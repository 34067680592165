import requestUtil from '../request-utils.js'
import { getCustomerInfo } from './api-customer.js'

/**
 * 获取限时抢购活动列表(根据saleId查单个活动,不传则查全部活动)
 */
const getTimedSaleListBySaleId = async (saleId) => {
	let customer = await getCustomerInfo();
	let memberGrade = ((customer && customer.customerId) && (customer.vipLevel || "no")) || "all";
	let channelId = localStorage.getItem("pc_channelId");
	return new Promise((resolve, reject) => {
		requestUtil.jackyunPost({
			method: "jmall.activity.gettimedsalelistbysaleid",
			channelId: '0',
			isValid: false,
			isGetStatistics: true,
			sortField: "begin_time",
			sortOrder: "ASC",
			pageIndex: 0,
			pageSize: saleId ? 1 : 100,
			saleIds: saleId,
			memberGrade: memberGrade
		}, async (res) => {
			let data = [];
			if (res && res.code == 200) {
				data = res.result.data || [];
			}
			resolve(data);
		})
	});
}

/**
 * 获取限时抢购活动商品列表 (根据saleId查多个商品)
 * @param {object}  = {saleId,pageIndex,pageSize} 
 */
const getTimedSaleGoodsList = async (reqData) => {
	reqData.channelId = '0';
	return new Promise((resolve, reject) => {
		requestUtil.jackyunPost({
			method: "jmall.activity.gettimedgoodsdetail.v1",
			...reqData
		}, async (res) => {
			if (res.code == 200) {
				resolve(res.result.data);
			} else {
				//console.log(res.msg);
				resolve([]);
			}
		})
	});
}
/**
 * 获取限时抢购活动的商品信息(根据goodsId查单个商品)
 * @param {object}  = {goodsId,pageIndex,pageSize} 
 */
const getTimedSaleGoodsInfo = async (saleId, goodsId) => {
	let channelId = localStorage.getItem("pc_channelId");
	let customerId = localStorage.getItem("pc_customerId");
	return new Promise((resolve, reject) => {
		requestUtil.jackyunPost({
			method: "jmall.activity.gettimedgoodsdetail",
			saleId,
			goodsId,
			channelId,
			customerId
		}, async (res) => {
			if (res.code == 200) {
				resolve(res.result.data);
			} else {
				//console.log(res.msg);
				resolve([]);
			}
		})
	});
}

// 获取预售信息
const getPresaleInfoByGoodsId = (preList) => {
	return new Promise((resolve, reject) => {
		// 获取预售信息
		requestUtil.jackyunPost({
			method: "jmall.activiy.listspecpresellInfobygoodsid",
			jsonStr: JSON.stringify(preList)
		}, async (res) => {
			if (res.code == 200 && !!res.result.data) {
				resolve(res.result.data);
			}
			resolve([]);
		})
	})
}

/**
 * 根据规格Id获取所有营销活动信息
 * @param {*} specIdList
 */
const getMarketingInfoListBySpec = async (specIdList) => {
	// 没有规格信息则不需要获取
	if (!specIdList || specIdList.length <= 0) {
		return [];
	}

	// 获取规格的营销活动信息
	let marketingInfoList = await _getMarketingInfoListBySpecFromOrigin(specIdList);
	// 没有营销活动信息，则直接跳出
	if (!marketingInfoList || marketingInfoList.length <= 0) {
		return [];
	}
	let pcCustomerInfo = localStorage.getItem("pc_customerInfo");
	if (!pcCustomerInfo) {
		return marketingInfoList.filter(t => t.limitMember == "all" || t.limitMember == "");
	} else {
		// 根据会员等级过滤获取有效数据
		let vipLevel = JSON.parse(localStorage.getItem("pc_customerInfo")).vipLevel || "***";
		return marketingInfoList.filter(t => t.limitMember == "all" || (t.limitMember || "").includes(
			vipLevel)) || [];
	}
}

/**
 * 获取规格的营销活动信息
 * @param {*} specIdList 
 */
const _getMarketingInfoListBySpecFromOrigin = async (specIdList) => {
	// 获取客户标签（营销设置中会有客户标签排除标记）
	let customerInfo = await getCustomerInfo();
	let customerTagList = customerInfo.tagArr || [];
	return new Promise((resolve, reject) => {
		requestUtil.jackyunPost({
			method: "jmall.activity.getmarketinginfolistbyspec",
			specIdList: specIdList.join(','),
			customerTagList: customerTagList.join(',')
		}, (res) => {
			let dataList = [];
			// 检测获取结果是否正确
			if (res.code == 200 && res.result.data && res.result.data.length > 0) {
				dataList = res.result.data;
				// 兼容处理最大值
				dataList.forEach(t => {
					t.upperLimitCount = t.upperLimitCount || 99999999
				});
			}
			// 返回结果数据
			resolve(dataList);
		});
	});
}

/**
 * 获取规格的限时抢购活动信息
 * @param {*} specInfoList [{saleId,specId},]
 */
const getSpecTimedSaleInfoList = async (specInfoList) => {
	// 获取渠道和用户Id
	let customer = await getCustomerInfo();
	let customerId = customer.customerId;
	// 获取规格的活动信息（原始数据）
	let specSaleInfoList = await _getSpecTimedSaleInfoListFromOrigin(customerId, specInfoList);
	return specSaleInfoList;
}

/**
 * 获取规格的限时抢购活动信息
 * @param {*} customerId 
 * @param {*} specInfoList [{saleId,specId},]
 */
const _getSpecTimedSaleInfoListFromOrigin = async (customerId, specInfoList) => {
	return new Promise((resolve, reject) => {
		requestUtil.jackyunPost({
			method: "jmall.activity.gettimedsalelistbyspec",
			jsonStr: JSON.stringify({
				customerId,
				saleItemList: specInfoList
			})
		}, (res) => {
			let dataList = [];
			// 检测获取结果是否正确
			if (res.code == 200 && res.result.data && res.result.data.length > 0) {
				dataList = res.result.data;
			}
			// 返回结果数据
			resolve(dataList);
		});
	});
}

/**
 * 计算促销商品总价（同时会给goodsList添加一个marketingPrice字段）
 * @param {*} goodsList 待结算商品列表，格式：[{id,goodsId,specId,unitName,goodsCount,executionPrice,score,baseUnit,countRate,actType}]
 * @param {*} allSpecMarketingList 所有规格相关的促销策略，来自getMarketingInfoListBySpec
 */
const calcMarketingGoodsTotalAmount = async (goodsList, allSpecMarketingList = null) => {
	// 获取所有选购的规格Id（去重处理）
	let allSpecIdList = [...new Set(goodsList.map(t => t.specId))];

	// 如果调用方没有传入促销策略，则自行获取
	if (!allSpecMarketingList) {
		allSpecMarketingList = await getMarketingInfoListBySpec(allSpecIdList);
	}

	// 总金额和总积分
	let totalAmount = 0;
	let totalScore = 0;

	// 第一步：获取没有直接设置策略的商品列表（不包括基础单位的策略），并且优先计算单独配置了促销策略的商品总价
	let notSetMarketingGoodsList = [];
	goodsList.forEach(goods => {
		// 获取当前单位设置的促销阶梯价列表
		let currUnitMarketingList = allSpecMarketingList.filter(t => t.specId == goods.specId && t.unitName == goods.unitName);

		// 如果促销阶梯价列表不存在，则记录到列表
		if (!(currUnitMarketingList && currUnitMarketingList.length > 0) || goods.unitName == goods.baseUnit || goods.actType > 0) {
			notSetMarketingGoodsList.push(goods);
			return;
		}

		// 开始计算直接设置了策略的货品总价，获取命中的策略
		let hittingMarketing = currUnitMarketingList.find(t => goods.goodsCount >= t.limitCount && goods.goodsCount <= t.upperLimitCount) || {};
		// 计算命中/未命中情况下的总价格积分
		goods.marketingPrice = hittingMarketing.favorablePrice || goods.executionPrice;
		goods.marketingLabelName = hittingMarketing.labelName || "";
		totalAmount += goods.marketingPrice * goods.goodsCount;
		totalScore += goods.score * goods.goodsCount;
	});

	// 第二步：获取可以沿用基础单位策略的商品列表，并且计算出没有任何策略的商品总价
	let useBaseUnitMarketingGoodsList = [];
	notSetMarketingGoodsList.forEach(goods => {
		// 获取基础单位设置的促销阶梯价列表
		let baseUnitMarketingList = allSpecMarketingList.filter(t => t.specId == goods.specId && t.unitName == goods.baseUnit && !(goods.actType > 0 && goods.actType != 3));

		// 如果促销阶梯价列表存在，则记录到列表
		if (baseUnitMarketingList && baseUnitMarketingList.length > 0) {
			useBaseUnitMarketingGoodsList.push(goods);
			return;
		}

		// 开始计算没有设置任何策略的货品总价
		goods.marketingPrice = goods.executionPrice;
		goods.marketingLabelName = "";
		totalAmount += goods.marketingPrice * goods.goodsCount;
		totalScore += goods.score * goods.goodsCount;
	});

	// 第三步：处理沿用了基础单位促销价的商品列表的总价
	// 获取规格Id（去重处理）
	let useBaseUnitSpecIdList = [...new Set(useBaseUnitMarketingGoodsList.map(t => t.specId))];
	// 遍历规格Id，根据规格维度计算货品总价
	useBaseUnitSpecIdList.forEach(specId => {
		// 获取当前规格单位相关货品列表
		let marketingGoodsList = useBaseUnitMarketingGoodsList.filter(t => t.specId == specId);
		let baseUnit = marketingGoodsList[0].baseUnit;

		// 转为基础单位后的商品数量求和
		let baseUnitSelectedCount = marketingGoodsList.reduce((total, goods) => total + (goods.goodsCount * goods.countRate), 0);

		// 找到基础单位命中的策略
		let hittingMarketing = allSpecMarketingList.find(t => t.specId == specId && t.unitName == baseUnit && baseUnitSelectedCount >= t.limitCount && baseUnitSelectedCount <= t.upperLimitCount) || {};

		// 计算命中/未命中情况下的总价格积分
		marketingGoodsList.forEach(goods => {
			goods.marketingPrice = (hittingMarketing.favorablePrice * goods.countRate) || goods.executionPrice;
			goods.marketingLabelName = hittingMarketing.labelName || "";
			totalAmount += goods.marketingPrice * goods.goodsCount;
			totalScore += goods.score * goods.goodsCount;
		});
	});

	return {
		totalAmount,
		totalScore
	}
}

export {
	getTimedSaleGoodsList,
	getPresaleInfoByGoodsId,
	getMarketingInfoListBySpec,
	getSpecTimedSaleInfoList,
	calcMarketingGoodsTotalAmount,
	getTimedSaleGoodsInfo,
	getTimedSaleListBySaleId
}
