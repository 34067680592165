<template>
	<!-- crm促销策略匹配结果 -->
	<div id='crmPloyResult' class='crm-ploy-result-box'>
		<div class='crm-ploy-result-inner'>
			<!-- 关闭/展开按钮 -->
			<img v-if='hasMorePromotionRemind&&!isShowFullPromotion' src="../../assets/images/ico-double-arrow-up-brown.svg" class='change-ploy-type' @click="showFullPromotion" />
			<img v-if='hasMorePromotionRemind&&isShowFullPromotion' src='../../assets/images/ico-double-arrow-down-brown.svg' class='change-ploy-type' @click="closeFullPromotion" />
			<!-- 展示策略明细 -->
			<div class='ploy-list'>
				<div class='ploy-item' v-for="(item,index) in promotionRemindList" v-if="index<3||isShowFullPromotion">{{item}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import commonUtils from '../../utils/common-utlis.js'

	export default {
		name: "crm-ploy-info",
		props: {
			matchResult: {
				type: Object,
				default: function() {
					return {};
				}
			},
		},
		data() {
			return {
				promotionRemindList: [], // 促销策略匹配提醒信息
				hasMorePromotionRemind: false, // 是否展示完整策略列表
				isShowFullPromotion: false, // 展示区域缩起还是展开
			}
		},
		computed: {
			// hasMorePromotionRemind() {
			// 	return promotionRemindList.length > 3
			// },
		},
		watch: {
			/**
			 * 监听促销策略匹配结果
			 * @param {Object} newValue
			 * @param {Object} oldValue
			 */
			matchResult(newValue, oldValue) {
				// 刷新展示数据
				this._refreshViewData(newValue);
			},
		},
		mounted() {
			// 刷新展示数据
			this._refreshViewData(this.matchResult);
		},
		methods: {
			/**
			 * 刷新展示数据
			 * @param {Object} matchResult
			 */
			_refreshViewData(matchResult) {
				// 生成促销策略应用提示列表
				let promotionRemindList = this._createCrmPromotionRemindList(matchResult);
				// promotionRemindList = [...promotionRemindList, ...promotionRemindList, ...promotionRemindList, ...promotionRemindList, ...promotionRemindList];
				this.promotionRemindList = promotionRemindList;
				this.hasMorePromotionRemind = promotionRemindList.length > 3;
			},

			/**
			 * 生成促销策略应用提示列表
			 */
			_createCrmPromotionRemindList(matchResult) {
				// 分开存储命中和未命中的策略，用作排序
				let promotionRemindListHit = [];
				let promotionRemindListMiss = [];
				matchResult.promotionRemindDtoList.forEach(promotion => {
					if (promotion.promotionStatus == "已参加") {
						// 查找命中的策略（按名称匹配，策略名称不会重复）
						let hitPromotion = matchResult.promotionResult.find(t => t.ployName == promotion.promotionName);
						if (hitPromotion && hitPromotion.discount > 0) {
							promotionRemindListHit.push(`已参加【${promotion.promotionName}】：优惠 ${commonUtils.floatKeepDigit(hitPromotion.discount).toFixed(2)}`);
						} else {
							// promotionRemindListHit.push(`已参加【${promotion.promotionName}】`);
						}
					} else {
						promotionRemindListMiss.push(`${promotion.promotionStatus}可参加【${promotion.promotionName}】`);
					}
				});
				// 合并返回结果
				return [...promotionRemindListHit, ...promotionRemindListMiss];
			},

			/**
			 * 展示完整促销策略提示
			 */
			showFullPromotion() {
				// 渲染切换效果
				this.isShowFullPromotion = true;
			},

			/**
			 * 关闭完整促销策略提示
			 */
			closeFullPromotion() {
				// 渲染切换效果
				this.isShowFullPromotion = false;
			},
		}
	}
</script>

<style>
	.crm-ploy-result-box {
		background: #FDF2E9;
		width: 100%;
		height: auto;
		font-size: 14px;
		line-height: 24px;
		padding: 10px 70px 10px 30px;
		color: #7F4D12;
		position: relative;
		box-sizing: border-box;
	}

	.change-ploy-type {
		height: 28px;
		width: 28px;
		position: absolute;
		top: 14px;
		right: 20px;
	}

	.ploy-item {
		/* white-space: nowrap;
	  overflow: hidden;
	  text-overflow: ellipsis; */
	}
</style>