<template>
	<div class="flex-col" v-show="timedGoodsListForShow.length > 0 || !isShowMoreMenu">
		<div class="timed-title-radius timed-title-img">
			<img class="icon-timed" src="../../assets/images/ico_timed.svg" />

			<div class="act-intime-sel">
				<div v-if="activityList.length > 0">
					<el-select placeholder="选择限时抢购活动" @change="currActivityInTimeChange" v-model="activityInTime">
						<el-option v-for="item in activityList" :value="item.saleId" :key="item.saleId" :label="item.label">
						</el-option>
					</el-select>
				</div>
			</div>
			<div v-show="isShowMoreMenu" class="flex-row moretimed-button" @click="gotoTimedSaleList()">
				<div class="menu-icon"></div>
				<div class="more-text">更多</div>
			</div>
		</div>
		<div class="flex-row" :class="isShowMoreMenu?'timed-goods-list':'timedsale-wrap'">
			<div v-for="(item) in timedGoodsListForShow">
				<timed-goods-detail :key="item.goodsId+currSaleId+item.leftCount" :saleId='currSaleId' :activityIsUnStart='activityIsUnStart' :activityList='activityList' :timedGoodsDetail='item' :isShowPriceDetail='isShowDetailPrice'>
				</timed-goods-detail>
			</div>
		</div>
	</div>

</template>

<script>
	import publicUtil from '../../utils/public-methods.js';
	import commonUtil from '../../utils/common-utlis.js';
	import goodsUtils from '../../utils/api-utils/api-goods.js';
	import timedGoodsDetail from './TimedGoodsItem.vue';
	import { getParam } from '../../utils/api-utils/api-params.js';
	import { getCustomerInfo } from '../../utils/api-utils/api-customer.js';
	import { getTimedSaleListBySaleId, getTimedSaleGoodsList } from '../../utils/api-utils/api-activity.js';
	import { system } from '../../utils/api-utils/api-sys.js';
	import requestUtil from '../../utils/request-utils.js';
	export default {
		components: {
			'timed-goods-detail': timedGoodsDetail
		},
		props: {
			isShowMoreMenu: {
				type: Boolean,
				default: true
			},
			pageIndex: {
				type: Number,
				default: 0
			},
			isFromIndex: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				currentTime: '',
				saleList: [],
				activityInTime: -1,
				activityInTimeIndex: -1,
				timedsessionList: [], // 未开始场次
				timedShowsessionList: [], //未开始展示场次
				activityList: [], // 正在进行中活动
				currSaleId: -1,
				countdownInterval: '',
				isactive: -1, // 未开始活动选中下标
				timedGoodsList: [],
				timedGoodsListForShow: [],
				channelId: '',
				warehouseId: '',
				noDataText: '',
				timedsessionListIndexNum: 0,
				pageSize: 4,
				isShowDetailPrice: true, // 是否展示零售价
				isOpenZeroStockBuy: false, // 是否开启负库存下单
			}
		},
		watch: {
			currSaleId(newName, oldName) {
				let that = this;
				that._getTimedSaleGoodsList();
			},
			activityInTime(newVal, oldVal) {
				let that = this;
				that.activityInTimeIndex = that.activityList.findIndex(item => item.saleId == newVal) || -1;
			},
			isactive(newActive, oldActive) {
				let that = this;
				if (newActive >= 0) {
					this.activityInTime = '';
				}
			}

		},
		created: function() {
			this.$emit('openLoading');
			if (!this.isShowMoreMenu) {
				this.pageSize = 8;
			}
			this._getTimedSaleList();
		},
		methods: {
			nextTimedSession() {
				let length = this.timedsessionList.length;
				let maxNum = Math.ceil(length / 3);
				let index = (this.timedsessionListIndexNum + 1) % maxNum;
				this.timedsessionListIndexNum = index;
				if (length < ((index + 1) * 3)) {
					this.timedShowsessionList = this.timedsessionList.slice(length - 3, length);
					return;
				}
				this.timedShowsessionList = this.timedsessionList.slice(index * 3, (index + 1) * 3);
			},
			preTimedSession() {
				let length = this.timedsessionList.length;
				// this.isactive = (this.isactive - 1 + length) % length;
				// this.currSaleId = this.timedsessionList[this.isactive].saleId;
				let maxNum = Math.ceil(length / 3);
				let index = (this.timedsessionListIndexNum - 1 + maxNum) % maxNum;
				this.timedsessionListIndexNum = index;
				if (length < ((index + 1) * 3)) {
					this.timedShowsessionList = this.timedsessionList.slice(length - 3, length);
					return;
				}
				this.timedShowsessionList = this.timedsessionList.slice(index * 3, (index + 1) * 3);
			},

			// 前往首页
			_toHome() {
				this.$message({
					message: "暂无限时抢购活动",
					type: 'error'
				});
				this.$router.push({
					name: 'home',
				});
			},

			async _getTimedSaleList() {
				let that = this;
				// 读取客户信息
				let customer = await getCustomerInfo();
				let memberGrade = ((customer && customer.customerId) && (customer.vipLevel || "no")) || "all";

				let paramList = await getParam("bindSaleChannelId,bindWarehouseId,isOpenShowDetailPrice,isOpenZeroStockBuy");
				that.channelId = paramList.bindSaleChannelId || '';
				that.warehouseId = paramList.bindWarehouseId || '';
				that.isShowDetailPrice = paramList.isOpenShowDetailPrice == "1";
				that.isOpenZeroStockBuy = paramList.isOpenZeroStockBuy == "1";

				let saleList = await getTimedSaleListBySaleId();
				if (!saleList) {
					!this.isFromIndex && this._toHome();
					this.$emit('closeLoading')
					return;
				}

				that.saleList = saleList;
				let activityList = [];
				let timedsessionList = [];
				if (saleList && saleList.length > 0) {
					this.$emit('getTotal', saleList[0].goodsAmount);
					for (var i = 0; i < saleList.length; i++) {
						var beginTime = new Date(saleList[i].beginTime);
						var endTime = new Date(saleList[i].endTime);

						var beginMonth = (beginTime.getMonth() + 1 < 10 ? "0" : "") +
							(beginTime.getMonth() + 1);
						var beginDate = (beginTime.getDate() < 10 ? "0" : "") +
							beginTime.getDate();
						var beginHour = (beginTime.getHours() < 10 ? "0" : "") +
							beginTime
							.getHours(); //获取当前小时数(0-23)
						var beginMin = (beginTime.getMinutes() < 10 ? "0" : "") +
							beginTime
							.getMinutes(); //获取当前分钟数(0-59)
						saleList[i].startTime = beginMonth + '.' + beginDate + ' ' +
							beginHour + ":" + beginMin;

						let saleInfo = {
							saleId: saleList[i].saleId + "",
							beginTime: saleList[i].startTime,
							beginTimeHaveYear: saleList[i].beginTime,
							endTime: saleList[i].endTime,
							label: saleList[i].startTime
						};
						if (beginTime <= new Date() && endTime > new Date()) {
							saleInfo.isUnStart = false;
							activityList.push(saleInfo);
							saleList[i].status = "进行中";
							saleList[i].isUnStart = false;
						} else if (beginTime > new Date()) {
							saleInfo.isUnStart = true;
							activityList.push(saleInfo);
							saleList[i].status = "未开始";
							saleList[i].isUnStart = true;
						}
					}
				}
				if (activityList.length == 0) {
					!this.isFromIndex && this._toHome();
					this.$emit('closeLoading')
					return;
				}
				that.currSaleId = activityList[0] && activityList[0].saleId;
				that.activityList = activityList;
				let activityInTime = activityList[0] && activityList[0].saleId;
				that.activityInTime = activityInTime;
				let currentTime = await system.getSysTime();
				that.currentTime = parseFloat(currentTime);
				that.activityIsUnStart = activityList[0] && activityList[0].isUnStart;
				// //倒计时
				that.timeCountDownList();
			},

			timeCountDownList() {
				let that = this;
				that.countdownInterval = setInterval(function() {
					that.timeCountDown();
				}, 1000)
			},
			// 倒计时
			timeCountDown(saleInfo) {
				let that = this;
				that.currentTime = that.currentTime + 1000;
				let currentTime = that.currentTime;
				let activityList = that.activityList;
				// 当没有正在进行的活动则关闭计时器
				if (activityList.length == 0) {
					clearInterval(that.countdownInterval);
				}
				for (var i = 0; i < activityList.length; i++) {
					let isUnStart = activityList[i].isUnStart;
					let beginTime = activityList[i].beginTime;
					let beginTimeHaveYear = activityList[i].beginTimeHaveYear;
					let endTime = activityList[i].endTime;
					beginTime = commonUtil.convertTime(beginTime);
					endTime = commonUtil.convertTime(endTime);
					beginTimeHaveYear = commonUtil.convertTime(beginTimeHaveYear);
					let TimedDownTime = isUnStart ? beginTimeHaveYear : endTime;
					let leftText = isUnStart ? '距离开始' : "距离结束";
					if (currentTime >= endTime) {
						// 从正在进行中的活动移除
						activityList.splice(i, 1);
					}
					var leftTime = TimedDownTime - currentTime;
					leftTime = leftTime - 1000; //计算剩余的毫秒数
					var days = parseInt(leftTime / 1000 / 60 / 60 / 24, 10); //计算剩余的天数 
					var hours = parseInt(leftTime / 1000 / 60 / 60 % 24, 10); //计算剩余的小时 
					var minutes = parseInt(leftTime / 1000 / 60 % 60, 10); //计算剩余的分钟 
					var seconds = parseInt(leftTime / 1000 % 60, 10); //计算剩余的秒数 
					days = days <= 0 ? "" : days + "天";
					hours = this.checkTime(hours);
					minutes = this.checkTime(minutes);
					seconds = this.checkTime(seconds);
					activityList[i].label = activityList[i].beginTime + "  " + leftText + " " +
						days + hours + ":" + minutes + ":" + seconds;
				}
				that.activityList = activityList;
			},

			//检测时间
			checkTime(time) {
				if (time >= 0 && time < 10) {
					time = "0" + time;
				}
				return time;
			},

			// 单个选项时候更改活动Id
			changeSaleId() {
				this.isactive = -1;
				this.currSaleId = this.activityList[0].saleId;
			},
			// 下拉框更改
			currActivityInTimeChange(val) {
				this.currSaleId = val;
				this.$emit('refreshPageIndex');
				this.isactive = -1;
				this.activityIsUnStart = this.activityList.find(item => item.saleId && item.saleId == val).isUnStart;;
			},
			// 未开始活动切换
			currActivityUnInTimeChange(index) {
				this.isactive = index;
				this.currSaleId = this.timedShowsessionList[index].saleId;
			},

			//获取抢购详情，商品列表
			async _getTimedSaleGoodsList(goodsName, pageIndex, pageSize) {
				var that = this;
				let activityList = this.activityList;
				let reqData = {
					saleId: that.currSaleId,
					pageIndex: pageIndex || that.pageIndex,
					pageSize: 1000,
					goodsName
				}

				let timedGoodsList = await getTimedSaleGoodsList(reqData);
				if (!timedGoodsList || timedGoodsList.length == 0) {
					that.timedGoodsList = [];
					if (!that.isShowMoreMenu) {
						this.$message({
							message: "当前活动暂无商品",
							type: 'error'
						});
					}
					activityList = activityList.filter(item => item.saleId != that.currSaleId);
					this.activityList = activityList;
					if (activityList.length > 0) {
						this.currSaleId = activityList[0].saleId
						let activityInTime = activityList[0] && activityList[0].saleId;
						that.activityInTime = activityInTime;
						let currentTime = await system.getSysTime();
						that.currentTime = parseFloat(currentTime);
						that.activityIsUnStart = activityList[0] && activityList[0].isUnStart;
						//倒计时
						that.timeCountDownList();
						return
					}
					return that.$emit('closeLoading');
				}

				let timedGoodsPriceList = await goodsUtils.getSkuPriceList(timedGoodsList.map(item => item.specId));

				timedGoodsList.forEach(timedGoodsDetail => {
					timedGoodsDetail.goodsCount = timedGoodsDetail.goodsCount || 999999; // 置空则不限

					timedGoodsDetail.leftCount = parseInt(timedGoodsDetail.goodsCount) - parseInt(timedGoodsDetail.boughtCount || 0);
					timedGoodsDetail.isStart = that.isactive >= 0 ? false : true;
					let tempObj = timedGoodsPriceList.find(item => item.skuId == timedGoodsDetail.specId && item.unitName == timedGoodsDetail.unit);
					timedGoodsDetail.oldPrice = !!tempObj ? tempObj.referencePrice : 0;
				})

				// 未开启负库存下单，则需要检测实际库存
				if (!that.isOpenZeroStockBuy) {
					// 补充库存信息
					await that._completeGoodsStock(timedGoodsList);
					timedGoodsList.forEach(goods => {
						goods.leftCount = Math.min(goods.leftCount, goods.goodsStock);
					});
				}

				this.$emit('getTotal', timedGoodsList.length);
				this.$emit('closeLoading');
				this.timedGoodsList = timedGoodsList;
				this.timedGoodsListForShow = timedGoodsList.slice(0, pageSize || this.pageSize);
			},

			// 前端翻页
			_changePage(pageIndex, pageSize) {
				this.timedGoodsListForShow = this.timedGoodsList.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
			},

			gotoTimedSaleList() {
				this.$router.push({
					path: '/timedsalegoodslist',
					params: {

					},
				})
			},

			/**
			 * 补充库存数据
			 * @param {Object} goodsList
			 */
			_completeGoodsStock(goodsList) {
				return new Promise(async (resolve, reject) => {
					// 获取所有Id集合
					let skuIds = goodsList.map(t => t.specId);
					if (skuIds.length == 0) {
						resolve(true);
						return;
					}

					// 获取库存数据
					let stockList = await goodsUtils.getSkuStockList(skuIds);

					// 补充库存数据
					goodsList.forEach(goods => {
						let stockInfo = stockList.find(t => t.skuId == goods.specId && (t.unitName == goods.unit || !t.unitName));
						if (stockInfo) {
							goods.goodsStock = parseInt(stockInfo.stock || 0);
						}
					});

					resolve(true);
				});
			},
		}
	}
</script>

<style scoped="scoped">
	.timed-title-img {
		width: 1200px;
		height: 70px;
		opacity: 0.99;
		background: linear-gradient(180deg, #f8765c, #e32b2b 88%);
		margin: 10px 0px 0 0px;
		position: relative;
	}

	.icon-timed {
		width: 136px;
		height: 38px;
		position: absolute;
		top: 16px;
		left: 20px;
	}

	.icon-timed-background {
		width: 87px;
		height: 68px;
		position: absolute;
		right: 600px;
		top: 0;
	}

	.timed-goods-list {
		height: 440px;
		opacity: 0.99;
		background: #e32b2b;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	.comming-soon-text {
		width: 56px;
		height: 20px;
		opacity: 0.69;
		font-size: 14px;
		font-family: Source Han Sans SC, Source Han Sans SC-Medium;
		font-weight: 500;
		text-align: left;
		color: #ffffff;
		line-height: 20px;
		position: absolute;
		right: 600px;
		top: 25px;
	}

	.timed-sessions {
		width: 480px;
		height: 46px;
		background: linear-gradient(180deg, #f36853, #e32d2d);
		border: 1px solid #ffffff;
		border-radius: 24px;
		position: absolute;
		right: 100px;
		top: 12px
	}

	.timedlist-sessions {
		width: 108px;
		margin-left: 18px;
		margin-right: -18px;
		margin-top: 13px;
		height: 24px;
		opacity: 0.7;
		font-size: 16px;
		font-family: Source Han Sans SC, Source Han Sans SC-Medium;
		font-weight: 500;
		text-align: left;
		color: #ffffff;
		line-height: 20px;
		cursor: pointer;
	}

	.prePic-button {
		width: 12px;
		height: 21px;
		opacity: 0.76;
		color: #ffffff;
		margin-top: 15px;
		margin-left: 8px;
		cursor: pointer;
	}

	.nextPic-button {
		width: 12px;
		height: 21px;
		opacity: 0.76;
		color: #ffffff;
		margin-top: 15px;
		margin-right: 8px;
		cursor: pointer;
	}

	.split-line {
		width: 18px;
		height: 26px;
		border-bottom: 1px solid #ffffff;
		transform: rotate(-45deg);
	}

	.menu-icon {
		border-top: double 6px #fff;
		border-bottom: solid 2px #fff;
		height: 2px;
		width: 14px;
		margin-top: -4px;
	}

	.more-text {
		width: 32px;
		height: 24px;
		font-size: 16px;
		font-family: Source Han Sans SC, Source Han Sans SC-Medium;
		font-weight: 500;
		text-align: left;
		color: #ffffff;
		line-height: 20px;
		margin: 4px;
	}

	.moretimed-button {
		position: absolute;
		top: 25px;
		right: 12px;
		cursor: pointer;
	}


	.act-intime-sel {
		position: absolute;
		top: 13px;
		left: 197px
	}

	.act-intime-sel /deep/.el-input__inner {
		width: 300px;
	}

	.actived {
		color: #fff;
		font-weight: bold;
		opacity: 1;
	}

	.timed-title-radius {
		border-top-left-radius: 13px;
		border-top-right-radius: 13px;
	}

	.timedsale-wrap {
		display: flex;
		flex-flow: wrap;
		width: 1200px;
		overflow-y: auto;
		height: calc(100% - 90px);
		-ms-overflow-style: none;
		/*隐藏滚动条 IE10+*/
		scrollbar-width: none;
		background: #e32b2b;
		padding: 10px 0;
		border-bottom-left-radius: 13px;
		border-bottom-right-radius: 13px;
		margin-bottom: 10px;
	}

	/deep/ .el-input__inner {
		width: 270px;
		height: 46px;
		background: #fbfbfb;
		border-radius: 23px;
		color: #FF5757;
		border: 0 solid #FF5757;
		font-weight: bold;
	}

	/deep/ .el-input.is-disabled .el-input__inner {
		color: #FF5757;
		cursor: initial;
	}

	/deep/ .el-select__caret {
		color: #FF5757 !important;
	}
</style>