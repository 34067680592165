import {
	constant
} from '../../config.js'
import commonUtils from '../common-utlis.js'
import requestUtil from '../request-utils.js'
import {
	getTimelinesData,
	setTimelinesData
} from './data-refresh.js'
import {
	getParam
} from './api-params.js'
import {
	getChannelInfo
} from './api-channel.js'

/**
 * 获取用户信息
 * @param {*} loginParams 登录参数结构{loginName,loginPwd}或{tel,verificationCode}
 * @param {*} isRefresh 
 */
const getCustomerInfo = async (loginParams = {}, isRefresh = false) => {
	try {
		let customerInfo = getTimelinesData(constant.DATA_KEY_CUSTOMER, false) || {};

		// 如果需要获取最新数据或缓存中没有数据，则不读取缓存
		if (!isRefresh && !commonUtils.isNullOrEmpty(customerInfo)) {
			return customerInfo;
		}

		// 调用接口获取客户信息
		customerInfo = await _loginAndGetCustomerInfo(loginParams);

		// 没有查询到相关客户信息或会员限制检测不通过则直接返回
		if (!customerInfo || !customerInfo.customerId || await _loginLimitCheck(customerInfo)) {
			return {
				error: customerInfo.error
			};
		}

		// 补充会员有效期并缓存数据
		_setCustomerInfo(customerInfo);

		// 返回用户信息
		return customerInfo;
	} catch (e) {
		// 检测不通过提示异常情况
		console.log("用户信息获取失败：" + e.message);
		return {};
	}
}

/**
 * 检测返回数据
 */
const _setCustomerInfo = async (customerInfo) => {
	// 处理会员有效期
	_setVipLevelValidityDateInfo(customerInfo);

	// 缓存数据
	setTimelinesData(constant.DATA_KEY_CUSTOMER, customerInfo);
	localStorage.setItem("pc_customerId", customerInfo.customerId);
	localStorage.setItem("pc_nickname", customerInfo.nickname);
}

/**
 * 获取用户信息
 */
const _loginAndGetCustomerInfo = (loginParams) => {
	// 补充参数customerId
	if (localStorage.getItem("pc_customerId")) {
		loginParams.customerId = localStorage.getItem("pc_customerId");
	}

	// 不是登录调用且没有登录过时，直接返回
	if (commonUtils.isNullOrEmpty(loginParams)) {
		return {}
	}

	// 补充参数loginFromWx
	loginParams.loginFromWx = false;

	return new Promise((resolve, reject) => {
		requestUtil.jackyunPost({
			method: "jmall.customer.login",
			jsonStr: JSON.stringify(loginParams)
		}, function(res) {
			if (res && (res.code != 200 || !res.result.data)) {
				if (loginParams.tel && (res.msg.includes("根据手机号未查到客户信息") || res.msg.includes("未获取到crm客户档案"))) {
					// 新用户或异常用户需要执行注册
					resolve(registeCustomer(loginParams.tel));
				} else {
					resolve({
						error: res.msg
					});
				}
			} else {
				resolve(res.result.data);
			}
		});
	})
}

/**
 * 更新账号密码
 */
const updateCustomerNameAndPwd = (reqData) => {
	return new Promise((resolve, reject) => {
		requestUtil.jackyunPost({
			method: "jmall.customer.updatenameandpwd",
			jsonStr: JSON.stringify(reqData)
		}, function(res) {
			if (res && (res.code != 200 || !res.result.data)) {
				resolve(res.msg)
			} else {
				resolve(res.result.data);
			}
		});
	})
}

/**
 * 注册
 */
const registeCustomer = async (tel) => {
	let channelInfo = await getChannelInfo(localStorage.getItem("pc_channelId"));
	let channelName = channelInfo.channelName;
	let channelId = channelInfo.channelId;
	let channelIdRangeList = await _getOnShelfChannelIds();
	let upperId = 0;
	return new Promise((resolve, reject) => {
		requestUtil.jackyunPost({
			method: "jmall.customer.register",
			jsonStr: JSON.stringify({
				tel,
				channelName,
				channelId,
				channelIdRangeList,
				upperId,
				customerFrom: "pc商城"
			})
		}, function(res) {
			if (res && (res.code != 200 || !res.result.data)) {
				resolve({
					error: res.msg
				})
			} else {
				resolve(res.result.data);
			}
		});
	})
}

/**
 * 获取上线渠道Id集合
 */
const _getOnShelfChannelIds = async () => {
	// 获取所需参数
	let paramList = await getParam("bindSaleChannelId,bindSaleChannelName,isOpenStore,multipleChannelInfo");

	// 获取上线渠道Id集合
	let onShelfChannelIds = [];
	// 未开启则返回默认渠道
	if (paramList.isOpenStore == "1") {
		try {
			// 吉商城设置的多门店信息
			let multipleChannelList = JSON.parse(paramList.multipleChannelInfo);
			// 从上线渠道中获取出建档渠道，如果不存在则使用默认渠道
			onShelfChannelIds = multipleChannelList.filter(t => t.on == "1").map(t => t.cId);
		} catch (e) {}
	}

	// 将所有数据合并
	onShelfChannelIds = [paramList.bindSaleChannelId, ...onShelfChannelIds];
	// 数据去重
	onShelfChannelIds = [...new Set(onShelfChannelIds)]

	return onShelfChannelIds;
}



/**
 * 设置用户有效期信息
 */
const _setVipLevelValidityDateInfo = async (customerInfo) => {
	try {
		// 无效用户不处理
		if (!customerInfo.customerId || !customerInfo.phone) {
			return;
		}

		// 有效期不存在或者2999年+的设置永久有效
		if (!customerInfo.vipLevelExpireTime || customerInfo.vipLevelExpireTime.startsWith("2999")) {
			customerInfo.vipLevelValidityDateInfo = "永久有效";
		} else {
			// xxxx年xx月xx日到期
			customerInfo.vipLevelValidityDateInfo = commonUtils.stringToDate(customerInfo.vipLevelExpireTime, false, true) + "到期";
		}
	} catch (e) {
		customerInfo.vipLevelValidityDateInfo = "永久有效";
	}
}

/**
 * 用户登录限制检测
 */
const _loginLimitCheck = async (customerInfo) => {
	// 检测黑名单
	if (customerInfo.blackCustomer == 1) {
		customerInfo.error = "黑名单用户";
		return true;
	}

	// 处理异常会员等级情况，方式空数据影响判断结果
	let memberGradeId = customerInfo.vipLevel == '0' || !customerInfo.vipLevel ? '*****' : customerInfo.vipLevel;

	// 检测会员等级限制
	let paramList = await getParam("isLoginForMemberGrade,loginForMemberGradeId,pc_restrictLoginText");

	// 是否限制会员等级登录注册
	let isLoginForMemberGrade = paramList.isLoginForMemberGrade;

	// 限制登录的会员等级
	let loginForMemberGradeId = paramList.loginForMemberGradeId;

	// 限制登录提示文本
	let remainText = paramList.pc_restrictLoginText

	// 限制登录注册（吉客云无该用户或者会员等级不可登录）
	if (isLoginForMemberGrade == '1' && !!loginForMemberGradeId && loginForMemberGradeId != "all" && (loginForMemberGradeId.indexOf(memberGradeId) < 0)) {
		customerInfo.error = remainText || "会员等级限制不满足";
		return true;
	}
	return false;
}

export {
	getCustomerInfo,
	updateCustomerNameAndPwd,
	registeCustomer
}