<template>
	<div>
		<!-- 顶部广告栏 -->
		<div :hidden="!goodsAdsNotice" class="goods-ads-notice">
			<img :src="$utils.setImg(goodsAdsNotice)" @error="$utils.setImg('')" />
		</div>
		<div class="container" v-show="isShowPage">
			<div class="base-info">
				<i class="el-icon-arrow-left back-btn" @click="goBack"></i>
				<el-row :gutter="24">
					<el-col :span="7">
						<el-image class="goods-img" fit='scale-down' :preview-src-list="srcList" :src="$utils.setImg(currentImg_zindex99||currentImg)"></el-image>
						<div class="img-list flex-row">
							<el-image v-for="(item, index) in goodsDetail.goodsPicList" :key="index" class="thum-img" fit='contain' :src="$utils.setImg(item)" :class="{ active: index == currentImgIndex }" @mouseenter="mouseEnter(index)">
							</el-image>
						</div>
						<div v-if="!!erpGoodsCustomFieldInfoList.length>0" style="margin-top: 15px;">
							<div style="font-weight: 600;font-size: 15px;margin-bottom: 5px;display: flex;">
								<div style="background-color: #FF490B;margin-right: 5px;height:13px; width: 7px;transform: translateY(4px);"></div>
								商品属性
							</div>
							<div v-for="item in erpGoodsCustomFieldInfoList" class="customfield-content">
								<div class="customfield-name"> {{item.customFieldName}}:</div>
								<div class="customfield-name" style="color: #333;margin-left: 10px;white-space: normal;word-break: break-all;">{{item.customFieldVal}} </div>
							</div>
						</div>
						<!-- <div class="copy-btn"  @click="CopyUrl()"><img class="image-margin" src="../../assets/images/icon-share.svg"></img>分享</div> -->
					</el-col>
					<el-col :span="17">
						<div class="goods1-content" v-if="goodsDetail.goodsId">
							<div class="goods1-name" v-if="showGoodsName">{{goodsDetail.goodsName}}</div>
							<div class="label-content" v-if="goodsDetail.goodsLabelList.length > 0">
								<div class="label-item" v-for="item in goodsDetail.goodsLabelList">
									<img class='label-ico' v-if="item.labelIcon" :src='item.labelIcon'></image>
									<img class='label-ico' v-else src='../../assets/images/ico_checked.png'></image>
									<div>{{item.labelName}}</div>
								</div>
							</div>
							<div class="goods1-name" v-else>{{goodsDetail.goodsNo}}</div>
							<div class="flex-row">
								<div class="flex-col" style="width: 100%;">
									<div v-if="isShowGoodsNo && showGoodsName" class="goods_no_text">
										{{goodsDetail.goodsNo}}
									</div>
									<div class="goods_no_text" style="white-space: pre;" v-if="!!goodsValidityDate">{{goodsValidityDate}}</div>
									<!-- 规格自定义属性 -->
									<div class="goods_skucustomfield" v-if="!!skuCustomFieldForShowList.length>0">
										<div class="skucustomfield_item" v-for="item in skuCustomFieldForShowList">
											<div class="customfield-name"> {{item.customFieldName}}:</div>
											<div class="customfield-name" style="color: #333;margin-left: 10px;white-space: normal;word-break: break-all;">{{item.customFieldVal}} </div>
										</div>
									</div>

								</div>
							</div>
							<div>
								<!-- 普通商品的价格展示 -->
								<div v-if="!isSelectPreSaleSpec">
									<div class="flex-row price-info" v-show="isShowPrice">
										<div class="goods1-price">
											<span class="currency1">{{$currency.symbol}}</span>
											<span class="size26" v-show="executionPrice >= 0 || score == 0">{{$utils.filterTrailingZeros(executionPrice)}}</span>
											<span v-show="score > 0" class="size26"> + </span>
											<span v-show="score > 0" class="size26">{{score}}</span>
											<span v-show="score > 0" class="price-tip1">积分</span>
											<span class="price-tip1" v-if="!isPackageGoods && (goodsDetail.isMulti == '1' || specStyle == '1')" v-show="specStyle == '1'||specIndex == -1 || selectedUnitIndex == -1">起</span>
											<span class="goods1-stock" v-show="!!referencePrice&&referencePrice>executionPrice" style="text-decoration: line-through;margin-right: 10px;">
												{{$currency.symbol}}{{$utils.filterTrailingZeros(referencePrice)}}
											</span>
											<span class="goods1-stock" v-if="!!stockDesc&&specIndex>-1&&(specStyle != '1' || isPackageGoods)">{{stockDesc}}</span>
										</div>
										<div class="button-border" style="margin: 30px;" v-show="(marketingLabel||tieredPricingMarketingLabel) && satisfyMarketing">
											{{tieredPricingMarketingLabel||marketingLabel}}
										</div>
										<div class="goods1-sellcount" v-if="isShowSellCount">{{_getSellCountText(goodsDetail.sellCountTotal||0)}}</div>
									</div>
								</div>

								<!-- 预售商品的价格展示 -->
								<div v-else>
									<div class="img-timed" style="position: relative;">
										<img class="img-timed" src="../../assets/images/ico-presale.svg">
										<div class='TimedLeftTime'>
											<div class="TimedLeftTime-lefttext">距离结束</div>
											<div class="TimedLeftTime-lefttext" v-show="days!=''">{{days}}</div>
											<div class="TimedLeftTime-text">{{hours}}</div>
											<div class="colon-color">:</div>
											<div class="TimedLeftTime-text">{{minutes}}</div>
											<div class="colon-color">:</div>
											<div class="TimedLeftTime-text">{{seconds}}</div>
										</div>
									</div>
									<div style="background-color: #f3f3f3;margin-bottom: 4px;">
										<div class="flex-row padding-price">
											<span class="goods_no_text margin-price">价格</span>
											<div class="goods_no_text line-through" style="color:rgb(0 0 0);">
												<span class="currency1 size18" style="bottom: 0px;">{{$currency.symbol}}</span>
												<span class="size18">{{$utils.filterTrailingZeros(executionPrice)}}</span>
												<span v-show="executionPrice != 0 && score > 0" class="size18"> + </span>
												<span v-show="score > 0" class="size18">{{score}}</span>
												<span v-show="score > 0" class="price-tip1 size18">积分</span>
											</div>
										</div>
										<div class="flex-row padding-price">
											<span class="goods_no_text margin-price">定金</span>
											<div class="goods_no_text ">
												<span class="currency1 size18" style="bottom: 0px;color: #FF5757;">{{$currency.symbol}}</span>
												<span class="size24" style="color: #FF5757;">{{$utils.filterTrailingZeros(executionPrice*presaleDepositRatio/100)}}</span>
												<span class="size16 margin-price">预付定金比例:{{presaleDepositRatio}}%</span>
											</div>
										</div>
										<div class="flex-row padding-price">
											<div class="goods_no_text margin-price">库存</div>
											<div class="magin-stock">{{presalestock}}</div>
										</div>
										<div class="flex-row padding-price">
											<div class="goods_no_text margin-price">每人限购</div>
											<div class="magin-stock">{{presaleLimitCount}}</div>
										</div>
										<div class="flex-row padding-price" style="padding-bottom: 10px;">
											<span class="goods_no_text size15 margin-price">预计{{presaleArrivalTime}}后交货</span>
										</div>

									</div>
								</div>

								<!-- 普通商品选择模式 -->
								<div v-if="specStyle != 1 || isPackageGoods || isPreSale">
									<spec-choose :goodsSelectionActionType="specStyle" :isPackageGood="isPackageGoods" :isMulti="isMulti" :showSingleSpec="isShowSingleSpec" :goodsSpec="goodsSpecList" @selectSpec="specChange"></spec-choose>
									<!-- 阶梯价展示 -->
									<div v-if="isShowTieredPricingList && isShowPrice">
										<div class='attr-name'>价格</div>
										<div class='attr-flex'>
											<div class='tiered-pricing-item' v-for="(item, index) in currTieredPricingList" :key="index">
												<div class="goods1-price" :class="item.isActive?'':'grey-color'">
													<span class="currency1">{{$currency.symbol}}</span>
													<span class="size26">{{$utils.filterTrailingZeros(item.executionPrice)}}</span>
												</div>
												<div class='tiered-pricing-num'>
													<span v-if="_changeTieredData(item.limitCount) ==_changeTieredData(item.upperLimitCount)">
														{{_changeTieredData(item.limitCount)}}{{item.unitName}}
													</span>
													<span v-else-if="item.upperLimitCount<999999">
														{{_changeTieredData(item.limitCount)}}-{{_changeTieredData(item.upperLimitCount)}}{{item.unitName}}
													</span>
													<span v-else>
														≥{{_changeTieredData(item.limitCount)}}{{item.unitName}}
													</span>
												</div>
											</div>
										</div>
									</div>

								</div>
								<!-- 批发模式 规格选择 -->
								<div v-else>
									<div class="speclist-choose">
										<spec-list-choose :isShowPrice="isShowPrice" :goodsSelectionActionType="specStyle" ref="speclistchoose" :goodsSpec="goodsSpecList" :unitCountRate="unitList[selectedUnitIndex] ? `${unitList[selectedUnitIndex].countRate}` : '1'" :unitName="unitList[selectedUnitIndex] ? unitList[selectedUnitIndex].unitName : goodsDetail.unit" :distinctStock="isOpenIndistinctStock" :priceList="skuPriceList" :showSingleSpec="isShowSingleSpec"
											:marketinggoods="marketingGoodsInfo" :ignoreMaxStock="isOpenZeroStockBuy" :baseUnit="goodsDetail.unit" @selectSpec="specListSelect" @foucsedSpec="specListSelectToShowCustomfield" @setCurrentImg='setCurrentImg'></spec-list-choose>
									</div>
								</div>
								<!-- 单位选择 -->
								<div v-if="isShowSingleUnit||unitList.length > 1">
									<div class='attr-name'>单位</div>
									<div class='attr-flex'>
										<div v-for="(item, unit_index) in unitList" :key="unit_index" class='attr-box' :class='{attrselected: selectedUnitIndex == unit_index}' :data-index='unit_index' @click='unitTap'>
											<span>{{item.unitName}}</span>
											<span style="margin: 0 15px 0 0;" v-if="goodsDetail.unit != item.unitName">({{item.countRate}}{{goodsDetail.unit}})</span>
										</div>
									</div>
								</div>
								<!-- 这是普通选货模式的数量选择  批发模式的数量选择在组件里面   需要兼容 -->
								<div v-if="specStyle != 1 || isPackageGoods || isPreSale">
									<div class="title">数量 <span v-show="minCount >1">{{minCount}}件起售</span></div>
									<input-number :curval="goodsCount" :min="minCount" :max="(isOpenIndistinctStock =='1' || isOpenZeroStockBuy) && !isSelectPreSaleSpec ? Math.pow(10,6) : maxCount" :step="stepCount" @change="countChange"></input-number>
								</div>

							</div>
							<!--<div v-else>
								 <div class="img-timed" style="position: relative;">
									<img class="img-timed" src="../../assets/images/ico-presale.svg">
									<div class='TimedLeftTime'>
										<div class="TimedLeftTime-lefttext">距离结束</div>
										<div class="TimedLeftTime-lefttext" v-show="days!=''">{{days}}</div>
										<div class="TimedLeftTime-text">{{hours}}</div>
										<div class="colon-color">:</div>
										<div class="TimedLeftTime-text">{{minutes}}</div>
										<div class="colon-color">:</div>
										<div class="TimedLeftTime-text">{{seconds}}</div>
									</div>
								</div>
								<div style="background-color: #f3f3f3;margin-bottom: 4px;">
									<div class="flex-row padding-price">
										<span class="goods_no_text margin-price">价格</span>
										<div class="goods_no_text line-through" style="color:rgb(0 0 0);">
											<span class="currency1 size18" style="bottom: 0px;">{{$currency.symbol}}</span>
											<span class="size18">{{$utils.amountFixed(executionPrice, 2)}}</span>
											<span v-show="executionPrice != 0 && score > 0" class="size18"> + </span>
											<span v-show="score > 0" class="size18">{{score}}</span>
											<span v-show="score > 0" class="price-tip1 size18">积分</span>
										</div>
									</div>
									<div class="flex-row padding-price">
										<span class="goods_no_text margin-price">定金</span>
										<div class="goods_no_text ">
											<span class="currency1 size18" style="bottom: 0px;color: #FF5757;">{{$currency.symbol}}</span>
											<span class="size24" style="color: #FF5757;">{{$utils.amountFixed(executionPrice*presaleDepositRatio/100, 2)}}</span>
											<span class="size16 margin-price">预付定金比例:{{presaleDepositRatio}}%</span>
										</div>
									</div>
									<div class="flex-row padding-price" style="padding-bottom: 10px;"> <span class="size15 margin-price">预计{{presaleArrivalTime}}
											后交货</span></div>
								</div> 

								
								<div>
									<spec-choose :goodsSelectionActionType="goodsSelectionActionType" :isPackageGood="isPackageGoods" :isMulti="isMulti" :showSingleSpec="isShowSingleSpec" :goodsSpec="goodsSpecList" :goodsSpecForPresale="goodsSpecList" @selectSpec="specChange"></spec-choose>
									<div v-if="isShowSingleUnit||unitList.length > 1" class="flex-row margin-price">
										<div class='attr-name unit-txt'>单位</div>
										<div class='attr-flex'>
											<div v-for="(item, unit_index) in unitList" :key="unit_index" class='attr-box' :class='{attrselected: selectedUnitIndex == unit_index}' :data-index='unit_index' @click='unitTap'>
												<span>{{item.unitName}}</span>
												<span style="margin: 0 15px 0 0;" v-if="goodsDetail.unit != item.unitName">({{item.countRate}}{{goodsDetail.unit}})
												</span>
											</div>
										</div>
									</div>
									<hr align="center" width="100%" size="1" color="#D7D7D7">
									<div class="flex-row margin-price">
										<div class="title">库存</div>
										<div class="magin-stock">{{presalestock}}</div>
									</div>
									<div class="flex-row margin-price">
										<div class="title">数量</div>
										<input-number :curval="goodsCount" :min="minCount" :step="stepCount" :max="presaleMaxCount || Math.pow(10,6)" @change="countChange"></input-number>
									</div>
									<hr align="center" width="100%" size="1" color="#D7D7D7">
									<div class="flex-between">
										<span v-if="presaleLimitCount>0" class="goods_limit_text">每人限购{{presaleLimitCount}}件</span>
										<span v-if="presaleLimitCount <=0" class="goods_limit_text"></span>
										<div class="flex-row">
											<el-button class="handle-btn my-danger-button colon-color" @click="confirmTap('toPay')">立即购买</el-button>
										</div>
									</div>
								</div>
							</div>-->
						</div>
						<!-- 加购，购买 -->
						<div class="handle-box flex-row">
							<!-- 已选数量 -->
							<div class="seleced-total-count-wrap" v-if="specStyle == '1' && !isPackageGoods && !isPreSale">
								已选 <span class="seleced-total-count">{{specSelectedTotalCount}}</span> 件
							</div>
							<el-button class="handle-btn info-button" @click="confirmTap('addShopCart')">加入购物车</el-button>
							<el-button class="handle-btn my-danger-button colon-color" @click="confirmTap('toPay')">立即购买</el-button>
						</div>
					</el-col>
				</el-row>

			</div>

			<!-- 商品推荐 -->
			<div class="white-background" style="margin-bottom: 10px;" v-if="recommendGoodsList.length>0">
				<div class="recommend-tip">{{recommendTip}}</div>
				<div class="flex-row" style="justify-content: center;padding-bottom: 10px;">
					<div class="jmallArrow">
						<span class="arrow-fong" @click="arrowClick('left')">
						</span>
					</div>
					<div style="width: 92%;">
						<el-carousel :autoplay="false" height="275px" arrow="never" indicator-position="none" ref="cardShow">
							<el-carousel-item v-for="(itemPage,indexPage) in paginationData" :key="indexPage">
								<el-row>
									<el-col :span="4" v-for="(itemCol, indexCol) in itemPage" :key="indexCol">
										<div :data-goodsid="itemCol.goodsId" @click="toGoodsDetail">
											<div class="img-box">
												<img class="goods-img" style="height: 164px;" :src="$utils.set400Img(itemCol.coverPic)" />
											</div>
											<div class="goods-name text-ellipsis " v-if="showGoodsName" :title="itemCol.goodsName">
												{{itemCol.goodsName}}
											</div>
											<div class="goods-name text-ellipsis " v-if="isShowGoodsNo" :title="itemCol.goodsNo">
												{{itemCol.goodsNo}}
											</div>
											<div class="goods-price flex-row" style="justify-content: center;margin-bottom: 10px;">
												<span v-show="itemCol.priceMember">
													{{$currency.symbol}}{{$utils.filterTrailingZeros(itemCol.priceMember)}}
												</span>
											</div>
										</div>

									</el-col>
								</el-row>
							</el-carousel-item>
						</el-carousel>
					</div>

					<div class="jmallArrow">
						<span class="arrow-fong" @click="arrowClick('right')">></span>
					</div>
				</div>
			</div>

			<div class="detail-box">
				<div :hidden="!richTextDatas.goodsTopRichText">
					<div class="detail-info" v-html="$utils.ueStrDecode(richTextDatas.goodsTopRichText)">
					</div>
				</div>
				<div :hidden="!richTextDatas.goodsRichText">
					<div class="detail-info" v-html="$utils.ueStrDecode(richTextDatas.goodsRichText)">
					</div>
				</div>
				<div :hidden="!richTextDatas.goodsBottomRichText">
					<div class="detail-info" v-html="$utils.ueStrDecode(richTextDatas.goodsBottomRichText)">
					</div>
				</div>
			</div>
		</div>
		<!-- 右下角浮动按钮 -->
		<float-btns scroll-target=""></float-btns>
		<!-- 货品检测弹窗 -->
		<check-goods-dialog :isShowDialog.sync="isShowStockCheckModal" :goodsList="cannotOrderGoodsList" :isNoValidGoods="false" :isNeedTitle="true" confirmText="继续下单" cancelText="移除库存不足商品" modalStyle="shopcart" :noValidGoodsTip="OpenZeroStock_tipText" :hasValidGoodsTip="OpenZeroStock_tipText" @confirm="toBuy" @cancel="filterThenToBuy">
		</check-goods-dialog>
	</div>
</template>

<script>
	import specChoose from '../../components/goods/SpecChoose.vue'
	import specListChoose from '../../components/goods/SpecListChoose.vue'
	import inputNumber from '../../components/goods/InputNumber.vue'
	import FloatBtns from '../../components/common/FloatBtns.vue';
	import validitydateUtils from '../../utils/api-utils/api-goods-validitydate.js';
	import priceUtils from '../../utils/api-utils/api-goods-price.js';
	import {
		getParam
	} from '../../utils/api-utils/api-params.js';
	import {
		getCustomerInfo
	} from '../../utils/api-utils/api-customer.js';
	import elImageViewer from 'element-ui/packages/image/src/image-viewer'
	import {
		getMarketingInfoListBySpec
	} from '../../utils/api-utils/api-activity.js'
	import {
		getPresaleInfoByGoodsId
	} from '../../utils/api-utils/api-activity.js'
	import goodsUtils from '../../utils/api-utils/api-goods.js';
	import commonUtil from '../../utils/common-utlis.js'
	import checkGoodsDialog from '../../components/trade/CheckGoodsDialog.vue';
	import {
		system
	} from '../../utils/api-utils/api-sys.js';
	export default {
		name: 'goodsdetail',
		components: {
			FloatBtns,
			specChoose,
			specListChoose,
			inputNumber,
			elImageViewer,
			"check-goods-dialog": checkGoodsDialog
		},
		data() {
			return {
				marketingLabel: '', // 促销活动名称
				tieredPricingMarketingLabel: "", // 命中的阶梯价的标签名称
				isShowPage: false,
				goodsId: "", //商品id
				goodsDetail: {}, //商品详情
				currentImgIndex: 0, //主图下标
				currentImg: '', //当前图片
				currentImg_zindex99: '', //优先级较高的当前图片
				executionPrice: 0.00, //会员价格
				referencePrice: 0.00, // 零售价
				socre: 0, //积分
				goodsSpecList: [], //规格信息
				specIndex: -1, // 当前选中规格索引
				specChooseError: "请选择规格", // 规格选择提示
				unitList: [], //多单位
				selectedUnitIndex: 0, //当前选中单位索引
				stock: "", //可用库存
				stockDesc: "", //可用库存说明文字
				goodsCount: 0, //购买量
				minCount: 1, //最小购买量
				maxCount: Infinity, //默认极大值
				isShowLogin: false, //是否显示登录
				specStyle: 0, //规格展示样式（0规格模式 1批发模式 2组合模式）
				specSelectedTotalCount: 0, // 批发模式下，已填写的商品总数
				specSelectedList: [], // 批发模式下，已选择的规格列表
				specSelectedError: '', // 批发模式下，规格选择错误提示
				isOpenIndistinctStock: '0', // 是否开启模糊库存
				goodsAdsNotice: "", //广告通知
				goodsValidityDate: "", // 商品有效期
				customerId: 0, //初始客户Id
				selectedID: "",
				skuPriceList: [],
				tmpGoodsSpecList: [],
				showBigImgList: [],
				showBig: false,
				copyUrl: "",
				stepCount: 1,
				showCopyButton: parent == window,
				isShowSingleSpec: true, // 单规格是否展示
				isOpenZeroStockBuy: false, //0库存下单
				OpenZeroStock_isOpenRemind: false, // 0库存提醒
				OpenZeroStock_tipText: "",
				isMulti: true,
				satisfyMarketing: true,
				paginationData: [], // 推荐商品分组
				recommendGoodsList: [], // 推荐商品列表
				genericGoodsDetailPosition: "top",
				genericGoodsDetailInfo: "",
				isOpenShowDetailPrice: false, // 是否展示零售价
				recommendTip: "商品推荐",
				srcList: [],
				richTextDatas: {},
				isShowGoodsNo: false, // 是否展示商品编号
				showGoodsName: !['795330'].includes(localStorage.getItem("JackyunUserName")),
				// 预售相关
				isPreSale: false, //商品是否为预售商品
				isSelectPreSaleSpec: false, // 当前选择是否为预售规格
				preSaleSkuInfo: [], //预售规格信息列表
				presaleDepositRatio: 100, // 预售比例
				presaleArrivalTime: '0001-01-01 00:00:00', // 预售到货时长
				presaleLimitCount: 0, // 预售限购数量
				presaleMaxCount: 9999, // 预售最大购买量
				days: '',
				hours: '',
				minutes: '',
				seconds: '',
				countdownInterval: '',
				selectPresaleSkuIndex: -1,
				marketingId: '',
				presalestock: 0, // 预售库存
				currTieredPricingList: [], // 当前选中的商品的阶梯价列表（规格-单位级）
				priceLoadOver: false, // 价格加载是否完成
				marketingGoodsInfo: [],
				cannotOrderGoodsList: [], // 不能下单商品列表
				isShowStockCheckModal: false, //展示库存校验弹窗
				selectPurpose: "", //选货目的
				isPackageGoods: false, // 是否时套餐商品
				isShowPrice: false,
				erpGoodsCustomFieldInfoList: [], // 货品级自定义字段展示数组
				skuCustomFieldForShowList: [], // 规格级自定义字段展示数组
				selectedSpecInfo: {}, // 当前选中的规格
			}
		},
		computed: {
			/**
			 * 是否展示阶梯价列表
			 */
			isShowTieredPricingList() {
				return this.currTieredPricingList.length > 1;
			},
		},
		watch: {
			async customerId(newName, oldName) {
				if (!!newName && newName != 0 && newName != oldName) {
					this._completeGoodsStock(this.goodsSpecList);
					this._setPriceList(this.goodsSpecList);
				}

				// 判断是否展示价格
				this.isShowPrice = await this.$utils.getIsShowPrice();
			},
		},

		async created() {
			// 设置初始数据和配置信息
			await this._initData(window.decodeURIComponent(window.atob(this.$route.query.id)));
			// 获取商品详情
			await this._getGoodsDetail();

			// 判断是否展示价格
			this.isShowPrice = await this.$utils.getIsShowPrice();
		},
		mounted() {
			this.$eventBus.$on('setCustomerId', msg => {
				this.customerId = msg;
			});
		},
		destroyed() {
			this.$eventBus.$emit("showLoginDialog", false, true); //隐藏登录弹窗,可关闭
		},
		methods: {
			onCopySuccess() {
				this.$message({
					message: "复制成功",
					type: 'success'
				});
			},
			onCopyError(e) {
				this.$message.error({
					message: "复制失败"
				});
			},

			/**
			 * 初始化数据
			 */
			async _initData(goodsId) {
				this.goodsId = goodsId;
				this.customerId = localStorage.getItem("pc_customerId") || 0;
				this.currentImg_zindex99 = ''; //优先级较高的当前图片
				this.goodsValidityDate = "";

				// 获取配置信息
				let paramList = await getParam(
					"OpenZeroStock_isOpenRemind,OpenZeroStock_tipText,bindSaleChannelId,commission_mode,pc_goodsDetailStyle,isOpenIndistinctStock,goodsdetail_AdsNotice,isOpenShowDetailPrice,isShowSingleSpec,isOpenZeroStockBuy,genericGoodsDetailInfo,isOpenGenericGoodsTemplates,genericGoodsDetailPosition,isShowGoodsNo,isShowSingleUnit,isShowSellCount,isShowGoodsStock,isShowStockOuts,isOpenIndistinctStock,goodsSelectionActionType,isShowGoodsCustomField,specCustomFields,goodsCustomFields,labelsInExtraPage"
				);
				this.channelId = paramList.bindSaleChannelId || '';
				this.commissionMode = paramList.commission_mode || '';
				this.specStyle = paramList.pc_goodsDetailStyle || '0';
				this.isOpenIndistinctStock = paramList.isOpenIndistinctStock || '0';
				this.goodsAdsNotice = paramList.goodsdetail_AdsNotice || "";
				this.isShowSingleSpec = paramList.isShowSingleSpec && paramList.isShowSingleSpec == "0" ?
					false : true;
				this.isShowSingleUnit = paramList.isShowSingleUnit == "1";
				this.isOpenZeroStockBuy = paramList.isOpenZeroStockBuy && paramList.isOpenZeroStockBuy ==
					"1";
				if (paramList.isOpenGenericGoodsTemplates && paramList.isOpenGenericGoodsTemplates ==
					"1") {
					this.genericGoodsDetailPosition = paramList.genericGoodsDetailPosition == "1" ?
						"bottom" : "up";
					this.genericGoodsDetailInfo = paramList.genericGoodsDetailInfo;
				}
				this.isShowGoodsNo = paramList.isShowGoodsNo == "1";
				this.isOpenShowDetailPrice = paramList.isOpenShowDetailPrice == "1";
				this.OpenZeroStock_isOpenRemind = paramList.OpenZeroStock_isOpenRemind == "1";
				this.OpenZeroStock_tipText = paramList.OpenZeroStock_tipText;
				this.isShowSellCount = paramList.isShowSellCount == "1";
				this.isShowGoodsStock = paramList.isShowGoodsStock == "1"; // 是否展示库存 
				this.isShowStockOuts = paramList.isShowStockOuts != "0"; // 是否展示库存 
				this.goodsSelectionActionType = paramList.goodsSelectionActionType || "normal"; //选货弹窗类型（normal,attr,tiered）
				this.isShowGoodsCustomField = paramList.isShowGoodsCustomField == "1"; // 是否展示自定义字段
				this.specCustomFields = paramList.specCustomFields; // 规格级自定义字段
				this.goodsCustomFields = paramList.goodsCustomFields; // 货品级自定义字段
			},

			/**
			 * 读取商品详情
			 */
			async _getGoodsDetail() {
				let goodsInfo = await goodsUtils.getGoodsDetail(this.goodsId);
				// XQ202308001698 切换完之后购买数量默认为0
				this.goodsCount = 0;

				// 补充商品主体信息
				this._loadGoodsMainInfo(goodsInfo);

				// 获取erp自定义规格、货品属性信息
				this._getGoodsInfoFromErp();

				// 补充规格信息
				let goodsSpecList = await this._loadGoodsSpecInfo();

				// 补充未选择规格时的库存
				this._NullselectStock(goodsSpecList);

				// 补充促销活动信息
				this._loadMarketingInfo(goodsSpecList);

				// 获取规格价格并设置最低价
				this._setPriceList(goodsSpecList);

				// 补充商品富文本内容
				this.getGoodsRichText();

				// 补充单位信息
				this._setUnitList(goodsInfo);

				// 补充预售信息
				await this._loadPreSaleInfo(goodsSpecList);

				// 获取商品库存
				await this._completeGoodsStock(goodsSpecList);

				// 补充有效期信息（库存会把数据充值，需要重新赋值）
				await this._loadValidityDateInfo(goodsInfo.goodsId, goodsSpecList);

				// 推荐商品列表
				this.getRecommendGoodsList();

				this.goodsSpecList = goodsSpecList;
				this.isShowPage = true;
				this.stock = "0";
				this.maxCount = Infinity;
				this.specIndex = -1;
				this.specChooseError = "请选择规格";
			},

			/**
			 * 补充规格信息
			 */
			async _loadGoodsSpecInfo() {
				let goodsSpecList = await goodsUtils.getGoodsSkuList(this.goodsId);

				if (this.isPackageGoods) {
					// 查询关联单品是否被屏蔽
					let blockUpGoodsList = await goodsUtils.checkMemberBlockGoods(goodsSpecList.map(item => item.originalSpecInfo.goodsId));

					// 过滤屏蔽商品
					goodsSpecList = goodsSpecList.filter(item => !blockUpGoodsList.find(blockUpItem => blockUpItem.goodsId == item.originalSpecInfo.goodsId))
				}

				goodsSpecList.forEach(item => {
					item.stock = "0";
				});
				return goodsSpecList;
			},

			/**
			 * 补充促销活动信息
			 */
			async _loadMarketingInfo(goodsSpecList) {
				// 请求jmall接口获取促销活动信息
				let marketingGoodsInfo = await getMarketingInfoListBySpec(
					goodsSpecList.map(f => {
						return f.specId
					}));

				// 过滤不符合等级的促销商品
				marketingGoodsInfo = marketingGoodsInfo.filter(item => this._checkMarketingMemberLevel(item.limitMember));
				this.marketingGoodsInfo = marketingGoodsInfo || [];
				this.marketingLabel = (marketingGoodsInfo[0] && marketingGoodsInfo[0].labelName) || ""
				// 判断是否满足促销活动等级
				this.satisfyMarketing = marketingGoodsInfo.length > 0;
			},

			/**
			 * 补充预售信息
			 */
			async _loadPreSaleInfo(goodsSpecList) {
				let that = this;
				let preList = [];
				// 获取前置查询条件
				this.unitList.forEach(item => {
					if (item.isOn == "1") {
						goodsSpecList.forEach(sku => {
							preList.push({
								goodsId: that.goodsId,
								specId: sku.specId,
								unit: item.unitName,
								customerId: that.customerId
							})
						})
					}
				})
				// 获取预售信息
				let presaleInfo = await getPresaleInfoByGoodsId(preList);
				if (presaleInfo.length > 0) {
					this.isPreSale = true;
					this.preSaleSkuInfo = presaleInfo.map(item => {
						let arrivalTime = parseFloat(item.endTime) + parseFloat(item
							.arrivalDays * 1000 * 60 * 60 * 24);
						item.arrivalTime = commonUtil.formatTime(arrivalTime, 'Y-M-D');
						item.stock = Math.min(item.goodsCount, item.limitCount - (
							item
							.customerPurchasedCount || 0));
						return item;
					});
					that.timeCountDownList();
				}

				// 预售倒计时
				this.preSaleSkuInfo = this.preSaleSkuInfo.filter(item =>
					goodsSpecList.find(f => f.specId == item.specId));
			},


			/**
			 * 补充商品主体信息
			 */
			_loadGoodsMainInfo(goodsInfo) {
				// 设置了推荐商品为同品牌修改推荐词
				if (goodsInfo.recommendType == "6") {
					this.recommendTip = "品牌推荐";
				}

				// 补充图片信息
				this.currentImg = goodsInfo.goodsPicList && goodsInfo.goodsPicList.length > 0 ? goodsInfo.goodsPicList[0] : '';
				this.srcList = goodsInfo.goodsPicList && goodsInfo.goodsPicList.map((a) => {
					return a
				});


				// 处理标签排序
				this.handleLabelsSort(goodsInfo);

				this.isPackageGoods = goodsInfo.goodsType == 1;
				this.minCount = goodsInfo.minCount ? parseInt(goodsInfo
					.minCount) : 1;
				this.isMulti = goodsInfo.isMulti == 1;
				this.score = parseInt(goodsInfo.score);
				this.goodsDetail = goodsInfo;
			},

			/**
			 * 处理标签排序
			 */
			handleLabelsSort(goodsInfo) {
				goodsInfo.goodsLabelList = goodsInfo.goodsLabelList || [];
				let labelIdList = (goodsInfo.labelId || '').split(',').filter(item => !!item);
				let labelInfoList = goodsInfo.goodsLabelList;
				let tempLabelList = [];
				labelIdList.forEach(labelIdItem => {
					let tempItem = labelInfoList.find(item => item.labelId == labelIdItem);
					tempItem && tempLabelList.push(tempItem);
				});
				goodsInfo.goodsLabelList = tempLabelList;
			},

			/**
			 * 补充有效期信息
			 * @param {*} goodsId
			 */
			async _loadValidityDateInfo(goodsId, goodsSpecList) {
				// 获取有效期列表
				let validityDateList = await validitydateUtils.getValidityDateByMultiCondition([goodsId]);
				// 没有有效期信息，则直接跳出
				if (!validityDateList || validityDateList.length < 0) {
					return;
				}

				// 获取保质期配置
				let paramList = await getParam("goodsValidityDateFrom,isShowValidateBySplitType");

				// 给每个规格添加上有效期信息
				goodsSpecList.forEach(async sepc => {
					// 添加规格的有效期
					let validityDateInfo = validityDateList.find(t => t.skuId == sepc.specId || t.skuId == "0") || {};

					// 补充配置信息
					validityDateInfo.validateFrom = paramList.goodsValidityDateFrom || "bathInfo";
					validityDateInfo.isShowValidateBySplitType = paramList.isShowValidateBySplitType == "1";

					sepc.validityDateObj = validityDateInfo || {};
					if (validityDateInfo) {
						sepc.validityDate = await this.$utils.getFormatValidate(validityDateInfo);
					}
				});
			},

			/**
			 * 获取商品富文本
			 */
			getGoodsRichText() {
				let that = this;
				this.$request.jackyunPost({
					method: "jmall.goods.getgoodsrichtext",
					goodsId: that.goodsId
				}, data => {
					if (data.code == 200) {
						this.richTextDatas = data.result.data;
					}
				})
			},

			/**
			 * 获取价格列表
			 * @param {Object} goodsSpecList
			 */
			async _setPriceList(goodsSpecList) {
				// 价格加载是否完成
				this.priceLoadOver = false;

				let that = this;
				// // 客户Id不存在则不获取
				// if (!that.customerId || that.customerId == 0) {
				// 	return;
				// }
				// 规格列表不存在，则不获取
				if (!goodsSpecList || goodsSpecList.length == 0) {
					return;
				}
				// 获取规格列表
				let specIds = goodsSpecList.map(t => this.isPackageGoods ? t.originalSpecId : t.specId);
				var dataArray = [];
				// 分页读取规格价格
				for (let i = 0; i < specIds.length; i += 20) {
					let specByPage = specIds.slice(i, i + 20).join(",");
					let tmpdata = await that._getskuPriceByPage(specByPage);
					dataArray = dataArray.concat(tmpdata);
				}

				// 将规格价格赋值
				that.skuPriceList = dataArray;

				// 价格加载是否完成
				this.priceLoadOver = true;

				this.specStyle == "1" && this._getMinPrice();
			},

			/**
			 * 设置最低价
			 */
			async _getMinPrice() {
				let priceInfo;
				if (this.isPackageGoods) {
					priceInfo = await priceUtils.getGoodsMinPriceListForPackage([this.goodsId]);
				} else {
					priceInfo = await priceUtils.getGoodsMinPriceList([this.goodsId]);
				}
				this.executionPrice = priceInfo[0] && priceInfo[0].price_member || 0;
			},

			/**
			 * 获取推荐商品
			 */
			async getRecommendGoodsList() {
				let that = this;
				// 获取缓存中信息
				let customerInfo = await getCustomerInfo();
				// 推荐商品信息
				let recommendGoodsInfo = await goodsUtils.getRecommendGoodsInfo(this.goodsId);
				let brandIds = (recommendGoodsInfo.brandIdList && recommendGoodsInfo.brandIdList.join(",")) || "0"
				let orConditionsJsonStr = {
					// categoryIds: recommendGoodsInfo.categoryIdList?.join(","),
					// fuzzyValue: that.data.searchValue,
				};
				// 渠道信息
				let channelId = localStorage.getItem("pc_channelId");
				let andConditionJsonStr = {
					categoryIds: recommendGoodsInfo.categoryIdList,
					channelId: channelId,
					goodsIds: recommendGoodsInfo.recommendGoodsIdList && recommendGoodsInfo.recommendGoodsIdList.join(","),
					isBlockUp: "0",
					brandId: brandIds,
					isDistributorGoods: false,
					memberGrade: customerInfo && customerInfo.vipLevel
				};

				// 推荐商品查询
				let newGoodsList = await goodsUtils.getGoodsList(andConditionJsonStr, orConditionsJsonStr, "createTime", "DESC", 1, "50");
				newGoodsList = newGoodsList.filter(item => item.goodsId != this.goodsId);
				// 补充价格信息
				newGoodsList = await goodsUtils.completeFullGoodsInfoForList(newGoodsList, "goodsDetail");

				that.recommendGoodsList = newGoodsList;
				that.splitRecommendGoods();
			},

			/**
			 * 获取规格价格
			 * @param {Object} specId
			 */
			_getskuPriceByPage(specId) {
				let that = this;
				return new Promise((resolve, reject) => {
					this.$request.jackyunPost({
						method: "jmall.goods.getskupricelist",
						customerId: that.customerId,
						channelId: that.channelId,
						specIds: specId
					}, data => {
						if (data.code == 200) {
							data.result.data.forEach(t => {
								// 未登录时展示游客展示价
								if (!that.customerId) {
									t.priceMember = t.priceDetail;
								}
							})
							resolve(data.result.data);
						} else {
							resolve([]);
						}
					})
				})
			},

			/**
			 * 加载辅助单位信息
			 * @param {Object} goodsInfo
			 */
			_setUnitList(goodsInfo) {
				let unitList;
				try {
					unitList = JSON.parse(goodsInfo.unitSetting || goodsInfo.unitInfo);
					unitList = unitList.filter(t => t.isOn != "0").sort(this.$utils.sortBy("countRate"));
				} catch (e) {
					unitList = [];
				}

				for (let i = 0; i < unitList.length; i++) {
					let countRate = unitList[i].countRate.toString();
					let x = countRate.indexOf(".") + 1;
					let count = countRate.length - x;
					if (count > 2) {
						//超过两位小数，四舍五入保留两位小数
						unitList[i].countRate = parseFloat(countRate).toFixed(2);
					} else if (count == 1 && countRate.substr(x, 1) == "0") {
						unitList[i].countRate = parseFloat(countRate);
					}
				}
				//this.selectedUnitIndex = 0;
				this.unitList = unitList;
			},

			/**
			 * 补充库存数据
			 * @param {Object} goodsSpecList
			 */
			_completeGoodsStock(goodsSpecList) {
				return new Promise(async (resolve, reject) => {
					// 获取所有Id集合
					let skuIds = goodsSpecList.map(t => t.originalSpecId || t.specId);
					if (skuIds.length == 0) {
						resolve(true);
						return;
					}

					// 获取库存数据
					let stockList = await goodsUtils.getSkuStockList(skuIds);

					// 补充库存数据
					goodsSpecList.forEach(spec => {
						let stockInfo = stockList.find(t => t.skuId == (spec.originalSpecId || spec.specId) && (t.unitName == spec.unit || !t.unitName));
						if (!!stockInfo) {
							spec.stock = parseInt(stockInfo.stock || 0);
						}

						// 套餐商品库存数据
						spec.stockInfoList = stockList.filter(t => t.skuId == (spec.originalSpecId || spec.specId));
					});

					resolve(true);
				});
			},

			/**
			 * 未选择规格或者单位情况下，计算库存
			 */
			_NullselectStock() {
				let stock = 0;
				this.goodsSpecList.forEach(spec => {
					stock = stock + parseInt(spec.stock);
				})
				this.stock = stock;

			},

			/**
			 * 选择完商品规格或者单位计算最小起购量以及数量
			 */
			_calMinCountAndStepCount() {
				// 获取当前单位的
				let currentUnit = this.unitList[this.selectedUnitIndex];
				// 计算辅助单位下最小起购量
				let countRate = Number(currentUnit.countRate);
				// 获取当前选中
				let selectedSpec = this.goodsSpecList[this.specIndex];
				let minCount = 1;
				let stepCount = 1;
				if (countRate >= 1) {
					minCount = Math.ceil(Number(selectedSpec.minCount) / countRate);
					stepCount = Math.ceil(Number(selectedSpec.stepCount) / countRate);
				}

				this.minCount = minCount;
				this.stepCount = stepCount;
				// XQ202308001698 切换完之后购买数量默认为0
				this.goodsCount = 0;
				// let goodCount = this.goodsCount;
				// if (goodCount < minCount) {
				// 	goodCount = minCount;
				// }

				this.countChange(this.goodsCount);
			},

			/**
			 * 匹配用户执行价格
			 * @param {Object} obj
			 */
			_getCustomerPrice(obj) {
				let executionPrice = 0.00;
				let skuPriceList = this.skuPriceList;
				let currentUnit = this.unitList[this.selectedUnitIndex];
				let currentUnitName = currentUnit.unitName;
				let specId = this.isPackageGoods ? obj.originalSpecId : obj.specId;
				let goodCount = this.goodsCount;

				let selectedSpec = this.goodsSpecList[this.specIndex];
				if (skuPriceList.length > 0) {
					let skuPriceInfo = skuPriceList.find((priceInfo) => priceInfo.skuId == specId && currentUnitName ==
						priceInfo.unitName);
					if (skuPriceInfo) {
						executionPrice = skuPriceInfo.priceMember;
						this.referencePrice = skuPriceInfo.priceDetail;
					} else {
						// 获取基础单位的价格信息
						let skuPriceInfo = skuPriceList.find((priceInfo) => priceInfo.skuId == specId && priceInfo
							.unitName == this.goodsDetail.unit);
						executionPrice = (skuPriceInfo.priceMember * parseFloat(currentUnit.countRate)).toFixed(2);
						this.referencePrice = (skuPriceInfo.priceDetail * parseFloat(currentUnit.countRate)).toFixed(2);
					}
				}

				// 计算促销活动价格
				let marketingGoodsInfo = this.marketingGoodsInfo;
				if (marketingGoodsInfo) {
					// 存在阶梯价则优先检测阶梯价
					if (this.currTieredPricingList && this.currTieredPricingList.length > 0) {
						// 取消已命中的价格
						this.currTieredPricingList.forEach(t => {
							t.isActive = false;
						});

						// 获取促销标签
						this.tieredPricingMarketingLabel = this.currTieredPricingList[0].labelName;

						// 获取命中的阶梯价
						let pricing = this.currTieredPricingList.find(t => t.specId == specId && goodCount >= this._changeTieredData(t.limitCount) && goodCount <= this._changeTieredData(t.upperLimitCount));

						if (pricing) {
							pricing.isActive = true;
							executionPrice = pricing.executionPrice;
							this.marketingId = pricing.marketingId;
						}
					} else {
						let marketingGoods = marketingGoodsInfo.find(f => f.specId == specId && f
							.unitName == currentUnitName);
						this.marketingLabel = !!marketingGoods ? marketingGoods.labelName : this.marketingLabel;
						if (!!marketingGoods && parseInt(marketingGoods.limitCount) <= goodCount) {
							executionPrice = marketingGoods.favorablePrice;
							this.marketingId = marketingGoods.marketingId;
						}
					}
				}

				return parseFloat(executionPrice);
			},

			/**
			 * 校验促销活动等级
			 * @param {string} limitMember
			 */
			async _checkMarketingMemberLevel(limitMember) {
				let customer = await getCustomerInfo();
				if ((customer && customer.customerId) && (!!limitMember && limitMember !=
						'all')) {
					var supportLevel = limitMember.split(',');
					customer.vipLevel = !!customer.vipLevel ? customer.vipLevel : "empty";
					if (!supportLevel.includes(customer.vipLevel)) {
						return false;
					}
				}
				return true;
			},

			/**
			 * 继续下单
			 */
			toBuy() {
				// 加入购物车
				if (this.selectPurpose == "addShopCart") {
					this.confirmTap("addShopCart");
				} else {
					// 立即购买
					this.confirmTap("toPay");
				}
			},

			/**
			 * 过滤之后在进行购买
			 */
			filterThenToBuy() {
				let specSelectedList = this.specSelectedList;
				// 去除库存为0并且小于最小起购量的商品
				specSelectedList = specSelectedList.filter(t => t.stock > 0 && t.stock >= t.minCount);
				// 修改库存不足的商品选购量
				specSelectedList.forEach(t => {
					if (t.stock > 0 && t.stock < t.goodsCount) {
						t.goodsCount = t.stock
					}
				});
				this.specSelectedList = specSelectedList;
				this.changeSpeclistchoose(specSelectedList);
				this.toBuy();
			},

			/**
			 * 同步确认框
			 */
			confirmTip() {
				let that = this;
				return new Promise((resolve, reject) => {
					this.$confirm("<span style='color:#999'>" + this.OpenZeroStock_tipText + "</span>",
						'提示', {
							confirmButtonText: '取消',
							cancelButtonText: '继续下单',
							cancelButtonClass: 'modal-button-class',
							confirmButtonClass: 'modal-button-class',
							dangerouslyUseHTMLString: true,
							center: true,
						}).then(() => {
						resolve(false);
					}).catch(() => {
						resolve(true);
					});
				})
			},

			/**
			 * 普通模式选中
			 * @param {string} catType
			 * @param {Object} presaleInfo
			 */
			generateGoods(catType, presaleInfo) {
				let that = this;
				let goodsList = [],
					unitName = this.goodsDetail.unit || '',
					unitCountRate = 1;
				if (this.unitList.length > 0) {
					unitName = this.unitList[this.selectedUnitIndex].unitName;
					unitCountRate = this.unitList[this.selectedUnitIndex].countRate;
				}

				let res = {
					cartType: catType == "toPay" ? -1 : 0,
					goodsId: this.selectedSpecInfo.goodsId,
					specId: this.selectedSpecInfo.specId,
					goodsCount: this.goodsCount,
					unitName,
					unitCountRate,
					actType: presaleInfo ? "3" : "0",
					actId: presaleInfo ? presaleInfo.presaleId : "0",
					executionPrice: this.executionPrice,
				};

				// 套餐商品处理
				if (this.isPackageGoods) {
					res.packageGoodsId = res.goodsId;
					res.packageSpecId = res.specId;
					res.goodsId = this.selectedSpecInfo.originalSpecInfo.goodsId;
					res.specId = this.selectedSpecInfo.originalSpecInfo.specId;
				}

				return res;
			},

			/**
			 * 订货模式生成选中对象
			 * @param {Object} item
			 * @param {Object} unit
			 * @param {Object} unitCountRate
			 */
			generateGoodsQuickShopping(item, catType) {
				let that = this;
				let goodsList = [],
					unitName = this.goodsDetail.unit || '',
					unitCountRate = 1;
				if (this.unitList.length > 0) {
					unitName = this.unitList[this.selectedUnitIndex].unitName;
					unitCountRate = this.unitList[this.selectedUnitIndex].countRate;
				}
				return {
					cartType: catType == "toPay" ? -1 : 0,
					goodsId: this.goodsId,
					specId: item.specId,
					goodsCount: item.goodsCount,
					unitName,
					unitCountRate,
					executionPrice: item.priceShown,
					//??marketingId: item.marketing_id || this.marketingId
				}
			},

			/**
			 * 检测数据
			 */
			_checkData() {
				//关闭所有警告
				this.$message.closeAll();
				if (!this.customerId) {
					this.$message.error("未获取到用户信息");
					return;
				}

				if (this.goodsDetail.isBlockup == '1') {
					this.$message.error("商品已下架，请重新选购");
					return;
				}
				if (this.unitList.length > 1 && this.selectedUnitIndex == -1) {
					this.$message.error("未选择单位");
					return;
				}
				// 批发模式
				if (this.specStyle == '1' && !this.isPackageGoods && !this.isPreSale) {
					if (this.specSelectedError || this.specSelectedTotalCount <= 0 || this.specSelectedList
						.length == 0) {
						this.$message.error(this.specSelectedError || '请选择规格，填写订购量');
						return;
					}
				} else {
					if (this.specIndex < 0 || !this.specId || this.specId <= 0) {
						this.$message.error(this.specChooseError || '未选择规格');
						return;
					}
					if (this.executionPrice <= 0 && this.score <= 0) {
						this.$message.error("商品价格不可为0");
						return;
					}
					if (this.goodsCount < 0) {
						this.$message.error("购买数量不可为负");
						return;
					}
					if (this.goodsCount == 0) {
						this.$message.error("购买数量不可为0");
						return;
					}
					// 预售库存校验
					if (this.selectPresaleSkuIndex > -1) {
						if (this.goodsCount > this.presaleMaxCount) {
							this.$message.error("商品库存不足");
							return;
						}
					} else {
						// 普通商品校验
						if (!this.isOpenZeroStockBuy && this.goodsCount > this.stock) {
							this.$message.error("商品库存不足");
							return;
						}
					}
				}
				return true;
			},

			/**
			 * 规格切换、规格选择
			 */
			specChange(res) {
				// 价格加载是否完成
				let that = this;
				// // 价格加载是否完成
				if (!that.priceLoadOver) {
					setTimeout(function() {
						that.specChange(res);
					}, 300);
					return;
				}

				let specIndex = res.specIndex;
				that.specIndex = specIndex;
				that.specChooseError = res.error;
				if (specIndex == -1) {
					that.goodsValidityDate = "";
					that.skuCustomFieldForShowList = [];
					return;
				}

				let selectedSpecInfo = that.goodsSpecList[specIndex];

				// 记录当前选中的规格
				that.selectedSpecInfo = selectedSpecInfo;

				that.specId = selectedSpecInfo.specId;

				// 获取自定义规格数据
				that._getGoodsSkuCustomFieldForShow(that.specId)

				// 套餐商品选完属性  展示单位
				if (that.isPackageGoods) {
					that._setUnitList({
						unitSetting: selectedSpecInfo.originalSpecInfo.unitSetting
					});
				}

				that.isSelectPreSaleSpec = !!that.preSaleSkuInfo.find(item => item.specId == that.specId);
				that.specName = selectedSpecInfo.specName;
				that.goodsValidityDate = selectedSpecInfo.validityDate;

				let specImg = selectedSpecInfo.picUrl;
				if (specImg) {
					that.currentImgIndex = -1;
					that.currentImg = specImg;
					that.currentImg_zindex99 = "";
				}
				let executionPrice = 0.00;
				// 展示阶梯价信息
				this._showTieredPricingList();
				if (that.selectedUnitIndex > -1) {
					try {
						that._calMinCountAndStepCount();
						that.executionPrice = that._getCustomerPrice(selectedSpecInfo);
					} catch {}

					let uIndex = that.selectedUnitIndex;
					let countRate = that.unitList[uIndex] ? that.unitList[uIndex].countRate : 1;

					// 预售暂无积分
					let specScore = parseInt(selectedSpecInfo.score);
					let specWeight = selectedSpecInfo.weight ? parseFloat(selectedSpecInfo.weight) : 0;

					// 获取预售信息
					let tmpPresaleSkuInfo = this._setPresaleInfo();

					let specStock = tmpPresaleSkuInfo ? tmpPresaleSkuInfo.stock : (selectedSpecInfo.stockInfoList.find(item => item.unitName == (that.unitList[uIndex] ? that.unitList[uIndex].unitName : "")).stock || 0);

					if (!!tmpPresaleSkuInfo) {
						if (that.presaleMaxCount < that.goodsCount) {
							that.goodsCount = that.presaleMaxCount;
						}
					} else {
						// 没有开启模糊库存，数量不能大于库存量，也不能小于0
						if (that.isOpenIndistinctStock != "1" && that.goodsCount > specStock && specStock > 0) {
							that.goodsCount = specStock;
						}
					}

					if (that.unitList.length > 1) {
						let countRate = parseFloat(that.unitList[uIndex].countRate);
						specScore = Math.ceil(specScore * countRate);
						specWeight = specWeight * countRate;
					}

					that.stock = specStock;
					that.stockDesc = goodsUtils.getStockDesc(specStock, "goodsDetail");
					that.maxCount = specStock;
					that.score = specScore;
					that.specWeight = specWeight;

				}
			},

			/**
			 * 获取预售信息
			 */
			_setPresaleInfo() {
				// 获取预售规格信息
				this.selectPresaleSkuIndex = this.preSaleSkuInfo.findIndex(item =>
					item.specId == this.goodsSpecList[this.specIndex].specId && item.unit == this.unitList[this.selectedUnitIndex].unitName);

				let tmpPresaleSkuInfo = this.preSaleSkuInfo[this.selectPresaleSkuIndex];

				if (!!tmpPresaleSkuInfo) {
					// 获取预售比例以及到时长、每人限购数量
					this.presaleDepositRatio = tmpPresaleSkuInfo.depositRatio;
					this.presaleArrivalTime = tmpPresaleSkuInfo.arrivalTime;
					this.presaleLimitCount = tmpPresaleSkuInfo.limitCount;
					this.presaleMaxCount = tmpPresaleSkuInfo.stock;
					this.presalestock = tmpPresaleSkuInfo.goodsCount;
					this.isSelectPreSaleSpec = true;
				} else {
					this.isSelectPreSaleSpec = false;
				}
				return tmpPresaleSkuInfo;
			},

			/**
			 * 选中多个规格（批发）
			 */
			specListSelect(res) {
				this.specSelectedTotalCount = res.totalCount;
				this.specSelectedList = res.selectedSpeclist;
				this.specSelectedError = res.error;
			},

			/**
			 * 聚焦单个规格（批发）
			 */
			specListSelectToShowCustomfield(specId) {
				// 获取自定义规格数据
				this._getGoodsSkuCustomFieldForShow(specId);
			},


			/**
			 * 选中单位
			 */
			unitTap(e) {
				let that = this;

				// 价格加载是否完成
				if (!that.priceLoadOver) {
					setTimeout(function() {
						that.unitTap(e);
					}, 300);
					return;
				}

				let uIndex = e.currentTarget.dataset.index;
				that.selectedUnitIndex = uIndex;
				let goodsSpecList = that.goodsSpecList;

				if (that.specIndex > -1) {
					let specInfo = goodsSpecList[that.specIndex];

					let tmpPresaleSkuInfo = this._setPresaleInfo();

					that._calMinCountAndStepCount();
					let specPrice = that._getCustomerPrice(specInfo);
					let countRate = parseFloat(that.unitList[uIndex].countRate);
					let specStock = tmpPresaleSkuInfo ? tmpPresaleSkuInfo.stock : (specInfo.stockInfoList.find(item => item.unitName == that.unitList[uIndex].unitName).stock || "0");
					let specScore = tmpPresaleSkuInfo ? 0 : parseInt(specInfo.score);
					let specWeight = specInfo.weight ? parseFloat(specInfo.weight) : 0;
					specScore = Math.ceil(specScore * countRate);
					specWeight = specWeight * countRate;
					that.countRate = countRate;
					that.executionPrice = specPrice;
					that.stock = specStock;
					that.stockDesc = goodsUtils.getStockDesc(specStock, "goodsDetail");
					that.maxCount = specStock;
					that.score = specScore;
					that.specWeight = specWeight;

					// 切换单位将购买数量置为0
					that.goodsCount = 0;
					// if (that.goodsCount > specStock) {
					// 	that.goodsCount = specStock > 0 ? specStock : 1;
					// }
				}

				// 展示阶梯价信息
				this._showTieredPricingList();
			},

			/**
			 * 展示阶梯价列表
			 */
			_showTieredPricingList() {
				// 检测选中的规格和单位信息，没有都选中则不执行
				if (!(this.specIndex >= 0 && this.selectedUnitIndex >= 0)) {
					return;
				}
				// 检测商品营销活动信息，没有设置则不执行
				if (this.marketingGoodsInfo.length <= 0) {
					return;
				}
				// 获取当前选中的规格和单位
				let currSpecInfo = this.goodsSpecList[this.specIndex];
				let currUnitInfo = this.unitList[this.selectedUnitIndex];
				// 获取基础单位
				let baseUnit = this.goodsDetail.unit;
				let countRate = parseFloat(currUnitInfo.countRate);
				let currMarketingInfoList = this.marketingGoodsInfo.filter(t => t.specId == currSpecInfo
					.specId && t.unitName == currUnitInfo.unitName);
				// 如果无法通过现有
				let baseUnitName
				if (currMarketingInfoList.length == 0) {
					currMarketingInfoList = this.marketingGoodsInfo.filter(t => t.specId == currSpecInfo
						.specId && t.unitName == baseUnit);
					baseUnitName = baseUnit
				}
				// 获取当前选中商品对应的价格信息
				let currPriceInfo = this.skuPriceList.find(t => t.skuId == currSpecInfo.specId && t.unitName ==
					currUnitInfo.unitName) || {};

				// 生成阶梯价列表
				let currTieredPricingList = this.__getTieredPricingList(currMarketingInfoList, currSpecInfo.specId,
					baseUnitName || currUnitInfo.unitName, currPriceInfo.priceMember, countRate);

				// 设置默认命中的对象
				let activePricing = currTieredPricingList.find(t => this.goodsCount >= t.limitCount && this.goodsCount <= t
					.upperLimitCount);
				if (activePricing) {
					// 当前价格置为命中
					activePricing.isActive = true;
				}

				this.currTieredPricingList = currTieredPricingList;
			},

			_changeTieredData(data) {
				return Math.ceil(data / (this.countRate || 1))
			},

			/**
			 * 生成阶梯价列表
			 * @param {Object} marketingInfoList
			 * @param {Object} specId
			 * @param {Object} unitName
			 * @param {Object} basePrice
			 */
			__getTieredPricingList(marketingInfoList, specId, unitName, basePrice, countRate) {
				// 获取当前单位对应的基础价格
				let defaultPricing = {
					specId,
					unitName,
					executionPrice: parseFloat(basePrice) || 0,
					limitCount: 1,
					upperLimitCount: 99999999,
					isHideNum: true,
					marketingId: 0,
					isActive: true,
					labelName: ""
				};

				// 阶梯价展示列表
				let tieredPricingList = [];

				// 没有阶梯价信息，则直接返回默认价格列表
				if (marketingInfoList.length <= 0) {
					tieredPricingList.push(defaultPricing);
					return tieredPricingList;
				}

				tieredPricingList = marketingInfoList.map(t => {
					t.executionPrice = parseFloat(t.favorablePrice * countRate);
					t.limitCount = parseInt(t.limitCount);
					t.upperLimitCount = parseInt(t.upperLimitCount)
					return t;
				});

				// 按照限制数量下限进行排序
				tieredPricingList = tieredPricingList.sort(this.$utils.sortBy("limitCount"));

				// 阶梯价设置项如果最小门槛是>1，则需要补充1-n的基础加部分
				if (tieredPricingList[0].limitCount > 1) {
					// 处理阶梯价未设置的部分
					defaultPricing.labelName = tieredPricingList[0].labelName;
					defaultPricing.upperLimitCount = tieredPricingList[0].limitCount - 1;
					defaultPricing.isHideNum = false;
					tieredPricingList = [defaultPricing, ...tieredPricingList];
				}

				return this._removeDuplicates(tieredPricingList);
			},

			/**
			 * 切换辅助单位时数据去重
			 */
			_removeDuplicates(tieredPricingList) {
				const set = new Set();
				return tieredPricingList.toReversed().filter(item => {
					const value = item["limitCount"];
					if (!set.has(Math.ceil(value / (this.countRate || 1)))) {
						set.add(Math.ceil(value / (this.countRate || 1)));
						return true;
					}
					return false;
				}).toReversed();
			},

			/**
			 * 图片鼠标进入事件
			 */
			mouseEnter(e) {
				let goodsPicList = this.goodsDetail.goodsPicList;
				this.currentImgIndex = e;
				this.currentImg = goodsPicList.length > 0 ? goodsPicList[e] : '';
				this.currentImg_zindex99 = "";
			},

			/**
			 * 数量变化
			 */
			countChange(value) {
				this.goodsCount = parseInt(value);
				let goodsSpecList = this.goodsSpecList;
				if (this.specIndex > -1) {
					let specInfo = goodsSpecList[this.specIndex];
					this.executionPrice = this._getCustomerPrice(specInfo);
				}
			},

			/**
			 * 组合推荐商品
			 */
			splitRecommendGoods() {
				let paginationData = [] //分页数组  tableData 所有的数据

				for (var i = 0; i <= this.recommendGoodsList.length; i += 6) {
					paginationData.push(this.recommendGoodsList.slice(i, i + 6))
				}
				this.paginationData = paginationData;
			},

			/**
			 * 倒计时
			 */
			async timeCountDownList() {
				let currentTime = await system.getSysTime();
				let that = this;
				that.currentTime = currentTime;

				that.countdownInterval = setInterval(function() {
					that.timeCountDown();
				}, 1000)
			},

			/**
			 * 倒计时
			 */
			timeCountDown() {
				let that = this;
				that.currentTime = parseInt(that.currentTime) + 1000;
				let currentTime = that.currentTime;
				let activityInTimeList = that.preSaleSkuInfo;
				// 当没有正在进行的活动则关闭计时器
				if (activityInTimeList.length == 0) {
					clearInterval(that.countdownInterval);
				}
				for (var i = 0; i < activityInTimeList.length; i++) {
					let endTime = parseFloat(activityInTimeList[i].endTime);
					let TimedDownTime = endTime;
					if (currentTime >= endTime) {
						// 从正在进行中的活动移除
						activityInTimeList.splice(i, 1);
						continue;
					}
					var leftTime = TimedDownTime - currentTime;
					leftTime = leftTime - 1000; //计算剩余的毫秒数
					var days = parseInt(leftTime / 1000 / 60 / 60 / 24, 10); //计算剩余的天数 
					var hours = parseInt(leftTime / 1000 / 60 / 60 % 24, 10); //计算剩余的小时 
					var minutes = parseInt(leftTime / 1000 / 60 % 60, 10); //计算剩余的分钟 
					var seconds = parseInt(leftTime / 1000 % 60, 10); //计算剩余的秒数 
					days = days <= 0 ? "" : days + "天";
					hours = this.checkTime(hours);
					minutes = this.checkTime(minutes);
					seconds = this.checkTime(seconds);
					activityInTimeList[i].days = days;
					activityInTimeList[i].hours = hours;
					activityInTimeList[i].minutes = minutes;
					activityInTimeList[i].seconds = seconds;
				}
				that.preSaleSkuInfo = activityInTimeList;
				try {
					that.days = activityInTimeList[that.selectPresaleSkuIndex].days;
					that.hours = activityInTimeList[that.selectPresaleSkuIndex].hours;
					that.minutes = activityInTimeList[that.selectPresaleSkuIndex].minutes;
					that.seconds = activityInTimeList[that.selectPresaleSkuIndex].seconds;
				} catch {

				}


			},

			/**
			 * 检测时间
			 */
			checkTime(time) {
				if (time >= 0 && time < 10) {
					time = "0" + time;
				}
				return time;
			},

			/**
			 * 检测下单多单位商品是否超卖
			 */
			async _checkMultiUnitOverStock(selectedGoods) {
				// 预售库存校验通过设置校验不通过接口校验
				if (this.selectPresaleSkuIndex > -1) {
					return true;
				}


				// 检测加购数量是否小于剩余库存
				let orderGoodsList = selectedGoods.map((item) => {
					return {
						goodsId: item.goodsId,
						specId: item.specId,
						unitName: item.unitName,
						goodsCount: item.goodsCount
					}
				})
				let checkStockResult = await goodsUtils.checkOrderGoodsStock(orderGoodsList);
				if (checkStockResult.baseUnitOverOrderGoodsList && checkStockResult.baseUnitOverOrderGoodsList.length > 0) {
					this.$message.error("库存不足");
					return false;
				}
				return true;
			},

			/**
			 * 立即购买或加购
			 * @param {string}  "toPay"|"addShopCart"
			 */
			async confirmTap(catType) {
				let customer = await getCustomerInfo();
				this.selectPurpose = catType;
				if (!customer.customerId) {
					this.$eventBus.$emit("showLoginDialog", true, true); //显示登录弹窗,可关闭
					return;
				}

				if (!this._checkData()) {
					return;
				}

				// 购物车id供订单确认页查询订单使用
				let cartIds
				let selectedGoods = [];
				if (this.specStyle == '1' && !this.isPackageGoods && !this.isPreSale) {
					// 批发模式
					this.specSelectedList.forEach((item) => {
						let goods = this.generateGoodsQuickShopping(item, catType)
						if (!!goods) {
							selectedGoods.push(goods);
						}
					})
					// 检测数据是否正确
					if (!this.checkSelectGoodsInfo(selectedGoods)) {
						return;
					}

					// 直接下单情况需要检测库存
					if (!await this._checkMultiUnitOverStock(selectedGoods)) {
						return;
					}

					// 购买
					if (catType == "toPay") {
						cartIds = await goodsUtils.addTempShoppingCartBatch(selectedGoods);
					} else {
						// 加入购物车
						for (let i = 0; i < selectedGoods.length; i++) {
							cartIds = await goodsUtils.addShopCart(selectedGoods[i]);
						}
					}

				} else {
					// 普通选货模式
					let presaleInfo = this.preSaleSkuInfo.find(item => item.specId == this.specId && item.unit ==
						this.unitList[this.selectedUnitIndex].unitName);

					if (this.stock < parseInt(this.goodsCount) && this.isOpenZeroStockBuy && this.OpenZeroStock_isOpenRemind && !await this.confirmTip()) {
						return;
					}
					selectedGoods = this.generateGoods(catType, presaleInfo);
					// 检测数据是否正确(参数格式[{}])
					if (!this.checkSelectGoodsInfo([selectedGoods])) {
						return;
					}

					// 直接下单情况需要检测库存(参数格式[{}])
					if (!await this._checkMultiUnitOverStock([selectedGoods])) {
						return;
					}

					// 加购(参数格式{})
					cartIds = await goodsUtils.addShopCart(selectedGoods);
				}

				// 区分加购类型
				if (catType == "toPay" && (cartIds > 0 || cartIds.split(",").length > 0)) {
					this.$router.push({
						name: 'tradeconfirm',
						params: {
							trade_from: 'PC商品详情',
							cartIds: cartIds + ""
						}
					});
					return;
				}

				this.$message({
					message: (cartIds > 0 || cartIds.split(",").length > 0) ? "添加成功，可前往购物车查看" : "加购失败",
					type: (cartIds > 0 || cartIds.split(",").length > 0) ? 'success' : "error"
				});

				// 清除选择数据
				this.goodsSpecList.map(item => item.goodsCount = "");

				//修改头部购物车总数
				this.$eventBus.$emit("updateHeaderShopCartCount");
			},
			// 校验商品信息
			checkSelectGoodsInfo(selectedGoods) {
				let canCreate = true;
				try {
					selectedGoods.forEach(item => {
						if (parseFloat(item.executionPrice) == 0 && parseInt(this.score) == 0) {
							canCreate = false;
							throw new Error("错误数据");
						}
					})
				} catch {
					this.$message.error("价格不可为0");
				}
				return canCreate;
			},

			/**
			 * 返回上一个界面
			 */
			goBack() {
				this.$router.back();
			},

			/**
			 * 赋值主图内容（供组件调用）
			 * @param {Object} url
			 */
			setCurrentImg(url) {
				this.currentImg_zindex99 = url;
			},

			arrowClick(val) {
				if (val === 'right') {
					this.$refs.cardShow.next()
				} else {
					this.$refs.cardShow.prev()
				}
			},
			// 推荐商品跳转商品详情，刷新页面
			async toGoodsDetail(e) {
				let goodsId = e.currentTarget.dataset.goodsid;
				document.documentElement.scrollTop = 0;
				this.goodsValidityDate = "";
				this.recommendGoodsList = []
				await this._initData(goodsId);
				// 获取商品详情
				await this._getGoodsDetail();
			},

			/**
			 * 销量展示文字处理
			 * @param {*} e 
			 */
			_getSellCountText(sellCountTotal) {
				// if (this.isPackageGoods) {
				// 	return "";
				// }
				if (sellCountTotal > 10000) {
					let sliceLength = sellCountTotal.toString().length - 4;
					return "已售 " + sellCountTotal.toString().slice(0, sliceLength) + "万+"
				}
				return "已售 " + sellCountTotal;
			},

			// 修改组件内部数据
			changeSpeclistchoose(specSelectedList) {
				this.$refs.speclistchoose.rebuildSpecSelect(specSelectedList)
			},

			/**
			 * 获取erp货品详情
			 */
			async _getGoodsInfoFromErp() {
				if (this.goodsDetail.goodsType == 1 || !this.isShowGoodsCustomField) {
					return
				}

				// 获取erp货品详情
				let tempErpGoodsInfo = await goodsUtils.getGoodsInfoFromErp(this.goodsId);

				// 获取erp自定义字段映射关系
				let erpCustomFieldMap = await this._getGoodsCustomFieldMapFromErp();

				let erpGoodsCustomFieldInfoList = [];
				this.goodsCustomFields.split(",").forEach(item => {
					// 有值才进行展示
					tempErpGoodsInfo[item] && erpGoodsCustomFieldInfoList.push({
						customFieldName: erpCustomFieldMap.find(mapItem => mapItem.fieldName == item).fieldShowName,
						customFieldVal: tempErpGoodsInfo[item]
					})
				})

				this.erpGoodsCustomFieldInfoList = erpGoodsCustomFieldInfoList;
				this.erpGoodsSkuInfoList = tempErpGoodsInfo && tempErpGoodsInfo.goodsSkuInfos || [];
			},

			/**
			 * 获取erp规格级自定义字段的展示列表
			 */
			async _getGoodsSkuCustomFieldForShow(specId) {
				if (!this.erpGoodsSkuInfoList || this.erpGoodsSkuInfoList.length == 0) {
					return
				}
				let tempSkuInfo = this.erpGoodsSkuInfoList.find(item => item.skuId == specId);

				let skuCustomFieldForShowList = [];

				// 获取erp自定义字段映射关系
				let erpCustomFieldMap = await this._getGoodsCustomFieldMapFromErp();

				this.specCustomFields.split(",").map(item => {
					// 有值才进行展示
					tempSkuInfo[item] && skuCustomFieldForShowList.push({
						customFieldName: erpCustomFieldMap.find(mapItem => mapItem.fieldName == item).fieldShowName,
						customFieldVal: tempSkuInfo[item]
					})
				})

				this.skuCustomFieldForShowList = skuCustomFieldForShowList;
			},

			/**
			 * 获取erp货品自定义字段映射关系
			 */
			async _getGoodsCustomFieldMapFromErp() {
				let erpGoodsCustomFieldMap = await goodsUtils.getGoodsCustomFieldMap();
				return erpGoodsCustomFieldMap
			},
		}
	}
</script>

<style scoped="scoped">
	.goods-ads-notice {
		min-height: 50px;
		max-height: 100px;
		overflow: hidden;
		flex-shrink: 0;
	}

	.goods-ads-notice /deep/ img {
		display: block;
		max-width: 100%;
		max-height: 100px;
		margin: 0 auto;
	}

	.container {
		flex-grow: 0;
	}

	.base-info {
		padding: 28px 70px 30px 70px;
		border-left: 1px solid #dedede;
		border-right: 1px solid #dedede;
		background-color: #fff;
	}

	.back-btn {
		font-size: 42px;
		margin-left: -30px;
		margin-bottom: 20px;
		cursor: pointer;
	}

	.goods-img {
		width: 100%;
		height: 300px;
	}

	.img-list {
		width: 100%;
		overflow: auto;
		/* justify-content: center; */
	}

	.thum-img {
		width: 60px;
		height: 60px;
		padding: 13px 2px;
		margin: 2px 8px;
		width: 14%;
		flex-shrink: 0;
		flex-grow: 0;
	}

	.active {
		border-bottom: 3px solid #797979;
	}

	.goods1-content {
		margin-left: 50px;
		font-size: 15px;
	}

	.goods1-name {
		font-size: 20px;
		line-height: 32px;
		word-break: break-all;
	}

	.goods1-price {
		/* 		margin: 30px 0 40px 0; */
		color: #FF5757;
	}

	.currency1 {
		position: relative;
		bottom: 2px;
		right: 2px;
	}

	.price-tip1 {
		position: relative;
		bottom: 2px;
		right: -8px;
	}

	.goods_no_text {
		color: #b6b6b6;
		line-height: 28px;
		font-size: 15px;
	}

	.goods1-stock {
		position: relative;
		bottom: 2px;
		left: 20px;
		color: #999;
	}

	.title {
		margin: 20px 0;
		color: #999;
	}

	.size26 {
		font-size: 26px;
	}

	.speclsit-choose-tips {
		width: 150px;
		line-height: 50px;
		text-align: center;
		background-color: #f5f7fa;
		border: solid 1px #e4e7ed;
		border-radius: 3px;
		margin-bottom: 20px;
	}

	/* .speclist-choose {
		margin-top: 30px;
	} */

	.handle-box {
		margin-top: 30px;
		align-items: center;
		justify-content: flex-end;
	}

	.goods-price {
		padding: 0 20px;
	}

	.handle-btn {
		height: 75px;
		width: 190px;
		font-size: 17px !important;
		margin-right: 20px !important;
	}

	.detail-box {
		background-color: #F2F2F2;
		padding: 30px 0;
	}

	.seleced-total-count-wrap {
		margin-right: 40px;
	}

	.seleced-total-count {
		margin: 0 10px;
		font-weight: bold;
	}

	.detail-info {
		width: 100%;
		margin: 0 auto;
		background-color: #fff;
	}

	.detail-info /deep/ p {
		margin: 0;
	}

	.arrow-fong {
		font-size: 40px;
		font-stretch: ultra-condensed;
		color: #c5c1c1;
	}

	.size18 {
		font-size: 18px;
	}

	.size24 {
		font-size: 24px;
	}

	.padding-price {
		padding-top: 10px;
	}

	.detail-info /deep/ img {
		/* 		width: 100%; 可能导致图片拉伸*/
		display: block;
	}

	.button-border {
		border-radius: 3px;
		color: #f63;
		font-size: 14px;
		border: 1px solid #f63;
		/* 	  width: 120px; */
		height: 20px !important;
		margin-left: 10px;
		word-break: break-all;
		overflow: hidden;
		text-align: center;
		padding-left: 5px;
		padding-right: 5px;
	}

	.copy-btn {
		display: flex;
		justify-content: flex-end;
		cursor: pointer;
	}

	.image-margin {
		margin-right: 4px;
	}

	.jmallArrow {
		width: 2%;
		cursor: pointer;
		transform: translateY(-20px);
	}

	.recommend-tip {
		margin: 20px 0;
		padding: 6px 0 6px 10px;
		border-bottom: 1px solid #f2f2f2;
		font-size: 20px
	}

	.white-background {
		background-color: #FFFFFF;
	}

	.img-box {
		display: flex;
		align-items: center;
		max-width: 250px;
		margin: 14px 14px 0 14px;
	}

	.goods-name {
		width: 80%;
		margin: 0 10%;
	}

	.img-timed {
		height: 38px;
		width: 100%;
		margin-top: 4px;
	}

	.TimedLeftTime-text {
		font-size: 16px;
		background-color: black;
		color: #FFFFFF;
		padding: 0px 4px;
		margin: 0px 4px;
		text-align: center;
		line-height: 20px;
		vertical-align: middle;
		border-radius: 5px;
	}

	.TimedLeftTime-lefttext {
		font-size: 13px;
		color: #FFFFFF;
		font-weight: bold;
	}

	.TimedLeftTime {
		display: flex;
		flex-direction: row;
		position: absolute;
		top: 13px;
		right: 18px;
	}

	.magin-stock {
		margin-left: 20px;
	}

	.margin-price {
		margin: 0 20px;
	}

	.goods_limit_text {
		color: #b6b6b6;
		line-height: 68px;
		font-size: 14px;
	}

	.tiered-pricing-item {
		padding: 0 8px 0 22px;
		text-align: center;
	}

	.tiered-pricing-num {
		padding-left: 10px;
	}

	.grey-color {
		color: #a6a6a6 !important;
	}

	.unit-txt {
		color: #999;
		margin-right: 20px;
	}

	.price-info {
		height: 82px;
	}

	.info-button {
		width: 160px;
		height: 68px;
		background: #fff2f2;
		border: 1px solid #f63e3e;
		border-radius: 4px;
		color: #f63e3e;
	}

	.my-danger-button {
		width: 160px;
		height: 68px;
		border: 1px solid #f63e3e;
		background: #f63e3e;
		border-radius: 4px;
	}

	.colon-color {
		color: #FFFFFF;
	}

	.goods1-sellcount {
		position: absolute;
		right: 0;
		color: #999;
		font-size: 14px;
	}

	.customfield-content {
		display: flex;
	}

	.customfield-name {
		color: #999999;
		font-size: 15px;
		margin: 5px 0;
		line-height: 18px;
		white-space: nowrap;
	}

	.goods_skucustomfield {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
	}

	.skucustomfield_item {
		/* flex: 1;
		width: 50%; */
		display: flex;
		flex-wrap: nowrap;
	}

	.img-list::-webkit-scrollbar {
		height: 3px;
	}

	.label-content {
		display: flex;
		flex-wrap: wrap;
		margin-top: 2px;
	}

	.label-item {
		height: 30px;
		line-height: 30px;
		margin-right: 15px;
		color: #b6b6b6;
		align-items: center;
		font-size: 13px;
		word-break: break-all;
		display: flex;
	}

	.label-ico {
		height: 20px;
		transform: translateY(-1px);
		margin-right: 5px;
	}
</style>