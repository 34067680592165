<template>
	<div id="app">
		<el-container>
			<el-header>
				<div v-if='$route.name != "error" && $route.name != "login"'>
					<!-- 头部 -->
					<v-header ref="vheader"></v-header>
				</div>
			</el-header>
			<el-main>
				<!-- 设置keepAlive为true的页面，页面被缓存，返回时不刷新 -->
				<keep-alive>
					<router-view v-if='$route.meta.keepAlive'></router-view>
				</keep-alive>
				<router-view v-if='!$route.meta.keepAlive && isRouterAlive'></router-view>
			</el-main>
		</el-container>
	</div>
</template>

<script>
	import vHeader from './components/common/Header.vue';
	import {
		getAllParam,
		getParam
	} from './utils/api-utils/api-params.js';
	import {
		getCustomerInfo
	} from './utils/api-utils/api-customer.js';
	export default {
		name: 'app',
		components: {
			'v-header': vHeader
		},
		provide() { // 父组件中返回要传给下级的数据
			return {
				reload: this.reload
			}
		},
		data() {
			return {
				isRouterAlive: true
			}
		},
		async created() {
			// 正式版访问需要检测当前版本
			if (location.href.includes("/jmc/pcmall")) {
				// 获取吉商城接口当前版本（用作灰度切换）
				let JmallApiVersion = await this.getJmallApiVersion();
				console.log("当前版本：" + JmallApiVersion);
				if (JmallApiVersion == "jmall-single") {
					let memberEncryptStr = localStorage.getItem("memberEncryptStr");
					location.replace(`https://jmall.jackyun.com/jmall_pc_single/pcmall/?${memberEncryptStr}`);
					return;
				}
			}

			// 加载系统参数
			await this.getSysParams();

			this.checkCustomer();
		},
		methods: {
			/**
			 * 获取吉商城接口当前版本（用作灰度切换）
			 */
			getJmallApiVersion() {
				return new Promise((resolve, reject) => {
					this.$request.jackyunPost({
						method: "sys.getsetting",
						content: "jmall_api_version,pcmall_version"
					}, (res) => {
						let data = ""
						if (res.code == 200) {
							data = res.data.jmall_api_version;
						}

						// 缓存当前版本信息
						localStorage.setItem("pcmall_version", res.data.pcmall_version);

						resolve(data || "jmall");
					})
				});
			},

			/**
			 * 获取系统配置参数，渠道仓库等
			 */
			async getSysParams() {
				let _this = this;
				let customerId = localStorage.getItem("pc_customerId");
				let memberName = localStorage.getItem("JackyunUserName");
				let subMemberName = localStorage.getItem("SubMemberName");
				let customerInfo = localStorage.getItem("pc_customerInfo");
				let searchHistory = localStorage.getItem("searchHistory");
				let pcmall_version = localStorage.getItem("pcmall_version");

				//清除缓存信息
				localStorage.clear();

				// 缓存customerId
				customerId && localStorage.setItem("pc_customerId", customerId);
				memberName && localStorage.setItem("JackyunUserName", memberName);
				subMemberName && localStorage.setItem("SubMemberName", subMemberName);
				customerInfo && localStorage.setItem("pc_customerInfo", customerInfo);
				searchHistory && localStorage.setItem("searchHistory", searchHistory);
				pcmall_version && localStorage.setItem("pcmall_version", pcmall_version);
				localStorage.setItem("refreshTimeObj", JSON.stringify({
					pc_customerInfo: new Date().getTime()
				}))

				// 首次进入刷新全库缓存参数
				await getAllParam(true);
				let paramList = await getParam("bindSaleChannelId,bindSaleChannelName,bindWarehouseId,defaultMemberGradeId,defaultMemberGradeName,commission_mode,pc_goodsDetailStyle,currencySymbol,pcmall_version,decimalKeepPlaces");
				if (paramList.bindSaleChannelId) {
					// 保存到缓存
					localStorage.setItem("pc_channelId", paramList.bindSaleChannelId);
					// 获取到店铺Id后，获取购物车总数
					this.$eventBus.$emit("updateHeaderShopCartCount");
				}

				// 绑定的吉客云渠道名称
				if (paramList.bindSaleChannelName) {
					// 保存到缓存
					localStorage.setItem("pc_channelName", paramList.bindSaleChannelName);
				}

				// 默认仓库ID
				if (paramList.bindWarehouseId) {
					// 保存到缓存
					localStorage.setItem("pc_warehouseId", paramList.bindWarehouseId);
				}

				// 默认会员等级ID
				if (paramList.defaultMemberGradeId) {
					// 保存到缓存
					localStorage.setItem("pc_defaultMemberGradeId", paramList.defaultMemberGradeId);
				}

				// 默认会员等级名称
				if (paramList.defaultMemberGradeName) {
					// 保存到缓存
					localStorage.setItem("pc_defaultMemberGradeName", paramList.defaultMemberGradeName);
				}

				// 分销模式
				localStorage.setItem('pc_commissionMode', paramList.commission_mode ? paramList
					.commission_mode : '');

				// 商品详情样式
				localStorage.setItem('pc_goodsDetailStyle', paramList.pc_goodsDetailStyle ? paramList
					.pc_goodsDetailStyle : 0);

				// 小数保留位数
				localStorage.setItem('decimalKeepPlaces', paramList.decimalKeepPlaces || 2);


				// 币种符号
				// this.$currency.setSymbol(paramList.currencySymbol || "￥");
				setTimeout(function() {
					let symbol = paramList.currencySymbol || "￥";
					_this.$currency.setSymbol(symbol);
				}, 5000);
			},

			// 校验customerInfo信息
			async checkCustomer() {
				// 获取缓存中信息
				if (!localStorage.getItem("pc_customerId")) {
					return;
				}

				// 用当前customerId查不到客户信息时退出登录
				let jmallCus = await getCustomerInfo({}, true);
				if (jmallCus.customerId) {
					return;
				}
				this.$refs.vheader.logout();
			},

			reload() {
				// header 变化之后更新数据
				this.isRouterAlive = false;
				this.$nextTick(function() {
					this.isRouterAlive = true
				})
			},
		}
	}
</script>

<style>
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.el-header {
		height: 50px !important;
		padding: 0 !important;
	}

	.el-main {
		padding: 0 !important;
		position: relative;
	}

	*::-webkit-scrollbar {
		width: 9px;
		height: 9px;
	}

	*::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: #c1c1c1;
	}

	*::-webkit-scrollbar-track {
		border-radius: 10px;
		background: #f1f1f1;
	}

	.min-scroll::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 5px !important;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 5px !important;
	}

	.min-scroll::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 10px !important;
		background: #c1c1c1 !important;
	}

	.min-scroll::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		border-radius: 10px !important;
		background: #f1f1f1 !important;
	}
</style>