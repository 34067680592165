import requestUtil from '../request-utils.js';
import goodsUtils from './api-goods.js';
import goodsValidataUtils from './api-goods-validitydate.js';
import {
	getTimelinesData,
	setTimelinesData
} from './data-refresh.js';
import {
	constant
} from '../../config.js';
import {
	getMarketingInfoListBySpec,
	getSpecTimedSaleInfoList
} from './api-activity.js';
import {
	getCustomerInfo
} from './api-customer.js';
import commonUtlis from '../common-utlis.js';

/**
 * 购物车列表
 * @param {*} cartType 
 * @param {*} cartIdList 
 * @param {*} isRefresh 
 * @param {*} isGetStock 
 * @param {*} isAllowOutOfStock 
 * @param {*} isGetValidityDate 
 * @param {*} isSaveCartCache 是否需要保存到缓存
 * @param {*} isAutoChangeGoodsCount 是否自动变更数量
 */
const getShoppingCartList = async (cartType, cartIdList = [], isRefresh = false, isGetStock = true, isAllowOutOfStock = false, isGetValidityDate = true, isSaveCartCache = true, isAutoChangeGoodsCount = true) => {
	// 获取渠道Id
	let channelId = localStorage.getItem("pc_channelId");
	// 获取客户信息
	let customerInfo = await getCustomerInfo();
	let customerId = customerInfo.customerId;
	let memberGrade = (customerInfo.customerId && (customerInfo.vipLevel || "no")) || "all";

	// 未获取到用户信息或者渠道信息，则不读取购物车信息
	if (!customerId || !channelId) {
		return [];
	}

	// 获取基础购物车数据
	let shoppingCartList = await _getShoppingCartListFromOrigin(customerId, channelId, cartType, cartIdList);
	// 检测购物车数据是否存在
	if (!(shoppingCartList && shoppingCartList.length > 0)) {
		return [];
	}

	// 从缓存获取未变更的购物车列表
	let noDifferCartList = isRefresh ? [] : _getNoDifferDataFromCache(shoppingCartList);
	let noDifferCartIdList = noDifferCartList.map(t => t.cartId);

	// 筛选出变更了的数据
	let differCartList = shoppingCartList.filter(t => !noDifferCartIdList.includes(t.cartId));

	// 补充所有扩展信息（营销活动、抢购活动、库存、价格、有效期、是否是会员等级限制商品）
	await _completeAllExtendedInfo(differCartList, isGetStock, isGetValidityDate);

	// 处理最小购买量和单次购买量
	_checkGoodsCount(differCartList, isAllowOutOfStock, isAutoChangeGoodsCount);

	// 拼接差异和非差异数据
	shoppingCartList = differCartList.concat(noDifferCartList);

	// 补充是否是会员等级限制商品扩展信息 (要针对所有购物车数据进行检测)
	await _completeMemberBlockUpGoodsInfo(shoppingCartList)

	// 数据处理及排序（补充中间数据、营销活动价格计算、无效数据置底等）
	shoppingCartList = _cartDataProcessingAndSort(shoppingCartList, memberGrade);

	// 将数据保存到缓存
	if (isSaveCartCache) {
		setTimelinesData(constant.DATA_KEY_CART_CACHE, shoppingCartList);
	}

	return shoppingCartList;
}

/**
 * 获取原始购物车列表
 * @param {*} customerId 
 * @param {*} channelId 
 * @param {*} cartType 
 * @param {*} cartIdList 
 */
const _getShoppingCartListFromOrigin = async (customerId, channelId, cartType, cartIdList) => {
	return new Promise((resolve, reject) => {
		requestUtil.jackyunPost({
			method: "jmall.goods.getshoppingcartlist",
			customerId,
			channelId: "0",
			cartType,
			cartIdList: cartIdList.join(',')
		}, (res) => {
			let cartList = [];
			// 检测获取结果是否正确
			if (res.code == 200 && res.result.data && res.result.data.length > 0) {
				cartList = res.result.data;
				// 过滤掉无效数据
				try {
					cartList = cartList.filter(t => t.goodsCount > 0);
					cartList.forEach(t => {
						t.minCount = t.minCount > 0 ? t.minCount : 1;
						t.stepCount = t.stepCount > 0 ? t.stepCount : 1;
					});
				} catch (e) {}
			}
			// 返回结果数据
			resolve(cartList);
		});
	});
}

/**
 * 从缓存获取未变更的购物车列表
 * @param {*} shoppingCartList 
 */
const _getNoDifferDataFromCache = (shoppingCartList) => {
	// 获取缓存中数据
	let cacheCartList = getTimelinesData(constant.DATA_KEY_CART_CACHE) || [];
	if (!(cacheCartList.length > 0)) {
		return [];
	}

	// 未变更数据列表
	let noDifferCartList = [];
	// 遍历货品，进行差异检测
	shoppingCartList.forEach(cart => {
		// 从缓存中获取当前商品对应的数据
		let cacheCart = cacheCartList.find(t => t.cartId == cart.cartId) || {};

		// 缓存数据存在并且未变更数量的直接获取缓存
		if (!!cacheCart.cartId && cacheCart.goodsCount == cart.goodsCount && cacheCart.minCount == cart.minCount && cacheCart.stepCount == cart.stepCount) {
			noDifferCartList.push(cacheCart);
		}
	});

	return noDifferCartList;
}

/**
 * 补充所有扩展信息（营销活动、抢购活动、库存、价格、有效期）
 * @param {*} goodsList 
 */
const _completeAllExtendedInfo = (shoppingCartList, isGetStock, isGetValidityDate) => {
	// 请求集合
	return new Promise(async (resolve, reject) => {
		let promiseList = [
			__completeMarketingInfo(shoppingCartList),
			__completeTimedActivityInfo(shoppingCartList),
			__completeStockInfo(shoppingCartList, isGetStock),
			__completePriceInfo(shoppingCartList),
			__completeSpecValidityDate(shoppingCartList, isGetValidityDate),
		];

		// 等待所有接口执行完成
		Promise.all(promiseList).then(() => {
			resolve();
		});
	});
}

/**
 * 补充购物车的营销活动信息
 * @param {*} shoppingCartList 
 */
const __completeMarketingInfo = (shoppingCartList) => {
	return new Promise(async (resolve, reject) => {
		// 获取规格信息
		let specIdList = shoppingCartList.filter(t => ![1, 2].includes(t.actType)).map(t => t.specId);

		// 没有规格信息则不需要获取
		if (!(specIdList && specIdList.length > 0)) {
			resolve();
			return;
		}

		// 获取规格的营销活动信息
		let allSpecMarketingInfoList = await getMarketingInfoListBySpec(specIdList);
		// 没有营销活动信息，则直接跳出
		if (!(allSpecMarketingInfoList && allSpecMarketingInfoList.length > 0)) {
			resolve();
			return;
		}

		// 遍历购物车，补充活动信息
		shoppingCartList.forEach(cart => {
			// 找到当前商品的活动信息
			let cartMarketingInfo = allSpecMarketingInfoList.filter(t => t.specId == cart.specId && t.unitName == cart.unitName);
			if (cartMarketingInfo && cartMarketingInfo.length > 0) {
				// 补充营销活动信息到购物车
				cart.marketingInfoList = cartMarketingInfo;
				return;
			}

			// 当前单位没有直接设置促销活动，则找到基础单位的策略，进行换算获取
			let baseUnitMarketingInfoList = allSpecMarketingInfoList.filter(t => t.specId == cart.specId && t.unitName == cart.baseUnit);
			if (baseUnitMarketingInfoList.length <= 0) {
				return;
			}
			// 基础单位转换率
			let countRate = parseFloat(cart.unitCountRate);
			// 新的营销活动策略
			let convertMarketingInfoList = [];
			baseUnitMarketingInfoList.forEach(item => {
				let marketingItem = JSON.parse(JSON.stringify(item));
				// 执行价格向上保留两位小数
				marketingItem.favorablePrice = Math.ceil(item.favorablePrice * countRate * 100) / 100;
				// 向上取整
				marketingItem.limitCount = Math.ceil(item.limitCount / countRate);
				marketingItem.upperLimitCount = Math.ceil(item.upperLimitCount / countRate);
				marketingItem.unitName = cart.unitName;
				convertMarketingInfoList.push(marketingItem);
			});
			// 根据辅助单位生产的促销价格
			cart.marketingInfoList = convertMarketingInfoList;
		});
		resolve();
	});
}


/**
 * 补充购物车的限时抢购活动信息
 * @param {*} customerId 
 * @param {*} shoppingCartList
 */
const __completeTimedActivityInfo = (shoppingCartList) => {
	return new Promise(async (resolve, reject) => {
		// 获取活动商品的规格信息
		let specInfoList = shoppingCartList.filter(t => t.actType == 1 && t.actId > 0).map(t => {
			return {
				saleId: t.actId,
				specId: t.specId
			};
		});

		// 没有活动信息则不需要获取
		if (!(specInfoList && specInfoList.length > 0)) {
			resolve();
			return;
		}

		// 获取规格的活动信息
		let timedActivityInfoList = await getSpecTimedSaleInfoList(specInfoList);
		// 没有活动信息，则直接跳出
		if (!(timedActivityInfoList && timedActivityInfoList.length > 0)) {
			resolve();
			return;
		}

		// 遍历购物车，补充活动信息
		shoppingCartList.forEach(cart => {
			// 找到当前商品的活动信息
			let actInfo = timedActivityInfoList.find(t => t.specId == cart.specId && t.saleId == cart.actId && t.unit == cart.unitName);
			if (actInfo && actInfo.saleId) {
				// 将限时抢购活动价格填充到执行价格
				cart.executionPrice = actInfo.price;
				// 补充活动信息到购物车
				cart.timedActivityInfo = actInfo;
				// 抢购活动的最小购买量和单次购买量忽略
				cart.minCount = actInfo.minCount || 1;
				cart.stepCount = 1;
			}
		});
		resolve();
	});
}


/**
 * 补充购物车的库存信息
 * @param {*} shoppingCartList 
 * @param {*} isGetStock 
 */
const __completeStockInfo = (shoppingCartList, isGetStock) => {
	return new Promise(async (resolve, reject) => {
		// 无数据不做处理
		if (!(shoppingCartList && shoppingCartList.length > 0)) {
			resolve();
			return;
		}

		// 如果不需要读取库存，则默认赋值9999
		if (!isGetStock) {
			shoppingCartList.forEach(t => {
				t.stock = 99999;
				t.stockDesc = goodsUtils.getStockDesc(t.stock, "shopcart");
			});
			resolve();
			return;
		}

		// 获取sku库存列表
		let specIdList = [...new Set(shoppingCartList.map(t => t.specId))];
		let skuStockList = await commonUtlis.getAllNeedInfoByConfigList(specIdList, goodsUtils.getSkuStockList);

		// 遍历需要补充的sku列表，进行价格补充
		shoppingCartList.forEach(cart => {
			// 获取当前规格的库存信息
			let stockInfo = skuStockList.find(t => t.skuId == cart.specId && t.unitName == cart.unitName) || {};
			// 补充库存
			cart.stock = stockInfo.stock || 0;
			cart.stockDesc = goodsUtils.getStockDesc(cart.stock, "shopcart");
		});
		resolve();
	});
}

/**
 * 补充购物车的价格信息
 * @param {*} shoppingCartList 
 */
const __completePriceInfo = (shoppingCartList) => {
	return new Promise(async (resolve, reject) => {
		// 无数据不做处理
		if (!(shoppingCartList && shoppingCartList.length > 0)) {
			resolve();
			return;
		}

		// 获取sku价格列表
		let specIdList = [...new Set(shoppingCartList.map(t => t.specId))];
		let skuPriceList = await commonUtlis.getAllNeedInfoByConfigList(specIdList, goodsUtils.getSkuPriceList);
		// 遍历需要补充的sku列表，进行价格补充
		shoppingCartList.forEach(cart => {
			// 获取当前规格的价格信息
			let priceInfo = skuPriceList.find(t => t.skuId == cart.specId && t.unitName == cart.unitName) || {};
			// 基础价格补充
			cart.memberPrice = priceInfo.memberPrice || 0; // 会员价
			cart.referencePrice = priceInfo.referencePrice || 0; // 参考价（综合零售价和付费会员执行价）
			cart.executionPrice = priceInfo.executionPrice || 0; // 最终执行价（营销活动会根据数量等调整）
			cart.score = priceInfo.score || 0; // 积分
			cart.isBargainGoods = priceInfo.isBargainGoods; // 是否为特价商品
			// // 未获取到价格，则设置为无效商品，防止资损
			// if (!priceInfo.executionPrice && priceInfo.executionPrice != 0) {
			//   // // 数据是否失效初始值设定
			//   cart.isInvalid = true;
			//   cart.invalidReason = "商品价格未设置或已下架";
			// }
		});
		resolve();
	});
}

/**
 * 补充有效期信息
 * @param {*} shoppingCartList 
 * @param {*} isGetValidityDate 
 */
const __completeSpecValidityDate = (shoppingCartList, isGetValidityDate) => {
	return new Promise(async (resolve, reject) => {
		// 未开启或者无数据，则不执行
		if (!isGetValidityDate || !(shoppingCartList && shoppingCartList.length > 0)) {
			resolve();
			return;
		}
		// 补充有效期信息
		await goodsValidataUtils.completeSpecValidityDateInfo(shoppingCartList);
		resolve();
	});
}

/**
 * 处理最小购买量和单次购买量
 * @param {*} shoppingCartList 
 * @param {*} isAllowOutOfStock 
 * @param {*} isAutoChangeGoodsCount 
 */
const _checkGoodsCount = (shoppingCartList, isAllowOutOfStock, isAutoChangeGoodsCount) => {
	// 遍历购物车，补充活动信息
	shoppingCartList.forEach(cart => {
		// 普通商品补充可购数量
		cart.canBuyCount = cart.stock || 0;

		// 是否为抢购商品，是则需要根据活动剩余商品重新计算
		if (cart.timedActivityInfo && cart.timedActivityInfo.saleId) {
			cart.isTimeSaleGoods = true;
			cart.timedActivityInfo.goodsCount = cart.timedActivityInfo.goodsCount || 9999 // 为空则不限
			cart.timedActivityInfo.limitCount = cart.timedActivityInfo.limitCount || 9999 // 为空则不限

			// 计算剩余数量
			let leftGoodsCount = cart.timedActivityInfo.goodsCount - cart.timedActivityInfo.boughtCount;
			// 比较库存和抢购商品数量，较小者作为库存
			cart.stock = isAllowOutOfStock ? leftGoodsCount : Math.min(cart.stock, leftGoodsCount);
			// 处理每人限购情况
			cart.canBuyCount = cart.stock;
		} else {
			// 处理每人限购情况
			cart.canBuyCount = isAllowOutOfStock ? 99999999 : cart.stock;
		}

		if (cart.timedActivityInfo && cart.timedActivityInfo.limitCount > 0) {
			// 计算当前用户还可以购买的数量
			let canBuyCount = cart.timedActivityInfo.limitCount - (cart.timedActivityInfo.selfBoughtCount || 0);
			// 和库存取小处理
			cart.canBuyCount = Math.min(cart.canBuyCount, canBuyCount);
		}

		// 控制基础数量范围
		let newGoodsCount = cart.goodsCount;
		// 限时抢购的限制购买数量是设置在具体单位上的不需要再根据比例换算
		let tempUnitCountRate = cart.isTimeSaleGoods ? 1 : cart.unitCountRate;
		if ((cart.goodsCount * tempUnitCountRate) < cart.minCount) {
			newGoodsCount =  cart.minCount < cart.canBuyCount ? cart.minCount : cart.canBuyCount;
		} else if (cart.goodsCount > cart.canBuyCount) {
			newGoodsCount = cart.canBuyCount;
		}

		// 如果库存不足引起的库存被置为0，需要设置为最小可购量
		if (newGoodsCount <= 0) {
			newGoodsCount = cart.minCount;
		}

		// 计算正确应有的正确数量（符合公式时，不会变更数量：count = min + n*step ）
		newGoodsCount = cart.minCount + Math.floor((newGoodsCount - cart.minCount) / cart.stepCount) * cart.stepCount;

		// 如果库存变更了，需要修改数量
		if (isAutoChangeGoodsCount && newGoodsCount != cart.goodsCount) {
			cart.goodsCount = newGoodsCount;
			// 更新数据库
			updateShoppingCartCount(cart.cartId, newGoodsCount);
		}
	});
}

/**
 * 更新购物车数量
 * @param {*} cartId 
 * @param {*} goodsCount 
 */
const updateShoppingCartCount = async (cartId, goodsCount) => {
	return new Promise((resolve, reject) => {
		requestUtil.jackyunPost({
			method: "jmall.goods.updateshopcartgoodscount",
			cartId,
			goodsCount
		}, (res) => {
			// 返回结果数据
			resolve(res.code == 200);
		});
	});
}

/**
 * 补充是否是会员等级限制商品信息
 * @param {*} shoppingCartList 
 */
const _completeMemberBlockUpGoodsInfo = (shoppingCartList) => {
	return new Promise(async (resolve, reject) => {
		// 无数据不做处理
		if (!(shoppingCartList && shoppingCartList.length > 0)) {
			resolve();
			return;
		}
		// 会员等级限制商品列表
		let goodsIdList = shoppingCartList.map(item => item.goodsId)
		let memberBlockList = await goodsUtils.checkMemberBlockGoods(goodsIdList);

		// 遍历需要补充的sku列表，进行价格补充
		shoppingCartList.forEach(cart => {
			// 先置空
			cart.isMemberBlockUp = ""
			// 判断是否含有会员等级限制商品，有则设置isInvalid
			if (memberBlockList.map(item => item.goodsId).includes(cart.goodsId)) {
				cart.isMemberBlockUp = 1; //是会员等级限制商品 提示商品已下架
			}
		});
		resolve();
	});
}

/**
 * 数据处理及排序（补充中间数据、营销活动价格计算、无效数据置底等）
 */
const _cartDataProcessingAndSort = (shoppingCartList, memberGrade) => {
	// 有效的数据列表
	let validList = [];
	// 下架的商品列表
	let offShelfList = [];
	// 删除的商品列表
	let deleteList = [];
	// 无效的活动的商品列表
	let invalidActList = [];

	// 遍历购物车，拆分出以上类型数据
	shoppingCartList.forEach((cart) => {
		// 补充抢购和营销活动缺失对象
		cart.timedActivityInfo = cart.timedActivityInfo || {};
		cart.marketingInfoList = cart.marketingInfoList || [];
		cart.marketingInfo = cart.marketingInfo || {};

		// 存在抢购活动相关数据
		if (!!cart.timedActivityInfo.saleId) {
			cart.executionPrice = cart.timedActivityInfo.price;
			cart.score = cart.timedActivityInfo.score;
			// 比较库存和抢购商品数量，较小者作为库存(无需再次处理，前置库存检测已经处理了)
			// cart.stock = Math.min(cart.stock, cart.timedActivityInfo.goodsCount);
			// 去除营销活动信息（防止展示标签冲突）
			cart.marketingInfoList = [];
			// IOS时间格式兼容处理
			try {
				cart.timedActivityInfo.beginTime = cart.timedActivityInfo.beginTime.replaceAll('-', '/');
				cart.timedActivityInfo.endTime = cart.timedActivityInfo.endTime.replaceAll('-', '/');
			} catch (e) {}
		}

		// 营销活动价格计算（根据商品数量，重新计算执行价）
		if (cart.marketingInfoList && cart.marketingInfoList.length > 0) {
			// 获取匹配的营销活动信息
			let marketingInfo = cart.marketingInfoList.find(t => cart.goodsCount >= t.limitCount && cart.goodsCount <= t.upperLimitCount);

			// 满足数量要求，则执行营销活动价
			if (marketingInfo) {
				cart.originalExecutionPrice = cart.executionPrice;
				cart.executionPrice = marketingInfo.favorablePrice;
				cart.marketingInfo = marketingInfo;
			} else {
				cart.executionPrice = cart.originalExecutionPrice = cart.memberPrice;
				cart.marketingInfo = {};
			}
		}

		// 默认仓库数据兼容（后端没有仓库会将字段隐藏）
		cart.defaultWarehouseName = cart.defaultWarehouseName || "";

		// 展示价格初值
		cart.priceReal = 0;
		// 判断失效状态和原因，用作无效数据置底（如果前置流程中已有设置过失效，那就不重复检测）
		if (cart.invalidReason) {
			invalidActList.push(cart);
		} else {
			// 数据是否失效初始值设定
			cart.isInvalid = true;
			cart.invalidReason = "";
			if (cart.isBlockup == 1 || cart.isMemberBlockUp == 1) { // 商品下架
				cart.invalidReason = "商品已下架";
				offShelfList.push(cart);
			} else if (cart.isDeleted == 1) { // 商品被删除
				cart.invalidReason = "商品已失效";
				deleteList.push(cart);
			} else if (cart.actId > 0 && !(cart.timedActivityInfo && cart.timedActivityInfo.saleId)) { // 抢购活动中商品被删除
				cart.invalidReason = "活动已失效";
				invalidActList.push(cart);
			} else if (cart.timedActivityInfo &&
				(cart.timedActivityInfo.isDeleted == 1 ||
					new Date(cart.timedActivityInfo.beginTime).getTime() > new Date().getTime() ||
					new Date(cart.timedActivityInfo.endTime).getTime() < new Date().getTime() || (cart.timedActivityInfo.memberGrade && cart.timedActivityInfo.memberGrade != "all" && !cart.timedActivityInfo.memberGrade.includes(memberGrade)))) { // 抢购活动失效或者删除
				cart.invalidReason = "活动已失效";
				invalidActList.push(cart);
			} else if (cart.actId > 0 && cart.canBuyCount <= 0) { // 抢购活动限购
				cart.invalidReason = "每人限购上限";
				invalidActList.push(cart);
			} else {
				cart.isInvalid = false;
				validList.push(cart);
			}
		}

		// 处理默认组合装商品标记，置空，避免后续流程误判
		cart.packageGoodsId = !!cart.packageGoodsId && cart.packageGoodsId != "0" ? cart.packageGoodsId : "";
		cart.packageSpecId = !!cart.packageSpecId && cart.packageSpecId != "0" ? cart.packageSpecId : "";
	});

	// 合并结果，重新赋值给购物车列表
	return [...validList, ...offShelfList, ...invalidActList, ...deleteList];
}

/**
 * 清除购物车缓存
 */
const removeCartCache = () => {
	localStorage.removeItem(constant.DATA_KEY_CART_CACHE)
}

/**
 * 获取购物车数量
 */
const getShoppingCartCount = () => {
	// 未获取到用户信息或者渠道信息，则不读取数据
	let customerId = localStorage.getItem("pc_customerId");
	let channelId = localStorage.getItem("pc_channelId");
	if (!customerId || !channelId) {
		return 0;
	}
	return new Promise((resolve, reject) => {
		requestUtil.jackyunPost({
			method: "jmall.goods.getshoppingcartcount",
			customerId,
			channelId: "0",
			cartType: 0
		}, (res) => {
			// 检测获取结果是否正确
			let data = 0;
			if (res.code == 200 && res.result.data) {
				data = parseInt(res.result.data || 0);
			}
			// 返回结果数据
			resolve(data);
		});
	});
}

export {
	getShoppingCartList,
	updateShoppingCartCount,
	removeCartCache,
	getShoppingCartCount
}