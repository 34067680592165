import requestUtil from '../request-utils.js'

/**
 * 获取渠道详情
 * @param {Object} channelId
 */
function getChannelInfo(channelId, refresh = false) {
	return new Promise((resolve, reject) => {
		//  补充渠道ID
		channelId = !channelId ? localStorage.getItem("pc_channelId") : channelId;

		// 优先从缓存中获取信息
		let channelInfo = localStorage.getItem("pc_channelInfo");
		if (!refresh && channelInfo && channelInfo.channelId) {
			resolve(JSON.parse(channelInfo));
			return;
		}

		// 缓存中没有信息，则调用接口获取最新数据
		requestUtil.jackyunPost({
			method: 'salechannel.detail',
			salechannelId: channelId
		}, (res => {
			if (res.code === 200) {
				// 保存到缓存
				localStorage.setItem("pc_channelInfo", JSON.stringify(res.result.data));
				resolve(res.result.data);
			} else {
				resolve(null);
			}
		}));
	});
}

//可以被外部引用的方法
export {
	getChannelInfo
}