<!-- 商品列表 -->
<template>
	<div ref="scrollDiv" @mousewheel="MouseWheel($event)">
		<div id="goodsList">
			<!-- 顶部广告栏 -->
			<div :hidden="!goodsAdsNotice" class="goods-ads-notice">
				<img :src="$utils.setImg(goodsAdsNotice)" @error="$utils.setImg('')" />
			</div>
			<div class="container">
				<!-- 排序搜索操作栏 -->
				<div class="flex-row goods-filter-wrap">
					<div class="cate-filter" v-on:click="openGoodsCate" @mouseleave="closeGoodsCate">
						<i class="el-icon-menu"></i>
						<span class="cate-name text-ellipsis">{{categoryName}}</span>
						<goods-cate v-if="initCate" v-show="showGoodsCate" :pCateId="pCategoryId" :cateId="categoryId" :fCateId="fCategoryId" v-on:close-goods-cate="closeGoodsCate" v-on:select-cate="selectCategory"></goods-cate>
					</div>
					<div class="order-btn" :class="{active:orderBy=='goodsName'}" @click="orderTap('goodsName')">
						默认
					</div>
					<div class="order-btn" :class="{active:orderBy=='createTime'}" @click="orderTap('createTime')">
						最新
					</div>
					<div class="order-btn" v-if="isShowSellCount" :class="{active:orderBy=='sell_count_total'}" @click="orderTap('sell_count_total')">销量
					</div>
					<div v-if="isShowPriceSort" class="order-btn" :class="{active:orderBy=='price'}">
						<span @click="orderTap('price')">价格</span>
						<div style="position: relative;">
							<div @click="orderSortTap('ASC')" class="sort-edge sort-asc" :class="orderBy!='price'?'':orderSort == 'ASC'?'sort-asc-choose':''">
							</div>
							<div @click="orderSortTap('DESC')" class="sort-edge sort-desc" :class="orderBy!='price'?'':orderSort == 'DESC'?'sort-desc-choose':''">
							</div>
						</div>
					</div>
					<div class="search-wrap">
						<search @searchGoods="searchGoods" :showPlaceholder="true"></search>
						<!-- <el-input v-model="searchValue" @keyup.13.native="searchGoods">
							<el-button type="danger" slot="append" icon="el-icon-search" @click="searchGoods">
							</el-button>
						</el-input> -->
					</div>
				</div>

				<div class="goods-list-tip" v-if="!loading && goodsList.length == 0">
					<div class="no-result-text1">没有搜索结果</div>
					<div class="no-result-text2">没有找到相关的宝贝</div>

				</div>

				<!-- 商品列表 -->
				<div v-show="goodsList.length != 0" id="goodsListWrap" class="goods-list-wrap" v-calcHeight="!!goodsAdsNotice">
					<div class="goods-list flex-row">
						<div class="goods-item flex-col" v-for="(item,index) in goodsList" :data-goodsid="item.goodsId" @click="toGoodsDetail(item)">
							<div class="button-border" v-show="item.labelName && item.satisfyMarketing">
								{{item.labelName}}
							</div>
							<div class="goods-img-wrap">
								<img class="goods-img" :src="$utils.set400Img(item.coverPic)" @error="$utils.set400Img('')" />
							</div>
							<div style="width: 100%;">
								<div v-if="showGoodsName" class="goods-name text-ellipsis" style="margin: 0 5%;" :title="item.goodsName">
									<span class="presale-style" v-if="item.presaleId">预售</span><span class="presale-style activity-label" v-if="item.activityType == '抢购'">抢购</span>{{item.goodsName}}
								</div>
								<div v-else class="goods-name text-ellipsis" style="margin: 0 5%;" :title="item.goodsNo">
									<span class="presale-style" v-if="item.presaleId">预售</span><span class="presale-style activity-label" v-if="item.activityType == '抢购'">抢购</span>{{item.goodsNo}}
								</div>
							</div>
							<div v-show="isShowGoodsNo && showGoodsName" style="width: 100%;">
								<div class="goods-no-text">
									{{item.goodsNo}}
								</div>
							</div>
							<div v-show="!!item.stockTxt && !item.presaleId" style="width: 100%;">
								<div class="goods-no-text">
									{{item.stockTxt}}
								</div>
							</div>
							<div class="flex-between" style="width: 100%;">
								<div class='goods-sub-title-home'>{{goodsValidityDateData[item.goodsId] || ""}}
								</div>
							</div>
							<img class='sold-out-img' src="../../assets/images/ico-soldout.svg" v-if="isShowOverStockTag &&!item.presaleId && item.stock <= 0 " />
							<div class="goods-price" v-show="isShowPrice">
								<div v-show="item.priceMember|| item.score==0 || !item.score" class="flex-row">
									<div v-show="item.priceMember || item.score==0 || !item.score">{{$currency.symbol}}{{$utils.filterTrailingZeros(item.priceMember)}}</div>
									<div v-show="item.priceMember && item.priceMember!=0 && item.score > 0" class="goods-score">+</div>
									<div v-show="item.score>0" class="goods-score">{{item.score}}积分</div>
									<div class='goods-price-detail' v-show="showPriceDetail&&(item.priceDetail || item.priceDetail===0)&&parseFloat(item.priceDetail)>parseFloat(item.priceMember)">
										{{$currency.symbol}}{{$utils.filterTrailingZeros(item.priceDetail)}}
									</div>
								</div>
								<i class="el-icon-shopping-cart-2" style="position: absolute; right: 10px;" :data-index="index" @click.stop="addShopCart(item)"></i>
							</div>
							<div class="sell-count" v-show="isShowSellCount">{{_getSellCountText(item.sellCountTotal||0,item.goodsType==1)}}</div>
							<div class="label-content">
								<span class="button-border_bottom" v-for="item in item.labelNameList">
									{{item}}
								</span>
							</div>
						</div>
					</div>


				</div>

				<div class="block flex-center" v-show="goodsList.length != 0">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pageIndex" :page-size="pageSize" :page-sizes="[20, 50]" layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
					</el-pagination>
				</div>
				<!-- <p v-if="loading" class="loading-txt">加载中...</p>
					<p v-else-if="noMore" class="loading-txt">没有更多了</p>
					<p v-else class="loading-txt">加载更多</p> -->
			</div>
		</div>

		<!-- 右下角浮动按钮 -->
		<float-btns v-if="goodsList.length != 0" scroll-target="goodsListWrap"></float-btns>
	</div>

</template>

<script>
	import GoodsCate from '../../components/goods/GoodsCate.vue';
	import FloatBtns from '../../components/common/FloatBtns.vue';
	import goodsUtils from '../../utils/api-utils/api-goods.js';
	import validityDateUtils from '../../utils/api-utils/api-goods-validitydate.js'
	import priceUtils from '../../utils/api-utils/api-goods-price.js';
	import {
		getParam
	} from '../../utils/api-utils/api-params.js';
	import Search from '../../components/common/Search.vue';
	export default {
		name: 'goodslist',
		components: {
			'goods-cate': GoodsCate,
			'float-btns': FloatBtns,
			'search': Search
		},
		directives: {
			calcHeight: {
				inserted: (el, binding) => {
					let height = "calc(100% - 80px)"
					if (binding.value) {
						height = "calc(100% - 180px)"
					}
					el.style.height = height
				},
			}
		},
		data() {
			return {
				goodsList: [], //商品列表
				searchValue: "", //搜索内容
				orderBy: "", //排序字段,目前只有倒序
				categoryId: '0', //选中的分类Id
				pCategoryId: '0', //选中的父分类Id
				categoryName: "全部", //选中的分类名称
				pageIndex: 1, //分页页码
				pageSize: 20, //分页页长
				showGoodsCate: false, //展示分类列表
				loading: false, //加载中
				noMore: false, //没有更多
				goodsAdsNotice: "", //广告通知
				scrollTop: 0,
				goodsValidityDateData: {},
				totalCount: 20,
				customerId: 0, //初始客户Id
				showPriceDetail: false, // 是否展示零售价
				isOpenZeroStockBuy: false, // 0库存下单
				OpenZeroStock_isOpenRemind: false, //开启0库存提醒
				OpenZeroStock_tipText: "", //提醒文本
				isOpenIndistinctStock: false, // 模糊库存
				showGoodsName: !['795330'].includes(localStorage.getItem("JackyunUserName")),
				isShowGoodsNo: false, // 是否展示商品编号
				isShowGoodsStock: false, // 是否展示库存
				isShowSellCount: false, // 是否展示销量
				goodsfrom: "",
				goodsIds: "",
				initCate: false,
				isShowPrice: false, // 是否展示价格
				isShowPriceSort: false, // 是否展示价格排序
			}
		},
		async created() {
			//获取传递的参数
			let params = this.$route.params;
			this.searchValue = params.search_value || "";
			this.orderBy = params.order_by || "";
			this.goodsfrom = params.goodsfrom || "";
			this.goodsIds = params.goodsIds || "";
			this.categoryId = params.category_id || '0';
			this.pCategoryId = params.pcategory_id || '0';
			this.fCategoryId = params.fcategory_id || '0';
			this.categoryName = params.category_name || "全部";
			this.groupId = params.groupId || "";
			//获取广告通知
			await this.getUserSetting();
		},
		watch: {
			async customerId(newName, oldName) {
				let that = this;
				if (!!newName && newName != 0) {
					this.reloadGoodsList();
				}

				// 判断是否展示价格
				this.isShowPrice = await this.$utils.getIsShowPrice();
			}
		},
		async mounted() {
			this.$eventBus.$on('setCustomerId', msg => {
				this.customerId = msg;
			});
			this.isShowPrice = await this.$utils.getIsShowPrice()
		},
		beforeRouteEnter(to, from, next) {
			//进入页面之前，判断是否是商品详情进入，是则不刷新
			if (['goodsdetail', 'timedsalegoodsdetail'].includes(from.name)) {
				to.meta.refreshPage = false;
			} else {
				to.meta.refreshPage = true;
			}
			next();
		},
		//在页面离开时记录滚动位置
		beforeRouteLeave(to, from, next) {
			try {
				// 获取div内部滚动距离
				let scrollTop = document.getElementsByClassName('goods-list-wrap')[0].scrollTop;
				localStorage.setItem('scrollTop', scrollTop);
				next();
			} catch (e) {}
		},
		activated() {
			this.$eventBus.$on('setCustomerId', msg => {
				this.customerId = msg;
			});
			//来源不是商品详情，刷新列表
			if (this.$route.meta.refreshPage) {
				let params = this.$route.params;
				this.searchValue = params.search_value || "";
				this.orderBy = params.order_by || "";
				this.categoryId = params.category_id || '0';
				this.pCategoryId = params.pcategory_id || '0';
				this.fCategoryId = params.fcategory_id || '0';
				this.categoryName = params.category_name || "全部";
				this.goodsfrom = params.goodsfrom || "";

				this.goodsIds = params.goodsids || "";
				//加载商品列表
				this.reloadGoodsList();
			} else {
				let scrollTop = localStorage.getItem('scrollTop');
				localStorage.removeItem('scrollTop');
				document.getElementsByClassName('goods-list-wrap')[0].scrollTo(0, scrollTop);
			}
			this.initCate = true;
		},
		methods: {
			handleSizeChange(val) {
				this.pageSize = val;
				this.getGoodsList();
			},
			handleCurrentChange(val) {
				this.pageIndex = val;
				this.getGoodsList();
			},

			// 获取用户配置
			async getUserSetting() {
				let paramList = await getParam("goodslist_AdsNotice,isOpenShowDetailPrice,isOpenZeroStockBuy,isShowGoodsNo,OpenZeroStock_isOpenRemind,OpenZeroStock_tipText,isOpenIndistinctStock,isShowSellCount,isShowGoodsStock,isShowStockOuts,isShowOverStockTag,isShowPriceSort")
				this.goodsAdsNotice = paramList.goodslist_AdsNotice || "";
				this.showPriceDetail = paramList.isOpenShowDetailPrice == "1";
				this.isOpenZeroStockBuy = paramList.isOpenZeroStockBuy == "1";
				this.isShowGoodsNo = paramList.isShowGoodsNo == "1";
				this.OpenZeroStock_isOpenRemind = paramList.OpenZeroStock_isOpenRemind == "1";
				this.OpenZeroStock_tipText = paramList.OpenZeroStock_tipText;
				this.isOpenIndistinctStock = paramList.isOpenIndistinctStock == "1";
				this.isShowSellCount = paramList.isShowSellCount == "1";
				this.isShowGoodsStock = paramList.isShowGoodsStock == "1";
				this.isShowStockOuts = paramList.isShowStockOuts != "0";
				this.isShowOverStockTag = paramList.isShowOverStockTag != "0";
				this.isShowPriceSort = paramList.isShowPriceSort != "0"; // 是否展示价格排序
			},
			//展示商品分类
			openGoodsCate() {
				this.showGoodsCate = true;
			},
			//隐藏商品分类
			closeGoodsCate() {
				this.showGoodsCate = false;
			},
			//选择商品分类
			selectCategory(res) {
				this.categoryId = res.cateId;
				this.pCategoryId = res.pCateId;
				this.categoryName = res.cateName;
				this.showGoodsCate = false;
				this.reloadGoodsList();
			},
			//商品排序
			orderTap(orderBy) {
				if (orderBy == this.orderBy) {
					this.orderBy = "goodsName";
					this.orderSort = "DESC";
				} else {
					this.orderBy = orderBy;
					this.orderSort = 'DESC';
				}
				if (orderBy == "price") {
					this.orderBy = "price";
					this.orderSort = "ASC";
				}

				this.reloadGoodsList();
			},
			// 排序
			orderSortTap(sort) {
				this.orderSort = sort;
				this.orderBy = 'price';
				this.reloadGoodsList();
			},
			//搜索商品
			searchGoods(searchValue) {
				this.searchValue = searchValue
				this.reloadGoodsList();
			},
			//加载商品列表
			async getGoodsList() {
				let that = this;
				//防止重复加载
				if (this.loading || this.noMore) {
					return;
				}
				this.loading = true;

				let orConditionsJsonStr = {
					fuzzyValue: that.searchValue
				};

				let channelId = "0";
				let andConditionJsonStr = {
					categoryIds: that.categoryId,
					goodsIds: that.goodsIds,
					isDistributorGoods: false,
					channelId: channelId,
					isBlockUp: "0",
					groupIds: this.groupId ? this.groupId.split(",") : "",
				};
				let goodsList = await goodsUtils.getGoodsRequest(that.orderBy, that.pageIndex, that.pageSize,
					andConditionJsonStr, orConditionsJsonStr, that.orderSort);
				// 补充完整的货品信息（价格、库存、营销活动信息等）
				goodsList = await goodsUtils.completeFullGoodsInfoForList(goodsList, "goodsList");
				goodsList.forEach(item => {
					if (!!item.unitSetting) {
						let unitSetting = JSON.parse(item.unitSetting);
						item.isMultiUnit = unitSetting.length > 1
					}
				})
				this.goodsList = goodsList;
				let goodsIds = goodsList.map(item => item.goodsId);

				// 补充保质期
				this._getvaliditydate(goodsIds);
				// 存在goodsIds數量為goodsIds數量
				if (!!that.goodsIds) {
					this.totalCount = parseInt(that.goodsIds.split(',').length);
				} else {
					this.totalCount = await goodsUtils.getGoodsCount(andConditionJsonStr, orConditionsJsonStr);
				}

				this.loading = false;
			},
			//刷新商品列表
			reloadGoodsList() {
				this.pageIndex = 1;
				this.goodsList = [];
				this.noMore = false;
				this.getGoodsList();
			},
			MouseWheel(e) {
				let eventDelta = -e.wheelDelta || -e.deltaY * 40;
				let scrollDiv = document.getElementsByClassName('goods-list-wrap')[0];
				scrollDiv.scrollTop = scrollDiv.scrollTop + eventDelta / 2;
			},

			// 获取保质期
			async _getvaliditydate(goodsIds) {
				let that = this;
				let validateList = await validityDateUtils.getValidityDateByMultiCondition(goodsIds);
				// 已有的保质期库存
				let goodsValidityDateData = that.goodsValidityDateData;

				for (let i = 0; i < validateList.length; i++) {
					let item = validateList[i]
					item.validityDate = await this.$utils.getFormatValidate(item, false);
					goodsValidityDateData[item.goodsId] = goodsValidityDateData[item.goodsId] || (!!item.validityDate ? item.validityDate : "");
				}

				that.goodsValidityDateData = undefined;
				that.goodsValidityDateData = goodsValidityDateData;
			},
			//跳转商品详情
			toGoodsDetail(good) {
				let goods_id = good.goodsId;
				// 跳转抢购
				if (good.activityType == "抢购") {
					this.$router.push({
						name: 'timedsalegoodsdetail',
						query: {
							saleId: good.activityId,
							goodsId: good.goodsId
						}
					})
					return
				}

				let encode = window.btoa(window.encodeURIComponent(goods_id))
				this.$router.push({
					name: 'goodsdetail',
					query: {
						id: encode
					}
				});
			},
			/**
			 * 加入购物车请求
			 * @param {Object} goods
			 */
			async addShopCartRequest(goods) {
				// 基础单位比率为1
				let cart = {
					goodsId: goods.goodsId,
					goodsCount: goods.minCount,
					unitName: goods.unit,
					specId: 0,
					unitCountRate: "1",
				};
				let cartId = await goodsUtils.addShopCart(cart);

				if (cartId != 0) {
					this.$message({
						message: '添加成功，可前往购物车查看',
						type: 'success'
					});
					this.$eventBus.$emit("updateHeaderShopCartCount"); //修改头部购物车总数
				}
			},

			/**
			 * 销量展示文字处理
			 * @param {*} e 
			 */
			_getSellCountText(sellCountTotal, isPackageGoods) {
				// if (isPackageGoods) {
				// 	return ""
				// }
				if (sellCountTotal > 10000) {
					let sliceLength = sellCountTotal.toString().length - 4;
					return "已售 " + sellCountTotal.toString().slice(0, sliceLength) + "万+"
				}
				return "已售" + sellCountTotal
			},

			//添加到购物车
			addShopCart(goods) {
				let customerId = localStorage.getItem("pc_customerId");
				if (!customerId) {
					this.$eventBus.$emit("showLoginDialog", true, true); //显示登录弹窗,可关闭
					return;
				}
				// 抢购商品跳转抢购详情
				if (goods.activityType == "抢购") {
					this.$router.push({
						name: 'timedsalegoodsdetail',
						query: {
							saleId: goods.activityId,
							goodsId: goods.goodsId
						}
					})
					return
				}
				// 多规格或者多单位统一跳转到详情加购
				if (goods.isMulti == "1" || goods.isMultiUnit || !!goods.presaleId || goods.goodsType == "1") {

					//多规格跳转商品详情选择规格
					let encode = window.btoa(window.encodeURIComponent(goods.goodsId))
					this.$router.push({
						name: 'goodsdetail',
						query: {
							id: encode
						}
					});
					return;
				}

				//单规格商品直接加入购物车
				this.$message.closeAll();
				if (goods.priceMember <= 0 && goods.score <= 0) {
					return this.$message.error("商品价格不可为0");
				}
				if (goods.stock < goods.minCount && !this.isOpenZeroStockBuy) {
					return this.$message.error("库存不足");
				} else if (goods.stock < goods.minCount && this.isOpenZeroStockBuy && this.OpenZeroStock_isOpenRemind) {
					return this.$confirm("<span style='color:#999'>" + this.OpenZeroStock_tipText + "</span>", '提示', {
						confirmButtonText: '取消',
						cancelButtonText: '继续下单',
						cancelButtonClass: 'modal-button-class',
						confirmButtonClass: 'modal-button-class',
						dangerouslyUseHTMLString: true,
						center: true,
					}).then(() => {
						return;
					}).catch(() => {
						// 加入购物车
						this.addShopCartRequest(goods);
					});
				}
				
				// 加入购物车
				this.addShopCartRequest(goods);
			}
		},
		computed: {
			//加载中和加载完成不触发加载
			disabledLoad() {
				return this.loading || this.noMore;
			}
		}
	}
</script>

<style scoped="scoped">
	#goodsList {
		height: calc(100vh - 50px);
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}

	.goods-ads-notice {
		min-height: 50px;
		max-height: 100px;
		overflow: hidden;
		flex-shrink: 0;
	}

	.goods-ads-notice /deep/ img {
		display: block;
		max-width: 100%;
		max-height: 100%;
		margin: 0 auto;
	}

	.container {
		flex-grow: 0;
	}

	.goods-filter-wrap {
		height: 70px;
		margin-bottom: 20px;
		background-color: #fff;
		align-items: center;
		padding: 0 20px;
		position: relative;
	}

	.cate-filter {
		height: 100%;
		width: 200px;
		/* line-height: 70px; */
		margin-right: 30px;
		cursor: pointer;
		display: flex;
		align-items: center;
		position: relative;
	}

	.cate-filter .el-icon-menu {
		font-size: 22px;
	}

	.cate-filter .cate-name {
		padding-left: 10px;
	}

	.cate-filter .goods-cate {
		top: 70px;
		left: -20px;
	}

	.order-btn {
		display: flex;
		margin-right: 60px;
		height: 70px;
		line-height: 70px;
		cursor: pointer;
	}

	.order-btn.active {
		color: #F56C6C;
	}

	.search-wrap {
		right: 20px;
	}

	.goods-item {
		width: calc(20% - 20px);
		padding: 20px 0;
		margin: 0 10px 20px;
		position: relative;
		background-color: #fff;
		text-align: center;
		align-items: center;
		cursor: pointer;
	}

	.goods-img {
		max-width: 100%;
		max-height: 100%;
		margin: 0 auto;
	}

	.goods-img-wrap {
		max-width: 170px;
		height: 170px;
	}

	.goods-price {
		color: #ff2b2a;
		align-items: center;
		font-size: 18px;
		padding-top: 6px;
		display: flex;
		width: 100%;
		justify-content: space-between;
		padding: 0px 9px;
		box-sizing: border-box;
		margin-top: 5px;
		position: relative;
		overflow: hidden;
	}

	.goods-score {
		margin-left: 5px;
		/* font-size: 15px; */
	}

	.el-icon-shopping-cart-2 {
		color: #fff;
		background-color: #f56c6b;
		width: 25px;
		height: 25px;
		border-radius: 25px;
		line-height: 25px;
		font-size: 16px;
		margin-left: 20px;
	}

	.loading-txt {
		text-align: center;
		color: #bbb;
		margin: 0 0 15px;
	}


	.goods-list-wrap {
		overflow-y: auto;
		/* height: 80%; */
		-ms-overflow-style: none;
		/*隐藏滚动条 IE10+*/
		scrollbar-width: none;
		/*隐藏滚动条 firefox*/
	}

	.flex-center {
		padding: 6px 0 10px 0;
	}

	/*隐藏滚动条 chrome*/
	.goods-list-wrap::-webkit-scrollbar {
		display: none;
	}

	.goods-list {
		padding: 0;
		flex-wrap: wrap;
		max-width: 1200px;
		margin: 0 auto;
	}

	.button-border {
		border-radius: 3px;
		color: #fff;
		font-size: 14px;
		border: 1px solid #f63;
		height: 20px !important;
		margin-left: 10px;
		word-break: break-all;
		overflow: hidden;
		text-align: center;
		background: #f63;
		position: absolute;
		padding-left: 5px;
		padding-right: 5px;
		top: 10px;
		right: 20px;
	}

	@media screen and (max-width: 900px) {
		.goods-item {
			width: calc(33.33% - 20px);
			position: relative;
		}

		.cate-filter {
			width: 150px;
		}
	}

	@media screen and (max-width: 1200px) and (min-width:900px) {
		.goods-item {
			width: calc(20% - 20px);
		}
	}

	.goods-stock {
		color: #999;
		font-size: 14px;
		font-weight: normal;
		text-align: right;
		max-width: 80px;
		width: auto;
		line-height: 22px;
		height: 22px;
		overflow: hidden;
		word-break: break-all;
		/* 		padding-right: 10px; */
	}

	.sold-out-img {
		width: 60px;
		height: 60px;
		position: absolute;
		top: 190px;
		right: 26px;
		z-index: 9;
	}

	.goods-name {
		width: 90%
	}

	.goods-price-detail {
		color: #999;
		font-weight: normal;
		margin-left: 5px;
		font-size: 15px;
		text-decoration: line-through;
	}

	.width-220 {
		width: 220px;
	}

	.goods-sub-title-home {
		color: #999;
		font-size: 14px;
		/* width: 124px !important; */
		width: 90% !important;
		white-space: pre;
		text-overflow: ellipsis;
		overflow: hidden;
		line-height: 22px;
		height: 22px;
		padding: 0 5%;
		text-align: left;
	}

	.goods-no-text {
		color: #999;
		font-size: 14px;
		width: 90%;
		padding: 0 5%;
		word-break: break-all;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		text-align: left;
		margin-bottom: 3px;
	}

	.sort-asc {
		top: 22px;
		border-top: none;
		border-bottom-style: solid;
		border-bottom-color: #b2b2b2;
		border-top-color: transparent;
		border-left-color: transparent;
		border-right-color: transparent;
	}

	.sort-desc {
		bottom: 22px;
		border-bottom: none;
		border-top-style: solid;
		border-top-color: #b2b2b2;
		border-left-color: transparent;
		border-right-color: transparent;
		border-bottom-color: transparent;
	}

	.sort-edge {
		display: inline-block;
		width: 0;
		height: 0;
		border-style: dashed;
		overflow: hidden;
		position: absolute;
		left: 6px;
		border-width: 5px;
	}

	.sort-asc-choose {
		top: 26rpx;
		border-bottom-color: #F56C6C;
		;
	}

	.hide-div {
		display: none;
	}

	.sort-desc-choose {
		border-top-color: #F56C6C;
		bottom: 26rpx;
	}

	.goods-list-tip {
		text-align: center;
		height: 100%;
		transform: translateY(35%);
		font-size: 16px;
	}

	.no-result-text1 {
		font-weight: 400;
	}

	.no-result-text2 {
		margin-top: 4px;
		color: #AAAAAA;
		font-size: 14px;
	}

	.sell-count {
		color: #999;
		font-size: 14px;
		font-weight: normal;
		margin-left: 23px;
		width: 100%;
		margin-top: 4px;
		text-align: left;
		height: 20px;
	}

	.activity-label {
		background: linear-gradient(92deg, #ECD6AF 0%, #F6E5C6 100%);
	}

	.label-content {
		display: flex;
		flex-wrap: wrap;
		margin-top: 4px;
	}

	.button-border_bottom {
		border-radius: 3px;
		color: #f63;
		font-size: 14px;
		border: 1px solid #f63;
		height: 20px !important;
		margin-left: 10px;
		word-break: break-all;
		overflow: hidden;
		text-align: center;
		margin-top: 2px;
		padding-left: 5px;
		padding-right: 5px;
	}
</style>