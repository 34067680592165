<!-- 头部导航组件 -->
<template>
	<div class="header">
		<div class="content flex-row">
			<div class="mall-name" @click="toHome">{{mallTitle || "JMALL商城"}}</div>
			<div class="menu-group flex-row flex-center">

				<div class="menu" @click="toHome">商城首页</div>
				<div class="menu program-menu flex-row" v-if="programQrcode" @mouseenter="showQrcode=true;" @mouseleave="showQrcode=false;">
					<img class="img" src="./../../assets/images/icon-wx.png" />
					<div>小程序</div>
					<img class="qrcode-img" v-show="showQrcode" :src="programQrcode" />
				</div>
				<div class="menu" v-if="!isLogin" @click="showLoginDialog">登录/注册</div>
				<!-- <div class="menu" v-else>
					<el-dropdown placement="bottom" @command="handleCommand">
						<span class="el-dropdown-link personal-menu-txt" @click="toMine">
							个人中心
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="logout">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div> -->
				<div class="menu" @click="toShopCart">购物车（{{shopCartCount}}）</div>
				<div class="menu" v-if="!!customerName">
					<el-dropdown placement="bottom" @command="handleCommand">
						<div @click="toMine" class="el-dropdown-link personal-menu-txt">
							{{customerName || "客户名称"}}
						</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-if="isShowCustomerBalance">余额 {{$currency.symbol}}{{customerBalance}}</el-dropdown-item>
							<el-dropdown-item v-if="debtAmountMax>0 && pcIsOpenDebtPay"> 可用欠款额度{{$currency.symbol}}{{debtAmountMax}}</el-dropdown-item>
							<el-dropdown-item v-if="isLogin" command="logout">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</div>

		<!-- 登录弹窗 -->
		<login-dialog :isShow="isShowLoginDialog" :showClose="isLoginShowClose" @close="closeLoginDialog">
		</login-dialog>
	</div>
</template>

<script>
	import LoginDialog from './LoginDialog.vue';
	import {
		getCustomerInfo
	} from '../../utils/api-utils/api-customer.js';
	import {
		getParam
	} from '../../utils/api-utils/api-params.js';
	import commonUtils from '../../utils/common-utlis.js'
	import {
		getShoppingCartCount
	} from '../../utils/api-utils/api-goods-shoppingcart.js';
	export default {
		name: 'v-header',
		components: {
			'login-dialog': LoginDialog,
		},
		inject: ['reload'], // 引用父组件传过
		data() {
			return {
				mallTitle: "", //商城名
				programQrcode: "", //小程序二维码
				showQrcode: false, // 是否展示二维码
				shopCartCount: 0, // 购物车数量
				isShowLoginDialog: false, // 是否显示登录弹窗
				isLoginShowClose: true, //登录弹窗是否显示关闭按钮
				isLogin: false, // 是否登录的标记
				customerId: "", // 当前登录会员ID
				customerName: "", // 当前用户名
				customerBalance: "0.00", //用户余额
				debtAmountMax: "0.00",
				isOpenMultiShopCart: false, // 多购物车
				pcIsOpenDebtPay: false,
				isShowCustomerBalance: false, // 是否展示用户预存款
			}
		},
		created: async function() {
			// 获取用户信息
			let csutomerInfo = await getCustomerInfo();
			if (!commonUtils.isNullOrEmpty(csutomerInfo)) {
				this.isLogin = true;
				this.customerId = csutomerInfo.customerId;
				this.customerName = csutomerInfo.nickname;

				// 获取账户余额
				this.getBalance();
			}

			//获取商城名称及小程序图片
			this.getMallInfo();
			//统计购物车数量
			this.countShopCart();
		},
		mounted() {
			//监听购物车数量变化
			this.$eventBus.$on("updateHeaderShopCartCount", () => {
				this.countShopCart();
			})

			//监听余额变化
			this.$eventBus.$on("updateHeaderBalance", () => {
				this.getBalance();
			})

			//监听登录弹窗是否显示,是否可关闭
			this.$eventBus.$on("showLoginDialog", (showLogin, showClose) => {
				this.isShowLoginDialog = showLogin;
				this.isLoginShowClose = showClose;
			})
		},
		methods: {
			//商城首页
			toHome() {
				this.$router.push({
					name: 'home'
				});
			},
			//个人中心
			toMine() {
				this.$router.push({
					name: 'tradelist'
				});
			},
			//购物车
			toShopCart() {
				this.$router.push({
					name: 'shopcart'
				});
			},
			// 获取商城名称
			async getMallInfo() {
				// 判断是否开启了多购物车
				let paramlist = await getParam('isOpenMultiShopCart,pcmall_title,program_qrcode_url_formal,pc_isOpenDebtPay,isShowCustomerBalance');
				this.isOpenMultiShopCart = paramlist.isOpenMultiShopCart == "1";
				this.mallTitle = paramlist.pcmall_title || "JMALL商城";
				this.programQrcode = paramlist.program_qrcode_url_formal || "";
				this.pcIsOpenDebtPay = paramlist.pc_isOpenDebtPay == "1";
				this.isShowCustomerBalance = paramlist.isShowCustomerBalance == "1";
			},
			// 获取购物车数量
			async countShopCart() {
				let shopCartCount = await getShoppingCartCount();
				//获取到最新的购物车数量，修改头部购物车数量
				this.shopCartCount = shopCartCount || 0;
				//修改浮动按钮购物车数量
				this.$eventBus.$emit("updateFloatShopCartCount", this.shopCartCount);
			},

			/**
			 * 显示登录弹窗
			 */
			showLoginDialog() {
				this.isShowLoginDialog = true;
				this.isLoginShowClose = false;
			},

			/**
			 * 关闭登录弹窗（下级调用）
			 */
			async closeLoginDialog() {
				this.isShowLoginDialog = false;
				// 获取用户信息
				let csutomerInfo = await getCustomerInfo();
				if (!commonUtils.isNullOrEmpty(csutomerInfo)) {
					this.isLogin = true;
					this.customerId = csutomerInfo.customerId;
					this.customerName = csutomerInfo.nickname;
				}

				//获取商城名称及小程序图片
				this.getMallInfo();
				//统计购物车数量
				this.countShopCart();
				// 获取账户余额
				this.getBalance();

				this.reload();

				// 设置cuustomerId
				this.$eventBus.$emit("setCustomerId", localStorage.getItem("pc_customerId"));

			},

			/**
			 * 处理下拉菜单命令
			 */
			handleCommand(command) {
				//退出登录
				if (command == "logout") {
					this.$confirm('确定退出登录?', '提示').then(() => {
						this.logout();
					});
				}
			},

			/**
			 * 获得账户余额
			 */
			async getBalance() {
				let that = this;
				// 获取余额数据
				let balance = await that.$public.getCustomerBalance();
				that.customerBalance = balance;
				let customerInfo = await getCustomerInfo();
				balance = parseFloat(that.customerBalance);
				let debtAmountMax = customerInfo && customerInfo.debtAmountMax ? parseFloat(customerInfo.debtAmountMax || 0) : 0;
				if (debtAmountMax == 0) {
					that.debtAmountMax = '0.00';
					return;
				}
				if (balance > 0) {
					that.debtAmountMax = debtAmountMax.toFixed(2);
				} else {
					that.debtAmountMax = (balance + debtAmountMax).toFixed(2);
				}
			},

			logout() {
				//清除缓存用户信息，回到首页
				localStorage.clear();
				this.getSysParams();
				this.isLogin = false;
				this.customerId = "";
				this.customerName = "";
				this.customerBalance = "0.00";

				//统计购物车数量
				this.countShopCart();

				if (this.$route.name == "home") {
					location.reload();
				} else {
					this.$router.push({
						name: 'home'
					})
				}
			},

			/**
			 * 获取系统配置参数，渠道仓库等
			 */
			async getSysParams() {
				let _this = this;
				let paramlist = await getParam('bindSaleChannelId,bindSaleChannelName,bindWarehouseId,defaultMemberGradeId,defaultMemberGradeName,commission_mode,pc_goodsDetailStyle,currencySymbol');
				// 绑定的吉客云渠道ID
				localStorage.setItem("pc_channelId", paramlist.bindSaleChannelId);
				// 获取到店铺Id后，获取购物车总数
				this.$eventBus.$emit("updateHeaderShopCartCount");
				// 绑定的吉客云渠道名称
				localStorage.setItem("pc_channelName", paramlist.bindSaleChannelName);
				// 默认仓库ID
				localStorage.setItem("pc_warehouseId", paramlist.bindWarehouseId);
				// 默认会员等级ID
				localStorage.setItem("pc_defaultMemberGradeId", paramlist.defaultMemberGradeId);
				// 默认会员等级名称
				localStorage.setItem("pc_defaultMemberGradeName", paramlist.defaultMemberGradeName);
				// 分销模式
				localStorage.setItem('pc_commissionMode', paramlist.commission_mode ? paramlist
					.commission_mode : '');
				// 商品详情样式
				localStorage.setItem('pc_goodsDetailStyle', paramlist.pc_goodsDetailStyle ? paramlist
					.pc_goodsDetailStyle : 0);
				// 币种符号
				// this.$currency.setSymbol(paramlist.currencySymbol || "￥");
				setTimeout(function() {
					let symbol = paramlist.currencySymbol || "￥";
					_this.$currency.setSymbol(symbol);
				}, 5000);
			},

		},
		computed: {

		}
	}
</script>

<style scoped="scoped">
	.header {
		height: 50px !important;
	}

	.content {
		width: 100%;
		height: 100%;
		background-color: #000000;
		color: #fff;
		font-size: 15px;
		justify-content: space-between;
		align-items: center;
	}

	.mall-name {
		margin-left: 20px;
		cursor: pointer;
	}

	.img {
		height: 18px;
		width: 18px;
		margin: 1px 10px;
	}

	.menu-group {
		height: 100%;
	}

	.menu {
		margin: 0 20px;
		cursor: pointer;
		position: relative;
	}

	.program-menu {
		height: 100%;
		align-items: center;
	}

	.qrcode-img {
		position: absolute;
		width: 300px;
		height: 300px;
		top: 50px;
		left: -100px;
		border: solid 1px #ccc;
	}

	.btn-logout {
		position: absolute;
	}

	.personal-menu-txt {
		color: #fff !important;
		/* width: 200px; */
		height: 23px;
		line-height: 23px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		margin-right: 30px;
		margin-top: 4px;
	}
</style>