<!-- 再来一单订单商品弹窗 -->
<template>
	<el-dialog width="600px" :modal-append-to-body='false' :close-on-click-modal="false" :visible.sync="isShow" :show-close="showClose">
		<el-form class="trade-again-box">
			<div class="trade-again-title">提示说明</div>
			<el-form-item>
				<div class="question-tips">以下商品库存不足或已下架，是否继续？</div>
			</el-form-item>
			<el-form-item>
				<el-row class="cgoods-item" :gutter="30" v-for="(item, index) in goodsList" :key="index">
					<el-col :span="6">
						<el-image class="cgoods-img" fit='scale-down' :src="$utils.set400Img(item.pic_url)"></el-image>
					</el-col>
					<el-col :span="12">
						<div class="cgoods-name text-ellipsis">{{item.goods_name}}</div>
						<div class="cspec-name">{{item.goods_spec}}</div>
					</el-col>
					<el-col :span="6">
						<div class="cgoods-price">
							<span v-show="item.price_detail > 0 || item.score == 0">{{$currency.symbol}}{{$utils.filterTrailingZeros(item.price_detail)}}</span>
							<span v-show="item.price_detail != 0 && item.score > 0"> + </span>
							<span v-show="item.score > 0">{{item.score}}</span>
							<span v-show="item.score > 0" class="sscore-text">积分</span>
						</div>
					</el-col>
				</el-row>
			</el-form-item>
			<el-form-item class="trade-again-btns" style="margin-top: 50px auto 0;">
				<el-button class="btn-cancel" @click="closeDialog">取 消</el-button>
				<el-button class="btn-confirm" type="danger" @click="confirmTradeAgain">继 续</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'trade-again-dialog',
		props: {
			isShow: {
				type: Boolean,
				default: false
			},
			showClose: {
				type: Boolean,
				default: true
			}, //是否显示关闭按钮
			goodsList: {
				type: Array,
				default: []
			}
		},
		data() {
			return {
			}
		},
		mounted() {
	
		},
		methods: {
			// 关闭弹窗
			closeDialog(){
				this.$emit('close');
			},
			
			// 继续，跳转到购物车页面
			confirmTradeAgain(){
				this.$emit('close');
				this.$router.push({
					name: 'shopcart',
					params: {
						cartFrom: '再来一单'
					}
				});
			}
		},
		computed: {

		}
	}
</script>

<style scoped="scoped">
	.trade-again-box {
		padding: 0 50px 30px 50px;
	}
	
	.trade-again-title {
		text-align: center;
		font-size: 22px;
		margin-bottom: 20px;
	}
	
	.question-tips{
		text-align: center;
		color: #f56c6c;
		font-size: 15px;
	}
	
	.cgoods-item {
		display: flex;
		align-items: center;
		padding: 15px;
		border-bottom: 1px solid #dedede;
		line-height: 35px;
	}
/* 	.cgoods-item:last-child{
		border-bottom: none;
	} */
	
	.cgoods-img{
		width: 70px;
		height: 70px;
		display: block;
	}
	.cgoods-name{
		font-size: 15px;
		width: 205px;
	}
	.cspec-name {
		font-size: 13px;
		color: #666;
	}
	.sscore-text{
		font-size: 15px;
		margin-left: 3px;
	}
	
	.trade-again-btns{
		text-align: center;
		margin: 50px auto 0;
	}
	.btn-cancel{
		margin-right: 80px;
	}
</style>
