// 基础信息配置
const {
	config
} = require('../config.js');
import { getParam } from './api-utils/api-params.js';
import requestUtil from './request-utils.js'

/**
 * 设置图片url
 * @param {String} imgurl
 */
function setImg(imgurl) {
	imgurl = imgurl && imgurl.indexOf("&amp;") >= 0 ? imgurl.replaceAll("&amp;", "&") : imgurl
	if (imgurl == "" || imgurl == null) {
		return config.headUrl + "/Images/MobileUse/default.png";
	} else if (imgurl.indexOf('http') >= 0) {
		return imgurl;
	} else {
		return config.headUrl + imgurl;
	}
}

/**
 * 设置图片url(400*400)
 * @param {String} imgurl
 */
function set400Img(imgurl) {
	imgurl = imgurl && imgurl.indexOf("&amp;") >= 0 ? imgurl.replaceAll("&amp;", "&") : imgurl
	if (imgurl == "" || imgurl == null) {
		return config.headUrl + "/Images/MobileUse/default.png";
	} else if (imgurl.indexOf('http') >= 0) {
		return imgurl;
	} else if (imgurl.indexOf("_th.") >= 0 || imgurl.indexOf("MobileUse/default.png") >= 0) {
		return config.headUrl + imgurl;
	} else if (imgurl.indexOf("_th800.") >= 0) {
		return config.headUrl + imgurl.replace('_th800', '_th.');
	} else {
		return config.headUrl + imgurl.replace('.', '_th.');
	}
}

/**
 * 设置图片url(800*800)
 * @param {String} imgurl
 */
function set800Img(imgurl) {
	if (imgurl == "" || imgurl == null) {
		return config.headUrl + "/Images/MobileUse/default.png";
	} else if (imgurl.indexOf('http') >= 0) {
		return imgurl;
	} else if (imgurl.indexOf("_th.") >= 0) {
		return config.headUrl + imgurl.replace('_th.', '_th800.');
	} else if (imgurl.indexOf("_th800.") >= 0 || imgurl.indexOf("MobileUse/default.png") >= 0) {
		return config.headUrl + imgurl;
	} else {
		return config.headUrl + imgurl.replace('.', '_th800.');
	}
}

/**
 * 固定金额显示位数
 * @param {Object} amount
 *  @param {Object} count
 */
function amountFixed(amount, count) {
	try {
		let type = typeof(amount);
		if (type == "number") {
			return amount.toFixed(count);
		} else if (type == "string") {
			return parseFloat(amount).toFixed(count);
		}
	} catch (e) {}
	return '0.00';
}

/**
 * 小数格式处理
 */
function filterTrailingZeros(number = 0) {
	let decimalKeepPlaces = localStorage.getItem('decimalKeepPlaces') || 2

	return parseFloat(number || 0).toFixed(decimalKeepPlaces).replace(/\.?0+$/, '');
}

/** 
 * 时间数字转化 02
 * number: 数字
 */
function formatNumber(n) {
	n = n.toString();
	return n[1] ? n : '0' + n;
}

/** 
 * 时间戳转化为年 月 日 时 分 秒 
 * number: 传入时间戳 
 * format：返回格式，支持自定义，但参数必须与formateArr里保持一致 
 */
function formatTime(timestamp, format) {
	var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
	var returnArr = [];
	if (timestamp.length == 10) {
		timestamp = timestamp * 1000;
	}
	var date = new Date(timestamp);
	returnArr.push(date.getFullYear());
	returnArr.push(formatNumber(date.getMonth() + 1));
	returnArr.push(formatNumber(date.getDate()));
	returnArr.push(formatNumber(date.getHours()));
	returnArr.push(formatNumber(date.getMinutes()));
	returnArr.push(formatNumber(date.getSeconds()));
	for (var i in returnArr) {
		format = format.replace(formateArr[i], returnArr[i]);
	}
	return format;
}

/**数组根据数组对象中的某个属性值进行排序的方法 
 * 使用例子：newArray.sort(sortBy('number',false)) //表示根据number属性降序排列;若第二个参数不传递，默认表示升序排序
 * @param attr 排序的属性 如number属性
 * @param rev true表示升序排列，false降序排序
 * */
function sortBy(attr, rev) {

	//第二个参数没有传递 默认升序排列
	if (rev == undefined) {
		rev = 1;
	} else {
		rev = (rev) ? 1 : -1;
	}

	return function(a, b) {
		a = a[attr];
		b = b[attr];
		if (a < b) {
			return rev * -1;
		}
		if (a > b) {
			return rev * 1;
		}
		return 0;
	}
}

/**
 * 加密手机号
 * @param {String} tel
 */
function regTel(tel) {
	if (tel == "" || tel == null) {
		return "";
	} else {
		return tel.replace(tel.substring(tel.length - 8, tel.length - 4), '****');
	}
}

/**
 * 检测手机号格式
 * @param {String} tel
 */
function checkTel(tel) {
	if (tel && /^1\d{10}$/.test(tel)) {
		return true;
	}
	return false;
}

/**
 * 分组
 * @param {Object} array
 * @param {Object} name
 */
function groupBy(array, name) {
	let groups = {};
	array.forEach(function(o) {
		let group = JSON.stringify(o[name]);
		groups[group] = groups[group] || [];
		groups[group].push(o);

	});
	return groups;
}

/**
 * 从缓存获取会员ID
 */
function getCustomerId() {
	return localStorage.getItem("pc_customerId") || "0";
}

/**
 * html编码
 * @param {any} str
 */
function htmlEncode(str) {
	if (typeof str != "string") return str;
	if (str.length == 0) return "";
	str = str.replace(/&/g, "&amp;");
	str = str.replace(/</g, "&lt;");
	str = str.replace(/>/g, "&gt;");
	//str = str.replace(/ /g, "&nbsp;");
	// str = str.replace(/\'/g, "&#39;");
	// str = str.replace(/\"/g, "&quot;");
	//str = str.replace(/\n/g, "<br/>");
	return str;
}

/**
 * html解码
 * @param {any} str
 */
function htmlDecode(str) {
	if (typeof str != "string") return str;
	if (str.length == 0) return "";
	str = str.replace(/&amp;/g, "&");
	str = str.replace(/&lt;/g, "<");
	str = str.replace(/&gt;/g, ">");
	str = str.replace(/img/g, "img style='width: 1200px !important;height: auto !important'")
	//str = str.replace(/&nbsp;/g, " ");
	// str = str.replace(/&#39;/g, "\'");
	// str = str.replace(/&quot;/g, "\"");
	//str = str.replace(/<br\/>/g, "\n");
	return str;
}


/**
 * html解码
 * @param {any} str
 */
function ueStrDecode(str) {
	let reg = RegExp(/&(amp;)+/g);
	return str ? htmlDecode(str.replaceAll("☚☚☚", "<").replaceAll("☛☛☛", ">").replaceAll("✪", "&amp;quot;").replaceAll("☮", "+").replaceAll("☸☸☸", "javascript:alert").replaceAll(reg, '&amp;')) : str;
}


/**
 * html解码
 * @param {any} str
 */
function htmlDecodeForMiddleRichText(str) {
	if (typeof str != "string") return str;
	if (str.length == 0) return "";
	str = str.replace(/&amp;/g, "&");
	str = str.replace(/&lt;/g, "<");
	str = str.replace(/&gt;/g, ">");
	str = str.replace(/img/g, "img style='width: 1200px !important'")
	//str = str.replace(/&nbsp;/g, " ");
	// str = str.replace(/&#39;/g, "\'");
	// str = str.replace(/&quot;/g, "\"");
	//str = str.replace(/<br\/>/g, "\n");
	return str;
}

/**
 * 获取时间戳
 * @param {Object} str
 */
function convertTime(str) {
	return new Date(str).getTime();
}
/**
 * 
 * @param {*} str 
 * @param {*} fullDate 
 */
function stringToDate(str, fullDate = true) {
	if (!str) {
		return "";
	}
	if (!isNaN(str)) {
		return str;
	}
	try {
		let date = new Date(str);
		if (!date.getTime()) {
			return str;
		}

		let year = date.getFullYear();
		let month = formatNumber(date.getMonth() + 1);
		let day = formatNumber(date.getDate());
		let hours = formatNumber(date.getHours());
		let minutes = formatNumber(date.getMinutes());
		let seconds = formatNumber(date.getSeconds());
		return fullDate ? `${year}-${month}-${day} ${hours}:${minutes}:${seconds}` : `${year}-${month}-${day}`;
	} catch (e) {
		return str;
	}
}

/**
 * 防抖
 */
function debounce(func, wait = 800, immediate = true) {
	let timer;
	return function() {
		let context = this;
		let args = arguments;
		if (timer) clearTimeout(timer);
		if (immediate) {
			var callNow = !timer;
			timer = setTimeout(() => {
				timer = null;
			}, wait);
			if (callNow) func.apply(context, args);
		} else {
			timer = setTimeout(function() {
				func.apply(context, args);
			}, wait);
		}
	}
}

/**
 * 数组求和
 * @param {*} arr 
 * @param {*} key 
 */
function sumArr(arr, key) {
	if (!(arr && arr.length > 0)) {
		return 0;
	}

	let total = 0;
	arr.forEach(t => {
		try {
			if (key) {
				total += parseFloat(t[key]);
			} else {
				total += parseFloat(t);
			}
		} catch (e) {}
	});

	return total;
}

/*
 * 下划线转换驼峰
 */
function underlineToHump(str) {
	// 如果首字母是_，执行 replace 时会多一个_，这里需要去掉
	if (str.slice(0, 1) === '_') {
		str = str.slice(1);
	}
	return str.replace(/([^_])(?:_+([^_]))/g, function($0, $1, $2) {
		return $1 + $2.toUpperCase();
	});
}

/**
 * 检测数据是否存在 
 */
function isNullOrEmpty(val) {
	return !val || ['{}', 'null', '""', '[]'].includes(JSON.stringify(val));
}

/**
 * 保留小数位数
 */
function floatKeepDigit(num, digit = 2) {
	try {
		return parseFloat(parseFloat(num).toFixed(digit));
	} catch (e) {
		return num;
	}
}

/**
 * 数组去重
 * @param {*} arr 
 * @param {*} key 
 */
function distinctArr(arr, key) {
	if (!arr || arr.length <= 0) {
		return [];
	}
	if (key) {
		return Array.from(new Map(arr.map(item => [item[key], item])).values());;
	} else {
		return [...new Set(arr)];
	}
}

/**
 * 分批调用
 * @param {*} configList 
 */
const getAllNeedInfoByConfigList = (configList, fun) => {
	// 请求集合
	return new Promise(async (resolve, reject) => {
		let promiseList = _getPromiseListBySliceConfigList(configList, fun);

		// 等待所有接口执行完成
		Promise.all(promiseList).then((values) => {
			// values:[[promise1接口返回数据]，[promise2接口返回数据]...] 需要拍平返回
			resolve(values.flat(1));
		});
	});
}

/**
 * 生成分批调用需要的PromiseList
 * @param {*} configList 
 */
const _getPromiseListBySliceConfigList = (configList, fun, sliceCount = 30) => {
	let promiseList = []
	for (let i = 0; i < Math.ceil(configList.length / sliceCount); i++) {
		promiseList.push(fun(configList.slice(i * sliceCount, (i + 1) * sliceCount)))
	}
	return promiseList
}

const getIsShowPrice = async () => {
	let patmaList = await getParam("isOpenShowVisitorPrice");
	let isOpenShowVisitorPrice = patmaList.isOpenShowVisitorPrice != "0";
	return isOpenShowVisitorPrice || !!localStorage.getItem("pc_customerId")
}

/**
 * 获取吉商城接口当前版本（用作灰度切换）
 * @param {string}  key 单个配置
 */
const getSysSetting = (key) => {
	return new Promise((resolve, reject) => {
		requestUtil.jackyunPost({
			method: "sys.getsetting",
			content: key
		}, (res) => {
			let data = ""
			if (res.code == 200) {
				data = res.data[key];
			}

			resolve(data);
		})
	});
}

/**
 * 获取统一格式保质期
 */
const getFormatValidate = async (validateObj, isShowShelfLift = true) => {
	// 获取保质期配置
	let paramList = await getParam("goodsValidityDateFrom,isShowValidateBySplitType");
	return validateObj.validityDate && (paramList.goodsValidityDateFrom == "bathInfo" && paramList.isShowValidateBySplitType == "1" ? `${validateObj.productionDate ? '生产日期：' + validateObj.productionDate + "  ": ""}${validateObj.shelfLife && validateObj.shelfLiftUnitName ? (isShowShelfLift ? '保质期：' : '') + validateObj.shelfLife + validateObj.shelfLiftUnitName : ''}` : validateObj.validityDate) || "";
}

export default {
	setImg,
	set400Img,
	set800Img,
	amountFixed,
	formatTime,
	sortBy,
	regTel,
	checkTel,
	getCustomerId,
	formatNumber,
	htmlEncode,
	convertTime,
	htmlDecode,
	htmlDecodeForMiddleRichText,
	stringToDate,
	debounce,
	groupBy,
	sumArr,
	underlineToHump,
	isNullOrEmpty,
	floatKeepDigit,
	distinctArr,
	getAllNeedInfoByConfigList,
	getIsShowPrice,
	getSysSetting,
	getFormatValidate,
	filterTrailingZeros,
	ueStrDecode
}