<template>
	<div>
		<!-- 顶部广告栏 -->
		<div :hidden="!goodsAdsNotice" class="goods-ads-notice">
			<img :src="$utils.setImg(goodsAdsNotice)" @error="$utils.setImg('')" />
		</div>
		<div class="container" v-show="isShowPage">
			<div class="base-info">
				<i class="el-icon-arrow-left back-btn" @click="goBack"></i>
				<el-row :gutter="24">
					<el-col :span="7">
						<el-image class="goods-img" fit='scale-down' :src="$utils.setImg(currentImg_zindex99||currentImg)"></el-image>
						<div class="img-list flex-row" style="position: relative;" v-show="!!goodsDetail.goodsPicList  && goodsDetail.goodsPicList.length > 0 ">
							<el-image v-for="(item, index) in goodsDetail.goodsPicList" :key="index" class="thum-img" fit='contain' :src="$utils.setImg(item.picUrl)" :class="{ active: index == currentImgIndex }" @mouseenter="mouseEnter(index)">
							</el-image>
							<!-- <el-image v-for="(item, index) in goodsDetail.goodsPicList" :key="index" class="thum-img" fit='contain' :src="$utils.setImg(item)" :class="{ active: index == currentImgIndex }" @mouseenter="mouseEnter(index)">
							</el-image> -->
						</div>
						<div v-if="!!erpGoodsCustomFieldInfoList.length>0" style="margin-top: 15px;">
							<div style="font-weight: 600;font-size: 15px;margin-bottom: 5px;display: flex;">
								<div style="background-color: #FF490B;margin-right: 5px;height:13px; width: 7px;transform: translateY(4px);"></div>
								商品属性
							</div>
							<div v-for="item in erpGoodsCustomFieldInfoList" class="customfield-content">
								<div class="customfield-name"> {{item.customFieldName}}:</div>
								<div class="customfield-name" style="color: #333;margin-left: 10px;white-space: normal;word-break: break-all;">{{item.customFieldVal}} </div>
							</div>
						</div>
					</el-col>
					<el-col :span="17">
						<div class="goods1-content" v-if="goodsDetail.goodsId">
							<div class="goods1-name">{{goodsDetail.goodsName}}</div>
							<div class="flex-row">
								<div class="flex-col" style="width: 100%;">
									<div class="goods_no_text">{{goodsDetail.goodsNo}}</div>
									<div class="goods_no_text" v-if="!!goodsValidityDate">{{goodsValidityDate}}</div>
									<div class="goods_no_text" v-if="!!stockDesc">{{stockDesc}}</div>
									<!-- 规格自定义属性 -->
									<div class="goods_skucustomfield" v-if="!!skuCustomFieldForShowList.length>0">
										<div class="skucustomfield_item" v-for="item in skuCustomFieldForShowList">
											<div class="customfield-name"> {{item.customFieldName}}:</div>
											<div class="customfield-name" style="color: #333;margin-left: 10px;white-space: normal;word-break: break-all;">{{item.customFieldVal}} </div>
										</div>
									</div>
								</div>
							</div>

							<div class="img-timed" style="position: relative;" v-if="isTimedSpecAndUnit">
								<img class="img-timed" src="../../assets/images/timed-salegoods.svg">
								<div class='TimedLeftTime'>
									<div class="TimedLeftTime-lefttext">{{leftText}}</div>
									<div class="TimedLeftTime-lefttext" v-show="days!=''">{{days}}</div>
									<div class="TimedLeftTime-text">{{hours}}</div>
									<div class="colon-color">:</div>
									<div class="TimedLeftTime-text">{{minutes}}</div>
									<div class="colon-color">:</div>
									<div class="TimedLeftTime-text">{{seconds}}</div>
								</div>
							</div>

							<div style="background-color: #f3f3f3;" v-if="isTimedSpecAndUnit && isShowPrice">
								<div class="flex-row padding-price">
									<span class="goods_no_text margin-price">原价</span>
									<div class="goods_no_text line-through">
										<span class="currency1 size18" style="bottom: 0px;">{{$currency.symbol}}</span>
										<span class="size18">{{$utils.filterTrailingZeros(orginPrice)}}</span>
									</div>
								</div>
								<div class="flex-row">
									<span class="goods_no_text margin-price">抢购价</span>
									<div class="goods1-price">
										<span class="currency1" v-show="executionPrice > 0|| score == 0">{{$currency.symbol}}</span>
										<span class="size26" v-show="executionPrice > 0 || score == 0">{{$utils.filterTrailingZeros(executionPrice)}}</span>
										<span v-show="score > 0" class="size26"> + </span>
										<span v-show="score > 0" class="size26">{{score}}</span>
										<span v-show="score > 0" class="price-tip1">积分</span>
										<span class="price-tip1" v-if="goodsDetail.isMulti == '1'" v-show="specIndex == -1 || selectedUnitIndex == -1">起</span>
									</div>
								</div>
							</div>
							<div v-else>
								<div class="flex-row padding-price" v-show="isShowPrice">
									<div class="goods_no_text">
										<span class="currency1 size26" style="bottom: 0px;color: #FF5757">{{$currency.symbol}}</span>
										<span class="size26" style="color: #FF5757">{{$utils.filterTrailingZeros(executionPrice)}}</span>
										<span v-show="score > 0" class="size26" style="color: #FF5757"> + </span>
										<span v-show="score > 0" class="size26" style="color: #FF5757">{{score}}</span>
										<span v-show="score > 0" class="price-tip1" style="color: #FF5757">积分</span>
										<span class="button-border" v-show="marketingLabel">
											{{marketingLabel}}
										</span>
									</div>
								</div>

							</div>

							<!-- 普通商品选择模式 -->
							<div>
								<spec-choose classType="new" :isMulti="isMulti" :isShowSingleSpec="isShowSingleSpec" :goodsSpec="goodsSpecList" @selectSpec="specChange"></spec-choose>
								<div class="flex-row">
									<div class='attr-name title'>单位</div>
									<div class='attr-flex '>
										<div v-for="(item, unit_index) in unitList" :key="unit_index" class='attr-box' :class='{attrselected: selectedUnitIndex == unit_index}' :data-index='unit_index' @click='unitChange'>
											<span>{{item.unitName}}</span>
											<span style="margin: 0 15px 0 0;" v-if="goodsDetail.baseUnit != item.unitName">({{item.countRate}}{{goodsDetail.baseUnit}})</span>
										</div>
									</div>
								</div>
								<hr align="center" width="100%" size="1" color="#D7D7D7">
								<!-- <div class="flex-row" v-if="!!stockDesc">
									<div class="title">{{stockDesc}}</div>
								</div> -->

								<div class="flex-row" style="justify-content: space-between;">
									<div class="flex-row">
										<span class="title">数量</span>
										<input-number :curval="goodsCount" :min="stock <= 0||minCount==0? 0 :minCount" :max="isOpenZeroStockBuy ? maxCount:(stock<maxCount?stock:maxCount)" :step="1" @change="countChange"></input-number>
									</div>
									<span class="goods_limit_text">
										<span v-if='timedMinCount > 1'>每人起购数量{{timedMinCount}}</span>
										<span v-if='(timedMinCount > 1) && (timedLimitCount != 999999)'>，</span>
										<span v-if='timedLimitCount != 999999'>限购数量{{timedLimitCount}}</span>
									</span>
								</div>
								<hr align="center" width="100%" size="1" color="#D7D7D7">
								<div class="flex-between" v-show="!!leftText">
									<span>
									</span>

									<div class="handle-box flex-row">
										<!-- 数量选择 -->
										<el-button class="handle-btn info-button" :class="leftText =='距离开始' ? 'disabled-button':''" :disabled="leftText =='距离开始'" @click="addShopCartTap">加入购物车
										</el-button>
										<el-button v-if="isTimedSpecAndUnit && curSaleInfo.originalPriceSale != '0'" class="handle-btn originalbuy-button colon-color" @click="gotoGoodsDetail">原价购买
										</el-button>
										<el-button class="handle-btn my-danger-button colon-color" v-show="leftText !='距离开始'" @click="goPayTap">立即购买</el-button>
										<el-button class="handle-btn my-danger-button colon-color disabled-button" v-show="leftText =='距离开始'" disabled="">抢购未开始</el-button>
									</div>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>


			</div>
			<div :hidden="!goodsDetail.goodsDetail" class="detail-box">
				<div class="detail-info" v-html="goodsDetail.goodsDetail">
				</div>
			</div>

		</div>
		<!-- 右下角浮动按钮 -->
		<float-btns scroll-target=""></float-btns>
	</div>
</template>

<script>
	import specChoose from '../../components/goods/SpecChoose.vue'
	import inputNumber from '../../components/goods/InputNumber.vue'
	import FloatBtns from '../../components/common/FloatBtns.vue';
	import validitydateUtils from '../../utils/api-utils/api-goods-validitydate.js';
	import commonUtil from '../../utils/common-utlis.js'
	import publicUtil from '../../utils/public-methods.js'
	import goodsUtils from '../../utils/api-utils/api-goods.js';
	import {
		getParam
	} from '../../utils/api-utils/api-params.js'
	import {
		getCustomerInfo
	} from '../../utils/api-utils/api-customer.js'
	import {
		getTimedSaleGoodsInfo,
		getTimedSaleListBySaleId,
		getMarketingInfoListBySpec
	} from '../../utils/api-utils/api-activity.js'
	import {
		system
	} from '../../utils/api-utils/api-sys.js'
	export default {
		name: 'timedsalegoodsdetail',
		components: {
			FloatBtns,
			specChoose,
			inputNumber
		},
		data() {
			return {
				currentImg: '',
				minCount: 1,
				limitCount: 0,
				goodsSpecList: [],
				goodsDetail: {},
				executionPrice: 0.00,
				orginPrice: 0.00,
				currentImgIndex: 0,
				selectedUnitIndex: -1,
				unitList: [],
				stockList: [], // 库存列表
				bindWarehouseId: '',
				goodsCount: 0, //购买量
				currentImg_zindex99: '', //优先级较高的当前图片
				saleId: 0,
				goodsId: '',
				goodsValidityDate: "", // 商品有效期
				customerId: 0, //初始客户Id
				selectedID: "",
				goodsAdsNotice: "", //广告通知
				skuPriceList: [],
				tmpGoodsSpecList: [],
				isShowPage: false,
				isOpenIndistinctStock: '0', // 是否开启模糊库存
				stock: 0,
				leftText: '',
				// 距离结束时间
				days: '',
				hours: '',
				minutes: '',
				seconds: '',
				countdownInterval: '', //倒计时控件
				currentTime: '',
				channelId: '',
				beginTime: '',
				endTime: '',
				specSelfbuyCount: 0,
				specBoughtCount: 0,
				specIndex: '-1', // 规格选中下标
				isOpenZeroStockBuy: false,
				score: 0,
				selectedUnit: "",
				isShowSingleSpec: false,
				isTimedSpecAndUnit: false,
				isShowPrice: false,
				marketingLabel: "",
				erpGoodsCustomFieldInfoList: [], // 货品级自定义字段展示数组
				skuCustomFieldForShowList: [], // 规格级自定义字段展示数组
				curSaleInfo: [],
				timedMinCount: 1,
				timedLimitCount: 999999
				// copyUrl:""
			}
		},
		mounted() {
			this.$eventBus.$on('setCustomerId', msg => {
				this.customerId = msg;
			});
		},
		watch: {
			async customerId(newName, oldName) {
				let that = this;
				if (!!newName && newName != 0) {
					that._getTimedSaleGoodsDetail();
				}
				// 判断是否展示价格
				this.isShowPrice = await this.$utils.getIsShowPrice();
			},
		},
		computed: {
			stockDesc() {
				return goodsUtils.getStockDesc(this.stock, "goodsDetail");
			},
		},
		async created() {
			// 获取参数
			await this._loadParam();

			// 获取商品信息
			await this._getTimedSaleGoodsDetail();

			// 获取erp自定义规格、货品属性信息
			this._getGoodsInfoFromErp();

			// 加载商品规格信息
			await this._loadGoodsSkuList()

			// 获取活动信息
			this._getTimedSaleInfo();

			// 补充所有扩展信息（库存、价格、营销活动）（渠道和用户之后）
			this._completeAllExtendedInfo();

			this.currentImg_zindex99 = ''; //优先级较高的当前图片
			this.$eventBus.$on('setCustomerId', msg => {
				this.customerId = msg;
			});

			this.isShowPrice = await this.$utils.getIsShowPrice()
		},
		deactivated() {
			this.initData = false;
			this.$eventBus.$emit("showLoginDialog", false, true); //隐藏登录弹窗,可关闭
		},
		destroyed() {
			this.initData = false;
			this.$eventBus.$emit("showLoginDialog", false, true); //隐藏登录弹窗,可关闭
		},
		methods: {
			/**
			 * 加载配置参数
			 */
			async _loadParam() {
				// 获取路由传递参数
				let query = this.$route.query;
				this.saleId = query.saleId || 0;
				this.goodsId = query.goodsId || "";
				this.customerId = localStorage.getItem("pc_customerId") || 0;

				// 获取后台配置参数
				let paramList = await getParam("goodsdetail_AdsNotice,pc_goodsDetailStyle,isOpenIndistinctStock,bindWarehouseId,bindSaleChannelId,isOpenZeroStockBuy,isShowSingleSpec,isShowGoodsCustomField,specCustomFields,goodsCustomFields");
				this.channelId = paramList.bindSaleChannelId || '';
				this.goodsAdsNotice = paramList.goodsdetail_AdsNotice || "";
				this.isOpenIndistinctStock = paramList.isOpenIndistinctStock || '0';
				this.specStyle = paramList.pc_goodsDetailStyle || 0;
				this.bindWarehouseId = paramList.bindWarehouseId;
				this.isOpenZeroStockBuy = paramList.isOpenZeroStockBuy == "1";
				this.isShowSingleSpec = paramList.isShowSingleSpec != "0";
				this.isShowGoodsCustomField = paramList.isShowGoodsCustomField == "1"; // 是否展示自定义字段
				this.specCustomFields = paramList.specCustomFields; // 规格级自定义字段
				this.goodsCustomFields = paramList.goodsCustomFields; // 货品级自定义字段
			},

			/**
			 * 加载抢购活动详情
			 */
			async _getTimedSaleInfo() {
				// 参数缺失直接返回
				if (this.saleId == 0) {
					return
				}
				// 获取商品详情
				let saleInfoList = await getTimedSaleListBySaleId()

				let curSaleInfo = saleInfoList.find(item => item.saleId == this.saleId);

				this.curSaleInfo = curSaleInfo;

				//倒计时
				this._timeCountDown(curSaleInfo.beginTime, curSaleInfo.endTime);
			},

			/**
			 * 加载商品规格信息
			 */
			async _loadGoodsSkuList() {
				// 获取商品规格信息
				this.goodsSpecList = await goodsUtils.getGoodsSkuList(this.goodsId);
			},

			/**
			 * 加载抢购商品详情
			 */
			async _getTimedSaleGoodsDetail() {
				// 参数缺失直接返回
				if (this.saleId == 0) {
					return
				}

				// 获取商品详情
				let goodsInfo = await getTimedSaleGoodsInfo(this.saleId, this.goodsId)
				this.isMulti = goodsInfo.isMulti == 1;
				goodsInfo.goodsDetail = commonUtil.htmlDecode(goodsInfo.goodsDetail);
				this.currentImg = goodsInfo.goodsPicList && goodsInfo.goodsPicList.length > 0 ? goodsInfo
					.goodsPicList[0]
					.picUrl :
					goodsInfo.coverPic;
				this.minCount = 1;
				this.score = !!goodsInfo.score ? parseInt(goodsInfo.score) : 0;

				this.goodsDetail = goodsInfo;
				//this.getCustomerPrice(goodsInfo.goodsSpecList);
				this._setUnitList(goodsInfo);
				this._getGoodsStock();
				this._setValiditydate()
				this.maxCount = goodsInfo.limitCount > 0 && goodsInfo.limitCount < 99999 ? (parseInt(goodsInfo.limitCount) - parseInt(goodsInfo.bountCount || 0)) : 999999;
				this.specIndex = -1;
				this.specChooseError = "请选择规格";
				// 加载时间
				let currentTime = await system.getSysTime();
				this.currentTime = currentTime;
			},

			/**
			 * 补充所有扩展信息（库存、价格、营销活动）
			 */
			_completeAllExtendedInfo() {
				// 请求集合
				return new Promise(async (resolve, reject) => {
					let promiseList = [
						this._completeSpecStockInfo(),
						this._completeSpecPriceInfo(),
						this._completeSpecMarketingInfo()
					];

					// 等待所有接口执行完成
					Promise.all(promiseList).then(() => {
						// 将抢购信息补充到选购弹窗所需的规格数据中
						this._completeSpecTimedSaleInfo();
						this.isShowPage = true;
						resolve();
					});
				});
			},

			/**
			 * 加载规格库存信息
			 */
			async _completeSpecStockInfo() {
				// 获取所有规格Id
				let skuIds = this.goodsSpecList.map(t => t.specId);

				// 获取规格库存信息
				let skuStockList = await goodsUtils.getSkuStockList(skuIds);
				if (!(skuStockList && skuStockList.length > 0)) {
					return;
				}

				// 将库存数据补充到规格信息中
				this.goodsSpecList.forEach(sku => {
					sku.unitStockList = skuStockList.filter(t => t.skuId == sku.specId);
				});
			},

			/**
			 * 加载商品价格信息
			 */
			async _completeSpecPriceInfo() {
				let skuPriceList = [];

				let skuIds = this.goodsSpecList.map(t => t.specId);
				// 获取sku价格列表
				skuPriceList = await goodsUtils.getSkuPriceList(skuIds);

				// 没有价格数据则不做后续处理
				if (!(skuPriceList && skuPriceList.length >= 0)) {
					return;
				}

				// 将价格数据补充到规格信息中
				this.goodsSpecList.forEach(sku => {
					sku.unitPriceList = skuPriceList.filter(t => t.skuId == sku.specId);
				});
			},

			/**
			 * 加载商品营销活动信息
			 */
			async _completeSpecMarketingInfo() {
				// 获取所有规格Id
				let skuIds = this.goodsSpecList.map(t => t.specId);

				// 获取规格的营销活动信息（会根据适用会员过滤）
				let marketingInfoList = await getMarketingInfoListBySpec(skuIds);
				if (!(marketingInfoList && marketingInfoList.length > 0)) {
					return;
				}

				// 将营销活动数据补充到规格信息中
				this.goodsSpecList.forEach(sku => {
					sku.marketingInfoList = marketingInfoList.filter(t => t.specId == sku.specId);
				});

				// 过滤不符合等级的促销商品
				marketingInfoList = marketingInfoList.filter(item => this._checkMarketingMemberLevel(item.limitMember));
				this.marketingInfoList = marketingInfoList || [];
				this.marketingLabel = (marketingInfoList[0] && marketingInfoList[0].labelName) || ""
				// 判断是否满足促销活动等级
				this.satisfyMarketing = marketingInfoList.length > 0;
			},

			/**
			 * 校验促销活动等级
			 * @param {string} limitMember
			 */
			async _checkMarketingMemberLevel(limitMember) {
				let customer = await getCustomerInfo();
				if ((customer && customer.customerId) && (!!limitMember && limitMember !=
						'all')) {
					var supportLevel = limitMember.split(',');
					customer.vipLevel = !!customer.vipLevel ? customer.vipLevel : "empty";
					if (!supportLevel.includes(customer.vipLevel)) {
						return false;
					}
				}
				return true;
			},

			/**
			 * 将抢购信息补充到选购弹窗所需的规格数据中
			 */
			_completeSpecTimedSaleInfo() {
				// 将抢购单位级信息添加到规格
				this.goodsSpecList.forEach(skuItem => {
					skuItem.timedActivityInfo = this.goodsDetail.goodsSpecList.filter(t => t.specId == skuItem.specId);
				});
			},

			// 设置有效期
			async _setValiditydate() {
				let validitydateList = await validitydateUtils
					.getValidityDateByMultiCondition([this.goodsId]);
				if (validitydateList.length > 0) {
					// 获取保质期配置
					let paramList = await getParam("goodsValidityDateFrom,isShowValidateBySplitType");

					this.goodsSpecList.forEach(async item => {
						let validityDateInfo = validitydateList
							.find(t => t.skuId == item.specId || t.skuId == "0") || {};
						// 补充配置信息
						validityDateInfo.validateFrom = paramList.goodsValidityDateFrom || "bathInfo";
						validityDateInfo.isShowValidateBySplitType = paramList.isShowValidateBySplitType == "1";

						item.validityDateObj = validityDateInfo || {};
						item.validityDate = await this.$utils.getFormatValidate(validityDateInfo || {});
						this.goodsValidityDate = this.goodsValidityDate || item.validityDate;
					})
				}
				this.stockChange(0);
			},

			//加载辅助单位信息
			_setUnitList(goodsInfo) {
				let unitList;
				try {
					unitList = JSON.parse(goodsInfo.unitSetting || goodsInfo.unitInfo);
					unitList = unitList.filter(t => t.isOn == "1" || !t.isOn).sort(this.$utils.sortBy("countRate"));
				} catch (e) {
					unitList = [];
				}

				for (let i = 0; i < unitList.length; i++) {
					let countRate = unitList[i].countRate.toString();
					let x = countRate.indexOf(".") + 1;
					let count = countRate.length - x;
					if (count > 2) {
						//超过两位小数，四舍五入保留两位小数
						unitList[i].countRate = parseFloat(countRate).toFixed(2);
					} else if (count == 1 && countRate.substr(x, 1) == "0") {
						unitList[i].countRate = parseFloat(countRate);
					}
				}
				// this.selectedUnitIndex = 0;
				// this.specIndex = 0;
				this.unitList = unitList;
			},

			/* 选中单位 */
			unitChange(e) {
				this.selectedUnitIndex = e.currentTarget.dataset.index;
				if (this.specIndex > -1) {
					this._refreshSpecAndUnitData();
				}
			},

			//选中规格
			specChange(res) {
				// 价格加载是否完成
				let that = this;

				let specIndex = res.specIndex;
				that.specIndex = specIndex;
				that.specChooseError = res.error;
				if (specIndex == -1) {
					that.goodsValidityDate = "";
					that.isTimedSpecAndUnit = false;
					that.skuCustomFieldForShowList = [];
					return;
				}

				let goodsSpecList = that.goodsSpecList;
				that.specId = goodsSpecList[specIndex].specId;
				that.specName = goodsSpecList[specIndex].specName;
				that.goodsValidityDate = goodsSpecList[specIndex].validityDate;

				// 获取自定义规格数据
				that._getGoodsSkuCustomFieldForShow(that.specId)

				let specImg = goodsSpecList[specIndex].picUrl;
				if (specImg) {
					that.currentImgIndex = -1;
					that.currentImg = specImg;
					that.currentImg_zindex99 = "";
				}
				let executionPrice = 0.00;

				if (that.selectedUnitIndex > -1) {
					that._refreshSpecAndUnitData();
				}
			},

			/* 
			 * 重新计算价格库存等信息
			 */
			_refreshSpecAndUnitData() {
				// 获取选中单位信息
				let currentUnit = this.unitList[this.selectedUnitIndex];
				// 计算辅助单位下最小起购量
				let countRate = Number(currentUnit.countRate);
				// 获取选中规格信息
				let selectedSpec = this.goodsSpecList[this.specIndex];

				let minCount = selectedSpec.minCount;
				let stepCount = selectedSpec.stepCount;

				// 设置最小起购量以及步频
				if (minCount != 1) {
					minCount = Math.ceil(Number(minCount) / countRate);
				}
				if (stepCount != 1) {
					stepCount = Math.ceil(Number(stepCount) / countRate);
				}

				// 获取规格库存
				let specStock = 0;
				let canBuyCount = 0;
				let timedLimitCount = 999999;
				let timedMinCount = 1;

				// 补充库存数据
				let curSotckInfo = selectedSpec.unitStockList.find(item => item.unitName == currentUnit.unitName);
				if (curSotckInfo) {
					specStock = parseInt(Number(curSotckInfo.stock));
					// 忽略上限，则默认可购999999，否则按照实际库存
					canBuyCount = this.isOpenZeroStockBuy ? 999999 : specStock;
				}

				// 补充限时抢购商品信息
				this.isTimedSpecAndUnit = false;
				let timedSpecInfo = selectedSpec.timedActivityInfo && selectedSpec.timedActivityInfo.find(item => item.unit == currentUnit.unitName);
				if (timedSpecInfo) {
					timedSpecInfo.goodsCount = timedSpecInfo.goodsCount || 999999; // 为空则不限
					timedSpecInfo.limitCount = timedSpecInfo.limitCount || 999999; // 为空则不限

					timedSpecInfo.leftCount = timedSpecInfo.goodsCount - timedSpecInfo.boughtCount;
					timedSpecInfo.selfCanBuyCount = timedSpecInfo.limitCount - timedSpecInfo.selfBuyCount;
					if (this.isOpenZeroStockBuy) {
						specStock = timedSpecInfo.leftCount;
						canBuyCount = Math.min(timedSpecInfo.leftCount, timedSpecInfo.selfCanBuyCount);
					} else {
						specStock = Math.min(timedSpecInfo.leftCount, specStock);
						canBuyCount = Math.min(timedSpecInfo.leftCount, timedSpecInfo.selfCanBuyCount, specStock);
					}
					timedLimitCount = timedSpecInfo.limitCount || 999999;
					timedMinCount = timedSpecInfo.minCount || 1;
					minCount = timedMinCount || minCount;
					this.isTimedSpecAndUnit = true
				}

				// 获取当前单位的基础价格信息
				let priceInfo = selectedSpec.unitPriceList.find(t => t.unitName == currentUnit.unitName) || {};

				this.stock = specStock;
				this.maxCount = canBuyCount;
				this.orginPrice = priceInfo.executionPrice;
				this.minCount = canBuyCount == 0 ? 0 : minCount;
				this.stepCount = stepCount;
				this.goodsCount = 0;
				this.score = this.isTimedSpecAndUnit ? timedSpecInfo.score : priceInfo.score;
				this.executionPrice = this.isTimedSpecAndUnit ? timedSpecInfo.price : priceInfo.executionPrice;
				this.timedLimitCount = timedLimitCount; // 限时抢购限制购买数
				this.timedMinCount = timedMinCount; // 限时抢购起购数量
				// 执行促销价匹配
				this.marketingLabel = "";
				!this.isTimedSpecAndUnit && this._matchMarketingPrice(minCount);
			},

			/**
			 * 促销价匹配
			 * @param {} 
			 */
			_matchMarketingPrice(goodsCount) {
				// 获取选中规格信息
				let selectedSpec = this.goodsSpecList[this.specIndex];

				// 获取选中单位信息
				let unitName = this.unitList[this.selectedUnitIndex].unitName;

				// 没有匹配的促销活动信息直接返回
				if (!selectedSpec.marketingInfoList) {
					return
				}

				this.marketingLabel = selectedSpec.marketingInfoList[0] && selectedSpec.marketingInfoList[0].labelName;
				// 标记是否执行基础单位的策略
				let isDoBaseUnitMarketing = false;

				// 第一步：获取当前单位设置的促销阶梯价列表
				let currUnitMarketingList = selectedSpec.marketingInfoList.filter(t => t.unitName == unitName);

				// 第二步：如果没有设置当前单位对应的策略，则获取基础单位设置的策略
				if (!(currUnitMarketingList && currUnitMarketingList.length > 0)) {
					currUnitMarketingList = selectedSpec.marketingInfoList.filter(t => t.unitName == this.goodsDetail.baseUnit);
					isDoBaseUnitMarketing = true;
				}

				// 第三步：没有任何可匹配策略则显示原价
				if (!(currUnitMarketingList && currUnitMarketingList.length > 0)) {
					return;
				}

				// 第四步：选购数量换算
				let countRate = 1;
				let selectedCount = goodsCount;
				if (isDoBaseUnitMarketing) {
					countRate = (this.unitList.find(t => t.unitName == unitName) && this.unitList.find(t => t.unitName == unitName).countRate) || 0;
					selectedCount = goodsCount * countRate;
				}

				// 第五步：命中策略检测
				let hittingMarketing = currUnitMarketingList.find(t => selectedCount >= t.limitCount && selectedCount <= t.upperLimitCount);
				// 没有命中任何配策略，则显示原价
				if (!hittingMarketing) {
					return;
				}

				this.executionPrice = hittingMarketing.favorablePrice * countRate;
			},

			//立即购买
			async goPayTap() {
				let customerId = localStorage.getItem("pc_customerId");
				if (!customerId) {
					this.$eventBus.$emit("showLoginDialog", true, true); //显示登录弹窗,可关闭
					return;
				}

				if (!this._checkData()) {
					return;
				}

				// 时间校验
				if (this.isTimedSpecAndUnit && !await this.checkIsInTime()) {
					return;
				}

				// 组装数据
				let cartData = this.generateData(-1);
				let cartIds = await goodsUtils.addShopCart(cartData);

				if (cartIds == 0) {
					return;
				}

				// 修改头部购物车总数
				this.$eventBus.$emit("updateHeaderShopCartCount");

				this.$router.push({
					name: 'tradeconfirm',
					params: {
						trade_from: 'PC限时抢购',
						cartIds: cartIds + ""
					}
				});
			},

			//检测数据
			_checkData() {
				//关闭所有警告
				this.$message.closeAll();
				if (!this.customerId) {
					this.$message.error("未获取到用户信息");
					return;
				}

				if (this.goodsDetail.isBlockup == '1') {
					this.$message.error("商品已下架");
					return;
				}

				if (this.specIndex < 0 || !this.specId || this.specId <= 0) {
					this.$message.error(this.specChooseError || '未选择规格');
					return;
				}

				if (!this.selectedUnitIndex || this.selectedUnitIndex < 0) {
					this.$message.error("未选择单位");
					return;
				}

				if (this.executionPrice <= 0 && this.score <= 0) {
					this.$message.error("商品价格不可为0");
					return;
				}

				if (this.goodsCount <= 0) {
					this.$message.error("购买数量不可小于0");
					return;
				}

				if (!this.isOpenZeroStockBuy && this.goodsCount > this.stock) {
					this.$message.error("商品库存不足");
					return;
				}

				return true;
			},

			// 获取商品可用库存
			async _getGoodsStock() {
				let specIdList = this.goodsSpecList.map(item => {
					return item.specId
				});

				let stockList = await goodsUtils.getSkuStockList(specIdList);

				if (stockList.length > 0) {
					this.stockList = stockList;
					this.stockChange(0);
				}
			},

			goBack() {
				this.$router.back();
			},

			/**
			 * 赋值主图内容（供组件调用）
			 * @param {Object} url
			 */
			setCurrentImg(url) {
				this.currentImg_zindex99 = url;
			},

			//图片鼠标进入事件
			mouseEnter(e) {
				let goodsPicList = this.goodsDetail.goodsPicList;
				this.currentImgIndex = e;
				this.currentImg = goodsPicList.length > 0 ? goodsPicList[e].picUrl : this.goodsDetail.coverPic;
				this.currentImg_zindex99 = "";
			},

			// 上一张
			prePic() {
				let goodsPicList = this.goodsDetail.goodsPicList;
				let length = goodsPicList.length;
				if (length == 0) {
					return;
				}
				this.currentImgIndex = (this.currentImgIndex - 1 + length) % length;
				this.currentImg = goodsPicList[this.currentImgIndex].picUrl;
			},

			// 下一张
			nextPic() {
				let goodsPicList = this.goodsDetail.goodsPicList;
				let length = goodsPicList.length;
				if (length == 0) {
					return;
				}
				this.currentImgIndex = (this.currentImgIndex + 1) % length;
				this.currentImg = goodsPicList[this.currentImgIndex].picUrl;
			},

			//加入购物车
			async addShopCartTap() {
				let customerId = localStorage.getItem("pc_customerId");
				if (!customerId) {
					this.$eventBus.$emit("showLoginDialog", true, true); //显示登录弹窗,可关闭
					return;
				}

				if (!this._checkData()) {
					return;
				}

				// 时间校验
				if (this.isTimedSpecAndUnit && !await this.checkIsInTime()) {
					return;
				}

				// 组装数据
				let cartData = this.generateData(0);
				let cartIds = await goodsUtils.addShopCart(cartData);

				if (cartIds == 0) {
					return;
				}

				this.$message({
					message: "添加成功，可前往购物车查看",
					type: 'success'
				});

				// 清除选择数据
				this.goodsCount = 0;

				// 修改头部购物车总数
				this.$eventBus.$emit("updateHeaderShopCartCount");
			},

			// 组装数据
			generateData(cartType) {
				return {
					cartType: cartType,
					goodsId: this.goodsId,
					specId: this.specId,
					goodsCount: this.goodsCount,
					unitName: this.unitList[this.selectedUnitIndex].unitName,
					unitCountRate: this.unitList[this.selectedUnitIndex].unitCountRate,
					actType: this.isTimedSpecAndUnit ? 1 : 0,
					actId: this.isTimedSpecAndUnit ? this.saleId : "0",
				}
			},

			//数量变化
			countChange(value) {
				if (this.specIndex == -1) {
					return this.$message.error('请选择规格')
				}
				this.goodsCount = parseInt(value);
				!this.isTimedSpecAndUnit && this._matchMarketingPrice(value);
			},
			// 修改库存量
			stockChange(specId) {
				if (this.stockList.length == 0) {
					return;
				}
				let stock = 0;
				let limitCount = 0;
				if (specId == "0") {
					this.stockList.forEach(item => {
						stock += item.stock;
					});
					this.goodsSpecList.forEach(item => {
						limitCount += item.goodsCount - item.boughtCount;
					})

				} else {
					stock = this.stockList.find(item => item.skuId == specId).stock;
					let specInfo = this.goodsSpecList.find(item => item.specId == specId);
					limitCount = specInfo.goodsCount - specInfo.boughtCount;
				}

				this.stock = this.isOpenZeroStockBuy || stock > limitCount ? limitCount : stock;
			},

			// 原价购买
			gotoGoodsDetail() {
				let goods_id = this.goodsId;
				let encode = window.btoa(window.encodeURIComponent(goods_id));
				this.$router.push({
					name: 'goodsdetail',
					query: {
						id: encode
					}
				});
			},

			// 抢购活动时间检验
			async checkIsInTime() {
				let that = this;
				let currentTime = await system.getSysTime();
				that.currentTime = currentTime;
				if (currentTime < that.beginTime) {
					that.$message({
						message: "抢购未开始~",
						type: 'error'
					});
					return false;
				} else if (currentTime > that.endTime) {
					that.$message({
						message: "抢购已结束~",
						type: 'error'
					});
					return false;
				}
				return true;
			},

			// 倒计时
			_timeCountDown(beginTime, endTime) {
				let that = this;
				beginTime = commonUtil.convertTime(beginTime);
				that.beginTime = beginTime;
				endTime = commonUtil.convertTime(endTime);
				that.endTime = endTime;
				let currentTime = that.currentTime;
				let TimedDownTime = endTime;
				let leftText = "";
				if (currentTime >= endTime) {
					clearInterval(that.countdownInterval);
					leftText = "已结束";
					that.leftText = leftText;
					return false;
				} else if (currentTime < beginTime) {
					TimedDownTime = beginTime;
					leftText = "距离开始";
				} else {
					TimedDownTime = endTime;
					leftText = "距离结束";
				}

				var leftTime = TimedDownTime - currentTime;

				that.countdownInterval = setInterval(function() {
					leftTime = leftTime - 1000; //计算剩余的毫秒数 
					var days = parseInt(leftTime / 1000 / 60 / 60 / 24, 10); //计算剩余的天数 
					var hours = parseInt(leftTime / 1000 / 60 / 60 % 24, 10); //计算剩余的小时 
					var minutes = parseInt(leftTime / 1000 / 60 % 60, 10); //计算剩余的分钟 
					var seconds = parseInt(leftTime / 1000 % 60, 10); //计算剩余的秒数 
					days = days <= 0 ? "" : days + "天";
					hours = checkTime(hours);
					minutes = checkTime(minutes);
					seconds = checkTime(seconds);
					that.leftText = leftText;
					that.days = days;
					that.hours = hours;
					that.minutes = minutes,
						that.seconds = seconds;

					if (days == "" && hours == '00' && minutes == '00' && seconds == '00' || seconds < 0) {
						clearInterval(that.countdownInterval);
						that._timeCountDown(beginTime, endTime);
					}
				}, 1000);
				//检测时间
				function checkTime(time) {
					if (time >= 0 && time < 10) {
						time = "0" + time;
					}
					return time;
				}
			},

			/**
			 * 获取erp货品详情
			 */
			async _getGoodsInfoFromErp() {
				if (this.goodsDetail.goodsType == 1 || !this.isShowGoodsCustomField) {
					return
				}

				// 获取erp货品详情
				let tempErpGoodsInfo = await goodsUtils.getGoodsInfoFromErp(this.goodsId);

				// 获取erp自定义字段映射关系
				let erpCustomFieldMap = await this._getGoodsCustomFieldMapFromErp();

				let erpGoodsCustomFieldInfoList = [];
				this.goodsCustomFields.split(",").forEach(item => {
					// 有值才进行展示
					tempErpGoodsInfo[item] && erpGoodsCustomFieldInfoList.push({
						customFieldName: erpCustomFieldMap.find(mapItem => mapItem.fieldName == item).fieldShowName,
						customFieldVal: tempErpGoodsInfo[item]
					})
				})

				this.erpGoodsCustomFieldInfoList = erpGoodsCustomFieldInfoList;
				this.erpGoodsSkuInfoList = tempErpGoodsInfo && tempErpGoodsInfo.goodsSkuInfos || [];
			},

			/**
			 * 获取erp规格级自定义字段的展示列表
			 */
			async _getGoodsSkuCustomFieldForShow(specId) {
				if (!this.erpGoodsSkuInfoList || this.erpGoodsSkuInfoList.length == 0) {
					return
				}
				let tempSkuInfo = this.erpGoodsSkuInfoList.find(item => item.skuId == specId);

				let skuCustomFieldForShowList = [];

				// 获取erp自定义字段映射关系
				let erpCustomFieldMap = await this._getGoodsCustomFieldMapFromErp();

				this.specCustomFields.split(",").map(item => {
					// 有值才进行展示
					tempSkuInfo[item] && skuCustomFieldForShowList.push({
						customFieldName: erpCustomFieldMap.find(mapItem => mapItem.fieldName == item).fieldShowName,
						customFieldVal: tempSkuInfo[item]
					})
				})

				this.skuCustomFieldForShowList = skuCustomFieldForShowList;
			},

			/**
			 * 获取erp货品自定义字段映射关系
			 */
			async _getGoodsCustomFieldMapFromErp() {
				let erpGoodsCustomFieldMap = await goodsUtils.getGoodsCustomFieldMap();
				return erpGoodsCustomFieldMap
			},

		}
	}
</script>

<style scoped="scoped">
	.goods-ads-notice {
		min-height: 50px;
		max-height: 100px;
		overflow: hidden;
		flex-shrink: 0;
	}

	.goods-ads-notice /deep/ img {
		display: block;
		max-width: 100%;
		max-height: 100%;
		margin: 0 auto;
	}

	.base-info {
		padding: 28px 70px 30px 70px;
		border-left: 1px solid #dedede;
		border-right: 1px solid #dedede;
		background-color: #fff;
	}

	.back-btn {
		font-size: 42px;
		margin-left: -30px;
		margin-bottom: 20px;
		cursor: pointer;
	}

	.goods-img {
		width: 100%;
		height: 360px;
	}

	.img-list {
		width: 100%;
		overflow: auto;
	}

	.thum-img {
		width: 60px;
		height: 60px;
		padding: 13px 2px;
		margin: 2px 8px;
		width: 14%;
		flex-shrink: 0;
		flex-grow: 0;
	}

	.active {
		border-bottom: 3px solid #797979;
	}

	.goods1-content {
		margin-left: 50px;
		font-size: 15px;
	}

	.goods1-name {
		font-size: 20px;
		line-height: 32px;
		word-break: break-all;
	}

	.goods1-price {
		margin: 11px 0 10px 0;
		color: #FF5757;
	}

	.currency1 {
		position: relative;
		bottom: 2px;
		right: 2px;
	}

	.price-tip1 {
		position: relative;
		bottom: 2px;
		right: -8px;
	}

	.goods1-stock {
		position: relative;
		bottom: 2px;
		left: 20px;
		color: #999;
	}

	.title {
		margin: 4px 20px;
		color: #999;
	}

	.size26 {
		font-size: 26px;
	}

	.speclsit-choose-tips {
		width: 150px;
		line-height: 50px;
		text-align: center;
		background-color: #f5f7fa;
		border: solid 1px #e4e7ed;
		border-radius: 3px;
		margin-bottom: 20px;
	}

	.speclist-choose {
		margin-top: 30px;
	}

	.handle-box {
		align-items: center;
		justify-content: flex-end;
	}

	.handle-btn {
		height: 53px;
		width: 140px;
		font-size: 17px !important;
		margin-left: 20px !important;

	}

	.detail-box {
		background-color: #F2F2F2;
		padding: 30px 0;
	}

	.seleced-total-count-wrap {
		margin-right: 40px;
	}

	.seleced-total-count {
		margin: 0 10px;
		font-weight: bold;
	}

	.detail-info {
		width: 80%;
		margin: 0 auto;
		background-color: #fff;
	}

	.detail-info /deep/ p {
		margin: 0;
	}

	.detail-info /deep/ img {
		width: 100%;
		display: block;
	}

	.goods_no_text {
		color: #b6b6b6;
		line-height: 28px;
		font-size: 15px;
	}

	.price_origin {
		color: #b6b6b6;
		line-height: 28px;
		font-size: 15px;
	}

	.margin-price {
		margin: 0 20px;
	}

	.goods_limit_text {
		color: #b6b6b6;
		font-size: 14px;
	}

	.button-border {
		border-radius: 3px;
		color: #f63;
		font-size: 14px;
		border: 1px solid #f63;
		height: 25px !important;
		line-height: 25px;
		margin-left: 10px;
		word-break: break-all;
		overflow: hidden;
		text-align: center;
		padding: 0 5px;
		display: inline-block;
		transform: translateY(4px);
		margin-left: 20px;
	}

	.magin-stock {
		margin-left: 20px;
	}

	.originalbuy-button {
		width: 160px;
		height: 68px;
		background: #c41d1d;
		border-radius: 4px;
	}

	.padding-price {
		padding-top: 10px;
	}

	.size18 {
		font-size: 18px;
	}

	.size22 {
		font-size: 22px;
	}

	.line-through {
		text-decoration: line-through;
		margin: 0px 15px;
	}

	.img-timed {
		height: 38px;
		width: 692px;
	}

	.TimedLeftTime-text {
		font-size: 16px;
		background-color: black;
		color: #FFFFFF;
		padding: 0px 4px;
		margin: 0px 4px;
		text-align: center;
		line-height: 20px;
		vertical-align: middle;
		border-radius: 5px;
	}

	.TimedLeftTime-lefttext {
		font-size: 13px;
		color: #FFFFFF;
		font-weight: bold;
	}

	.TimedLeftTime {
		display: flex;
		flex-direction: row;
		position: absolute;
		top: 10px;
		right: 0;
	}

	.prePic-button {
		position: absolute;
		top: 38.5px;
		left: 0;
	}

	.nextPic-button {
		position: absolute;
		top: 38.5px;
		right: 0;
	}

	.colon-color {
		color: #FFFFFF;
	}

	.info-button {
		width: 160px;
		height: 68px;
		background: #fff2f2;
		border: 1px solid #f63e3e;
		border-radius: 4px;
		color: #f63e3e;
	}

	.my-danger-button {
		width: 160px;
		height: 68px;
		background: #f63e3e;
		border: 1px solid #f63e3e;
		border-radius: 4px;
	}

	.disabled-button {
		filter: grayscale(100%);
		background-color: #C0C4CC !important;
		color: #FFF !important;
	}

	.attrselected {
		background-color: #FFF1F1 !important;
		border: solid 1px #FF5757;
		color: #FF5757;
	}

	.customfield-content {
		display: flex;
	}

	.customfield-name {
		color: #999999;
		font-size: 15px;
		margin: 5px 0;
		line-height: 18px;
		white-space: nowrap;
	}

	.goods_skucustomfield {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
	}

	.skucustomfield_item {
		/* flex: 1;
		width: 50%; */
		display: flex;
		flex-wrap: nowrap;
	}

	.img-list::-webkit-scrollbar {
		height: 3px;
	}
</style>