<template>
	<div class="timed-goods-detail flex-col">
		<div class="goods-img-wrap" @click="gotoTimedGoodsDetail(timedGoodsDetail)">
			<img class="timedgoods-img" :src="$utils.set400Img(timedGoodsDetail.coverPic)" @error="$utils.set400Img('')" />
		</div>
		<div class="goods-name text-ellipsis cursor-style" :title="timedGoodsDetail.goodsName">{{timedGoodsDetail.goodsName}}</div>
		<div class="flex-between" style="width: 238px;margin-bottom: 15px;">
			<div class="flex-col cursor-style">
				<div class="goods-price flex-row">
					<span v-show="timedGoodsDetail.price && isShowPrice">
						<span style="font-size: 16px;" v-show="timedGoodsDetail.price>0 || (timedGoodsDetail.price && timedGoodsDetail.score==0)">{{$currency.symbol}}{{$utils.filterTrailingZeros(timedGoodsDetail.price)}}</span>
						<span v-show="timedGoodsDetail.price && timedGoodsDetail.price!=0 && timedGoodsDetail.score > 0" class="goods-score">+</span>
						<span v-show="timedGoodsDetail.score>0" class="goods-score">{{timedGoodsDetail.score}}积分</span>
						<span class='goods-price-detail' v-if="isShowPriceDetailInner">{{$currency.symbol}}{{$utils.filterTrailingZeros(timedGoodsDetail.oldPrice)}}</span>
					</span>
				</div>
				<div class="process-margin" style="width: 127px;">
					<el-progress :stroke-width="12" :show-text="false" color="#f63e3e;" :percentage="timedGoodsDetail.boughtCount / timedGoodsDetail.goodsCount *100"></el-progress>
				</div>
				<span class="saled-span">已售: {{timedGoodsDetail.boughtCount || 0}}</span>
			</div>
			<div class="timed-buy-button" v-if="!activityIsUnStart" @click="gotoTimedGoodsDetail(timedGoodsDetail)">
				<div class="flex-col process-margin">
					<span class="timed-but-button-text">马上抢购</span>
					<span class="timed-but-button-text timed-buy-button-left-text">仅剩：{{timedGoodsDetail.leftCount}}</span>
				</div>
			</div>
			<div class="timed-buy-button soon-button" v-if="activityIsUnStart" @click="gotoTimedGoodsDetail(timedGoodsDetail)">
				<span class="timed-but-button-text soon-text" v-if="activityIsUnStart">即将开始</span>
			</div>
		</div>
	</div>
</template>

<script>
	import commonUtil from '../../utils/common-utlis.js'
	export default {
		props: {
			timedGoodsDetail: Object,
			activityList: Array,
			saleId: String,
			activityIsUnStart: {
				type: Boolean,
				default: false
			},
			isShowPriceDetail: {
				type: Boolean,
				default: true
			},
		},
		data() {
			return {
				isShowPrice: false,
			}
		},
		computed: {
			isShowPriceDetailInner() {
				return this.isShowPriceDetail;
			},
		},
		async mounted() {
			this.$eventBus.$on('setCustomerId', async msg => {
				this.customerId = msg;
				this.isShowPrice = await this.$utils.getIsShowPrice()
			})
			this.isShowPrice = await this.$utils.getIsShowPrice()
			// this.initData();
		},
		watch: {
			async customerId(newName, oldName) {
				// 判断是否展示价格
				this.isShowPrice = await this.$utils.getIsShowPrice();
			},
		},
		methods: {
			// 初始化数据
			initData() {
				// 获取剩余量
				let timedGoodsDetail = this.timedGoodsDetail;
				timedGoodsDetail.leftCount = parseInt(timedGoodsDetail.goodsCount) - parseInt(timedGoodsDetail
					.boughtCount || 0);
				if (parseFloat(timedGoodsDetail.leftCount) > parseFloat(timedGoodsDetail.goodsStock)) {
					timedGoodsDetail.leftCount = timedGoodsDetail.goodsStock;
				}

				this.timedGoodsDetail = timedGoodsDetail;
			},
			gotoTimedGoodsDetail(timedGoodsDetail) {
				// 未开始活动商品点击
				if (this.activityIsUnStart) {
					let tempBeginTime = this.activityList.find(item => item.saleId && item.saleId == this.saleId).beginTime;
					let beginTime = new Date(tempBeginTime);
					let beginTimeDesc = commonUtil.formatTime(beginTime.getTime(), "M月 D日 h:m");
					return this.$message('活动将在 ' + beginTimeDesc + ' 开始')
				}
				this.$router.push({
					name: 'timedsalegoodsdetail',
					query: {
						saleId: this.saleId,
						goodsId: this.timedGoodsDetail.goodsId
					}
				})
			}
		}
	}
</script>

<style>
	.timed-goods-detail {
		width: 278px;
		height: 412px;
		background: #ffffff;
		border: 1px solid #e2e2e2;
		border-radius: 8px;
		margin: 10px;
		background-color: #FFFFFF;
		text-align: center;
		align-items: center;
		position: relative;
	}

	.goods-img-wrap {
		max-width: 250px !important;
		height: 250px !important;
		display: flex;
		align-items: center;
		margin: 14px 14px 0 14px;
	}

	.goods-name {
		width: 240px;
		font-size: 14px;
		font-family: Source Han Sans SC, Source Han Sans SC-Regular;
		font-weight: 400;
		text-align: left;
		color: #666666;
		line-height: 20px;
		margin: 5px 0;
		height: 40px;
		box-sizing: border-box;
	}

	.oldprice-margin {
		margin-left: 23px;
	}

	.saled-span {
		width: 55px;
		height: 17px;
		font-size: 12px;
		font-family: Source Han Sans SC, Source Han Sans SC-Regular;
		font-weight: 400;
		text-align: left;
		color: #999999;
	}

	.process-margin {
		margin: 10px 0;
	}

	.timedgoods-img {
		width: 250px;
		height: 250px;
		background: rgba(0, 0, 0, 0.00);
		cursor: pointer;
	}

	.timed-buy-button {
		width: 87px;
		height: 57px;
		background: linear-gradient(180deg, #fc835d, #ec0000);
		border-radius: 3px;
		cursor: pointer;
	}

	.timed-but-button-text {
		height: 20px;
		font-size: 14px;
		font-family: Source Han Sans SC, Source Han Sans SC-Medium;
		font-weight: 500;
		color: #fffcfc;
	}

	.timed-buy-button-left-text {
		font-size: 12px;
		opacity: 0.74;
	}

	.soon-button {
		background: RGB(56, 56, 70);
	}

	.soon-text {
		line-height: 56px;
	}

	.cursor-style {
		cursor: default;
	}

	.goods-price-detail {
		font-size: 12px;
		text-decoration: line-through;
		color: #999;
		margin-left: 4px;
	}
</style>