<template>
	<div class="cgoods-list flex-col">
		<el-row class="cgoods-item" :gutter="30" v-for="(item, index) in goodslist" :key="index">
			<el-col :span="4">
				<div style="position: relative;">
					<el-image class="cgoods-img" fit='scale-down' :src="$utils.set400Img(item.coverPic)" :data-goodsid="item.packageGoodsId||item.goodsId" @click="gotoGoodsDetail">
					</el-image>
					<div class="canot-tap" v-if="item.can_not_buy">不可购买</div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class='goods-box' :data-goodsid="item.packageGoodsId||item.goodsId" @click="gotoGoodsDetail">
					<div class="cgoods-name text-ellipsis" style="word-break: break-all;" :title="item.goodsName">
						<span class="presale-style" v-if="item.actType == '3'">预售</span>{{item.goodsName}}
					</div>
					<div class="cspec-name label-content">
						<span class="act-label" v-for="gitem in item.labelNameList">
							{{gitem}}
						</span>
					</div>
					<div class="cspec-name" v-if="(isShowSingleSpec && (item.isMulti =='0'))|| (item.isMulti != '0')">
						{{item.specName}}
					</div>
					<div class="cspec-name" v-if="!!item.specCode">
						条码:{{item.specCode}}
					</div>
					<div class="cspec-name" style="color:#f63;" v-if="item.can_not_buy">您已经从购物车购买过此商品，请刷新购物车或重新选择商品下单</div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="cgoods-price">
					<span v-if="item.executionPrice>0 || (item.executionPrice && item.score==0)">{{$currency.symbol}}{{$utils.filterTrailingZeros(item.executionPrice)}}</span>
					<span v-show="item.executionPrice != 0 && item.score > 0"> + </span>
					<span v-show="item.score > 0">{{item.score}}</span>
					<span v-show="item.score > 0" class="sscore-text">积分</span>
				</div>
			</el-col>
			<el-col :span="4">
				<div class="cgoods-price">x {{$utils.amountFixed(item.sellCount|| item.goodsCount, 0)}} {{item.unit}}</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default {
		name: "goods-list",
		props: {
			goodslist: {
				type: Array,
				default: function() {
					return [];
				}
			},
			isShowSingleSpec: {
				type: Boolean,
				default: true
			},
			canToDetail: {
				type: Boolean,
				default: true
			}
		},
		methods: {
			/**
			 * 跳转商品详情
			 * @param {Object} e
			 */
			gotoGoodsDetail(e) {
				if (!this.canToDetail) {
					return;
				}

				let goodsId = e.currentTarget.dataset.goodsid;
				let encode = window.btoa(window.encodeURIComponent(goodsId))
				this.$router.push({
					name: 'goodsdetail',
					query: {
						id: encode
					}
				});
			},
		}
	}
</script>

<style scoped="scoped">
	.cgoods-list :last-child {
		/* border: none !important; */
	}

	.cgoods-item {
		display: flex;
		align-items: center;
		padding: 20px;
		border-bottom: 1px solid #dedede;
	}

	.cgoods-img {
		width: 100%;
		height: 110px;
	}

	.cspec-name {
		margin-top: 20px;
		font-size: 15px;
		color: #666;
	}

	.sscore-text {
		font-size: 15px;
		margin-left: 3px;
	}

	.act-label {
		border-radius: 5px;
		color: #f63;
		font-size: 14px;
		border: 1px solid #f63;
		height: 20px !important;
		/* 		margin-left: 10px; */
		word-break: break-all;
		overflow: hidden;
		text-align: center;
		background: #fff;
		padding: 2px 6px;
		max-width: 240px;
		margin-right: 4px;
		margin-bottom: 6px;
	}

	.canot-tap {
		color: white;
		background-color: #000000;
		opacity: 0.3;
		border-radius: 25px;
		line-height: 30px;
		text-align: center;
		width: 94px;
		position: absolute;
		top: 38px;
		right: 13%;		
	}

	.goods-box {
		min-height: 70px;
	}

	.presale-style {
		background: #0c0c0c;
		color: #FFFFFF;
		padding: 0 10px;
	}
	
	.label-content {
		display: flex;
		flex-wrap: wrap;
	}
</style>