import Vue from 'vue'
import App from './App.vue'
// 引入路由对象
import router from './router.js'
// 引入axios对象
import axios from 'axios'
import VueAxios from 'vue-axios'
// 引入公共样式文件
import './assets/css/base.css'
// 引入ElementUI
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import './theme/element/theme/index.css'
//引入复制到剪贴板
import VueClipboard from 'vue-clipboard2'
//引入工具类文件
import utils from './utils/common-utlis.js'
import request from './utils/request-utils.js'
import publicMethods from './utils/public-methods.js'
import currency from './utils/currency-utils.js'
import { getParam } from './utils/api-utils/api-params.js'

// 非父子组件之间传值及事件广播监听中转
import eventBus from './utils/event-bus.js'
import moment from 'moment'
import {
	constant,
	allUserSettingKeyList
} from './config.js'

let isFirsrLoad = true

Vue.prototype.$moment = moment;
Vue.prototype.$utils = utils;
Vue.prototype.$request = request;
Vue.prototype.$public = publicMethods;
Vue.prototype.$eventBus = eventBus;
Vue.prototype.$currency = currency;

Vue.use(VueAxios, axios);
Vue.use(ElementUI);
Vue.use(VueClipboard);

// 提示开关
Vue.config.productionTip = false
Vue.config.devtools = true //生产环境也可使用vue-devtools工具

//全局路由前置守卫，判断链接中是否有吉客号
router.beforeEach(async (to, from, next) => {
	if (parent != window) {
		try {
			window.parent.postMessage({
				cmd: 'changeUrl',
				params: {
					success: true,
					data: to.fullPath
				}
			}, '*');
		} catch {}
	}
	let userName = request.getUserName();
	if (!userName && to.name != 'error') {
		console.log("吉客号获取失败");
		next({
			name: 'error'
		});
	} else if (utils.getCustomerId() == "0" && to.name != 'login' && to.name != 'error') {
		// 刷新时重新获取配置
		await getParamBeforeLoad();
		
		//这里写登录拦截（如果缓存中有客户信息就不用跳转到登录页，就直接next()）
		// console.log(to);
		let paramList = await getParam("isLoginForMemberGrade,loginForMemberGradeId");
		// 是否限制会员等级登录注册
		let isLoginForMemberGrade = paramList["isLoginForMemberGrade"] || "";
		// 限制登录的会员等级
		let loginForMemberGradeId = paramList["loginForMemberGradeId"] || "";
		// 开启会员等级限制，并且设置了相应的会员等级才做拦截
		if (isLoginForMemberGrade == "1" && loginForMemberGradeId) {
			return next({
				name: 'login',
				NAVTYPE: 'replace' // redirectTo
			});
		} else {
			next();
		}
	} else {
		// 刷新时重新获取配置
		await getParamBeforeLoad();
		
		next();
		let newPcmallVersion = await utils.getSysSetting("pcmall_version");
		location.href.includes("/jmc/pcmall") && (!localStorage.getItem("pcmall_version") || newPcmallVersion != localStorage.getItem("pcmall_version")) && window.location.reload();
	}
})

async function getParamBeforeLoad() {
	// 可以做到在进入页面前加载完配置
	if (!isFirsrLoad) {
		return
	}
	isFirsrLoad = false;

	let paramList = await getParam('decimalKeepPlaces');

	// 小数保留位数
	localStorage.setItem('decimalKeepPlaces', paramList.decimalKeepPlaces || 2);
}

new Vue({
	router: router, // 使用路由对象
	render: h => h(App),
}).$mount('#app')