<!-- 我的购物车 -->
<template>
	<div id="shopcart">
		<div class="container">
			<div class="cart-wrap" v-if="shopCartList.length>0">
				<!-- 购物车抬头 -->
				<div class="cart-title-wrap">
					<span class="cart-title">购物车</span>
					<span class="shop-btn" @click="goShopping">继续购物</span>
				</div>

				<!-- 购物车列表 -->
				<div id='mainContent' class="cart-list">
					<div v-for="(item,index) in shopCartList" class="cart-item flex-row">
						<div class="cart-checkbox">
							<el-checkbox v-model="checkedArr[index]" @change="checked=>selectCart(checked,index)" :disabled="item.isInvalid">
							</el-checkbox>
						</div>
						<div class='goods-box'>
							<div class="relative-pos" style="width: 140px;">
								<img class="goods-img" :src="$utils.set400Img(item.coverPic)" :data-goodsid="item.goodsId" @click="gotoGoodsDetail(item)" />
								<img class='sold-out-img' src="../../assets/images/ico-soldout.svg" v-if="isShowOverStockTag && item.stock<=0 " />
								<div v-if="isShowStockOuts && item.stock>0 && item.stock<=5">
									<div class="warn-stock">库存告急</view>
									</div>
								</div>
							</div>
							<div class="cart-goods-info">
								<div class="cart-goods-name" v-if="showGoodsName" :title="item.goodsName">
									{{item.goodsName}}
								</div>
								<div class="cart-goods-name" v-else :title="item.goodsNo">
									{{item.goodsNo}}
								</div>

								<div class="cart-spec-name text-ellipsis">
									<span v-if="(item.isMulti == '0' && isShowSingleSpec )
									|| item.isMulti != 'False'" class="cart-spec-unit">{{item.specName}}</span>
									<span class="">单位：{{item.unitName}}</span>
								</div>
								<div class="cart-spec-name label-content">
									<span class="act-label" v-if="item.actType =='1'">
										限时抢购
									</span>
									<span class="act-label" v-for="gitem in item.labelNameList">
										{{gitem}}
									</span>
								</div>
								<div class="cart-spec-name" style="white-space: pre;" v-if="!!item.validityDate||!!item.stockDesc">
									<div v-if="!!item.stockDesc">
										<span :class='item.stock > 0 ? "" : "zero-stock"'>{{item.stockDesc}}</span>
									</div>
									<span v-if="!!item.validityDate" class="text-ellipsis">{{item.validityDate}}</span>
								</div>
								<div v-if="item.isInvalid">
									<div class="warn-blockup">{{item.invalidReason}}，请选择其他商品</view>
									</div>
								</div>
							</div>
						</div>
						<div class="cart-price">
							<span v-if="item.executionPrice>0 || (item.executionPrice && item.score==0)">{{$currency.symbol}}{{$utils.filterTrailingZeros(item.executionPrice)}}</span>
							<span v-show="item.executionPrice && item.score > 0">+</span>
							<span v-show="item.score>0">{{item.score}}积分</span>
							<div class="price-detail" v-if="isOpenShowDetailPrice && (item.referencePrice>(item.executionPrice||99999999)||item.referencePrice>item.executionPrice)">
								{{$currency.symbol}}{{$utils.filterTrailingZeros(item.referencePrice)}}
							</div>
						</div>
						<div class="cart-count-input">
							<el-input-number :disabled="item.isInvalid" :step="parseInt(item.stepCount)" :min="parseInt(item.minCount)" :max="item.canBuyCount||999999" v-model="item.goodsCount" @change="(newVal, oldVal) => {changeGoodsCount(newVal,oldVal,index)}">
							</el-input-number>
						</div>
						<div class="cart-del-btn" :data-index="index" @click="delCart">
							<i class="el-icon-delete"></i>
						</div>
					</div>
				</div>

				<!-- crm促销策略匹配结果 -->
				<crm-ploy-info class='crm-ploy-info' v-if='isMatchedCrmPloy' :matchResult="crmPloyMatchResult"></crm-ploy-info>

				<!-- 总计结算 -->
				<div class="cart-bottom-wrap flex-row">
					<div class="cart-checkbox cart-all-checkbox">
						<el-checkbox v-model="isSelectAll" @change="selectAllCart">全选</el-checkbox>
					</div>
					<div class="del-all-btn" @click="delAllCart"><i class="el-icon-delete"></i><span>清空</span></div>
					<div class="sum-data-wrap flex-row">
						<div class="sum-data"><span>数量：</span><span>{{totalCount}}</span></div>
						<div class="sum-data sum-data-money">
							<span>合计：</span>
							<span class="sum-data-money-txt">{{$currency.symbol}}{{totalMoney}}</span>
							<span v-show="totalScore>0">+{{totalScore}}积分</span>
						</div>
						<div class="sum-data" v-if="discountAmount>0" style='margin-left: 40px;'><span>优惠：</span><span>{{$currency.symbol}}{{$utils.amountFixed(discountAmount, 2)}}</span></div>
					</div>
					<el-button class="pay-btn" type="danger" @click="goPay">立即结算</el-button>
				</div>
			</div>

			<!-- 空购物车 -->
			<div class="empty-cart-wrap flex-col flex-center" v-else>
				<img class="empty-cart-img" src="../../assets/images/empty-cart.png" />
				<div class="empty-cart-tips">您的购物车是空的，快去选购商品吧</div>
				<el-button class="empty-cart-btn" type="danger" @click="goShopping">前往商城</el-button>
			</div>
		</div>
		<!-- 货品检测弹窗 -->
		<check-goods-dialog :isShowDialog.sync="isShowStockCheckModal" :goodsList="cannotOrderGoodsList" :isNoValidGoods="isNoStockAll" :isNeedTitle="true" confirmText="继续下单" cancelText="移除库存不足商品" modalStyle="shopcart" :noValidGoodsTip="OpenZeroStock_tipText" :hasValidGoodsTip="OpenZeroStock_tipText" @confirm="toBuy" @cancel="filterThenToBuy">
		</check-goods-dialog>
		<!-- 多单位货品检测弹窗 -->
		<check-goods-dialog :isShowDialog.sync="isShowMultiUnitStockCheckModal" :goodsList="baseUnitOverOrderGoodsList" :isMultiUnitOverStock="true" hasValidGoodsTip="库存不足,请重新选货" @cancel="multiUnitOverStockCancel">
		</check-goods-dialog>
	</div>
</template>

<script>
	import vHeader from '../../components/common/Header.vue';
	import goodsUtils from '../../utils/api-utils/api-goods.js';
	import commonUtils from '../../utils/common-utlis.js';
	import checkGoodsDialog from '../../components/trade/CheckGoodsDialog.vue';
	import crmPloyInfo from '../../components/goods/CrmPloyInfo.vue';
	import {
		getShoppingCartList,
		updateShoppingCartCount
	} from '../../utils/api-utils/api-goods-shoppingcart.js';
	import {
		getParam
	} from '../../utils/api-utils/api-params.js';
	import {
		matchCrmPromotionPloy
	} from '../../utils/api-utils/api-goods-price.js';
	export default {
		name: 'shopcart',
		components: {
			'v-header': vHeader,
			"check-goods-dialog": checkGoodsDialog,
			"crm-ploy-info": crmPloyInfo,
		},
		data() {
			return {
				channelId: "", // 渠道Id
				checked: false,
				shopCartList: [], //购物车列表
				orginShopCartList: [], // 原始购物车
				isSelectAll: false, //是否全选
				checkedArr: [], //选中状态列表
				totalMoney: 0.00, //选中商品总额
				totalScore: 0, //选中商品总积分
				// cartType: 2, //购物车来源 -1再来一单进入 2为PC端
				cartType: 0, //购物车来源 -1再来一单进入 2为PC端
				min: 1,
				max: 50,
				inputkey: true, // 保证input-number刷新
				isOpenZeroStockBuy: false, //0库存下单
				isShowSingleSpec: true,
				isOpenIndistinctStock: false, // 模糊库存
				isOpenShowDetailPrice: false, //展示零售价
				isShowGoodsStock: false, //展示库存
				showGoodsName: !['795330'].includes(localStorage.getItem("JackyunUserName")),
				OpenZeroStock_tipText: '', // 0库存提示
				OpenZeroStock_isOpenRemind: false, // 是否开启0库存提醒
				cannotOrderGoodsList: [], // 不能下单商品列表
				isNoStockAll: false, // 全部库存不足
				isShowStockCheckModal: false, //展示库存校验弹窗
				isShowMultiUnitStockCheckModal: false, //展示多单位库存校验弹窗
				baseUnitOverOrderGoodsList: [], //展示多单位库存不足货品

				isMatchedCrmPloy: false, // 是否匹配到CRM策略
				crmPloyMatchResult: {}, // crm促销策略匹配结果
				crmPloyDeductionAmount: 0, // crm促销策略抵扣金额
				premiumMembershipDiscountAmount: 0, // 付费会员优惠金额
			}
		},
		created: async function() {
			// 加载页面参数
			await this._loadParams();

			//加载购物车列表
			this.getShopCartList();
		},
		mounted() {
			let customerId = localStorage.getItem("pc_customerId");
			if (!customerId) {
				this.$eventBus.$emit("showLoginDialog", true, false); //显示登录弹窗,不可关闭
			}
		},
		destroyed() {
			this.$eventBus.$emit("showLoginDialog", false, true); //隐藏登录弹窗,可关闭
		},
		computed: {
			//（使用计算属性统计计算数量总额，无需监听数量输入框，删除按钮等）
			//当前选中的购物车列表
			checkedCartList() {
				let checkedList = [];
				if (this.isSelectAll) {
					checkedList = this.shopCartList.filter(t => !t.isInvalid);
				} else {
					for (let i = 0; i < this.shopCartList.length; i++) {
						if (this.checkedArr[i]) {
							checkedList.push(this.shopCartList[i]);
						}
					}
				}

				return checkedList;
			},
			//当前选中的商品总数
			totalCount() {
				let tCount = 0,
					tMoney = 0,
					tScore = 0;
				for (let i = 0; i < this.checkedCartList.length; i++) {
					let cart = this.checkedCartList[i];
					if (cart.isInvalid) continue
					tCount += parseInt(cart.goodsCount);
					tMoney += parseFloat(parseFloat(cart.executionPrice) * parseInt(cart.goodsCount));
					tScore += parseInt(parseInt(cart.score) * parseInt(cart.goodsCount));
				}
				this.totalMoney = tMoney.toFixed(2);
				this.totalScore = tScore.toFixed(0);
				return tCount;
			},
			//当前所有购物车Id,清空购物车时用到
			allCartIds() {
				var ids = [];
				for (let i = 0; i < this.shopCartList.length; i++) {
					ids.push(this.shopCartList[i].cartId);
				}
				return ids;
			},
			/**
			 * 优惠金额
			 */
			discountAmount() {
				return commonUtils.floatKeepDigit(this.crmPloyDeductionAmount + this.premiumMembershipDiscountAmount);
			}
		},
		watch: {
			/**
			 * 监听促销策略匹配结果
			 * @param {Object} newValue
			 * @param {Object} oldValue
			 */
			crmPloyMatchResult(newValue, oldValue) {
				// 计算容器高度
				let paddingBottom = 0;
				if (newValue.promotionResult && newValue.promotionResult.length > 0) {
					let resultLength = newValue.promotionResult.length;
					resultLength = resultLength > 3 ? 3 : resultLength;
					paddingBottom = resultLength * 24 + 20;
				}
				document.getElementById('mainContent') && (document.getElementById('mainContent').style.paddingBottom = paddingBottom + "px");
			},
		},
		methods: {
			/**
			 * 加载页面参数
			 */
			async _loadParams() {
				//获取页面传递的参数
				let params = this.$route.params;
				if (params && params.cartFrom == '再来一单') {
					this.cartType = '-1';
				}

				// 获取后台配置参数
				let paramList = await getParam("OpenZeroStock_isOpenRemind,OpenZeroStock_tipText,bindSaleChannelId,isOpenZeroStockBuy,isShowSingleSpec,isOpenIndistinctStock,isShowGoodsStock,isOpenShowDetailPrice,isShowStockOuts,isShowOverStockTag,labelsInExtraPage");
				// 默认渠道
				this.channelId = paramList.bindSaleChannelId || '';
				// 0库存下单
				this.isOpenZeroStockBuy = paramList.isOpenZeroStockBuy == "1";
				// 0库存下单提醒
				this.OpenZeroStock_isOpenRemind = paramList.OpenZeroStock_isOpenRemind == "1";
				this.OpenZeroStock_tipText = paramList.OpenZeroStock_tipText || "";
				// 库存展示
				this.isShowSingleSpec = paramList.isShowSingleSpec != "0";
				// 模糊库存展示
				this.isOpenIndistinctStock = paramList.isOpenIndistinctStock == "1";
				// 精确库存
				this.isShowGoodsStock = paramList.isShowGoodsStock == "1";
				// 零售价展示
				this.isOpenShowDetailPrice = paramList.isOpenShowDetailPrice == "1";
				// 是否展示缺货库存
				this.isShowStockOuts = paramList.isShowStockOuts != "0";
				// 是否展示缺货标签
				this.isShowOverStockTag = paramList.isShowOverStockTag != "0";
				// 商品标签展示数据
				this.labelsInExtraPage = paramList.labelsInExtraPage || "";
			},

			//加载购物车列表
			async getShopCartList() {
				//展示加载遮罩层
				let loadingLayer = this.$loading({
					lock: true,
					background: 'rgba(255, 255, 255, 0.2)'
				});

				// 渲染购物车
				let tempShopCartList = await getShoppingCartList(this.cartType, [], true, true, this.isOpenZeroStockBuy, this.isShowGoodsValidityDate);
				tempShopCartList.forEach(cart => {
					// 处理需要展示的标签
					let labelListInExtraPage = this.labelsInExtraPage.split(",");
					let labelNameList = (cart.labels || '').split(',').filter(item => item && labelListInExtraPage.includes(item));

					// 补充标签数据
					let tempMarketingLabelName = cart.marketingInfoList && cart.marketingInfoList[0] && cart.marketingInfoList[0].labelName;
					tempMarketingLabelName && labelNameList.unshift(tempMarketingLabelName)
					cart.labelNameList = labelNameList;
				})

				this.shopCartList = tempShopCartList;

				//关闭加载遮罩层
				loadingLayer.close();

				// 默认全都不选中
				for (let i = 0; i < this.shopCartList.length; i++) {
					this.checkedArr.push(false);
				}
			},

			//继续购物
			goShopping() {
				this.$router.push({
					name: 'goodslist'
				});
			},

			//修改商品数量
			changeGoodsCount: commonUtils.debounce(async function(newVal, oldVal, index) {
				let that = this;
				let cart = that.shopCartList[index];
				let min = parseInt(cart.minCount);
				let step = parseInt(cart.stepCount);
				if (newVal != oldVal) {
					if (newVal < min) {
						that.shopCartList[index].goodsCount = min;
						that.inputkey = !that.inputkey;
						that.$message.error("不能再少了");
						return;
					}
					if ((newVal - min) % step > 0) {
						that.shopCartList[index].goodsCount = min;
						that.inputkey = !that.inputkey;
						that.$message.error("购买数量必须为单次购买量" + step + "的倍数加最小起购量");
						return;
					}

					// 更新购物车数量
					if (await updateShoppingCartCount(cart.cartId, newVal)) {
						that.shopCartList[index].goodsCount = newVal;
						that.inputkey = !that.inputkey;
						// 计算选中的促销数据
						this.calcMarketingPrice();
						that.$eventBus.$emit("updateHeaderShopCartCount"); //修改购物车总数
					}
				}

				// 匹配crm促销策略
				this.__matchCrmPromotionPloy();
			}, 300, false),

			//选中单个商品
			selectCart(checked, index) {
				this.isSelectAll = checked && this.checkedCartList.length == this.shopCartList.length;

				// 匹配crm促销策略
				this.__matchCrmPromotionPloy();
			},
			//全选
			selectAllCart(checked) {
				for (let i = 0; i < this.checkedArr.length; i++) {
					// 判断是否为有效商品，有效商品才可以勾选
					if (!this.shopCartList[i].isInvalid) {
						this.checkedArr[i] = checked;
					}
				}
				this.isSelectAll = checked;

				// 匹配crm促销策略
				this.__matchCrmPromotionPloy();
			},

			//删除单个商品
			delCart(e) {
				let index = parseInt(e.currentTarget.dataset.index);
				let cartId = this.shopCartList[index].cartId;

				this.$confirm('确定删除?', '提示').then(async () => {
					//删除
					let successJudge = await goodsUtils.deleteShoppingCart([cartId]);

					if (!successJudge) {
						return this.$message.error('删除失败');
					}

					this.shopCartList.splice(index, 1);
					this.checkedArr.splice(index, 1);
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					this.$eventBus.$emit("updateHeaderShopCartCount"); //修改购物车总数

					// 匹配crm促销策略
					this.__matchCrmPromotionPloy();
				});
			},
			//清空购物车
			delAllCart() {
				this.$confirm('确定删除?', '提示').then(async () => {
					//删除
					let successJudge = await goodsUtils.deleteShoppingCart(this.allCartIds);

					if (!successJudge) {
						return this.$message.error('清空失败');
					}

					this.shopCartList = [];
					this.checkedArr = [];
					this.isSelectAll = false;
					this.$message({
						type: 'success',
						message: '清空成功!'
					});
					this.$eventBus.$emit("updateHeaderShopCartCount"); //修改购物车总数

					// 匹配crm促销策略
					this.__matchCrmPromotionPloy();
				});
			},

			multiUnitOverStockCancel() {
				this.isShowMultiUnitStockCheckModal = false;
			},

			//立即结算
			async goPay() {
				let that = this;
				//数据验证
				if (this.checkedCartList.length == 0) {
					this.$message.closeAll();
					this.$message.warning("还没有勾选商品哦");
					return false;
				}

				// 展示加载遮罩层
				let loadingLayer = this.$loading({
					lock: true,
					text: '库存检测中...',
					background: 'rgba(255, 255, 255, 0.2)'
				});

				if (!this.isOpenZeroStockBuy) {
					// 检测多单位货品库存
					let orderGoodsList = this.checkedCartList.map((item) => {
						return {
							goodsId: item.goodsId,
							specId: item.specId,
							unitName: item.unitName,
							goodsCount: item.goodsCount
						}
					})
					let checkStockResult = await goodsUtils.checkOrderGoodsStock(orderGoodsList);
					// 关闭加载遮罩层
					if (checkStockResult.baseUnitOverOrderGoodsList && checkStockResult.baseUnitOverOrderGoodsList.length > 0) {
						loadingLayer.close();
						this.isShowMultiUnitStockCheckModal = true;
						this.baseUnitOverOrderGoodsList = checkStockResult.baseUnitOverOrderGoodsList;
						return;
					}
				}
				// 关闭弹窗
				loadingLayer.close();

				let filteredCartList = this.checkedCartList;

				// 对0元商品进行校验
				for (var i = 0; i < filteredCartList.length; i++) {
					if (parseFloat(filteredCartList[i].executionPrice) == 0) {
						return this.$message.error("存在0元商品");
					}
					break;
				}

				if (!this.isOpenZeroStockBuy) {
					// 过滤货品数据，将库存为0的商品去除，以及修改选购量
					filteredCartList = this._filterGoodsList(this.checkedCartList);
				} else if (this.OpenZeroStock_isOpenRemind && !this._ZeroStockCheckRemind(filteredCartList)) {
					return;
				}

				// 检测库存过滤后是否还有货品，没有则提示异常信息
				if (filteredCartList.length <= 0) {
					return this.$message.error("暂无可下单商品");
				}

				this.goTradeConfirm(filteredCartList);
			},
			toBuy(filteredCartList) {
				this.isShowDialog = false;
				if (!filteredCartList) {
					filteredCartList = this.checkedCartList;
				}
				this.goTradeConfirm(filteredCartList);
			},
			filterThenToBuy() {
				let filteredCartList = this.checkedCartList;
				// 去除库存为0并且小于最小起购量的商品
				filteredCartList = filteredCartList.filter(t => t.stock > 0 && t.stock >= t.minCount);
				filteredCartList.forEach(t => {
					if (t.stock > 0 && t.stock < t.goodsCount) {
						t.goodsCount = t.stock;
					}
				});
				if (filteredCartList.length == 0) {
					return this.$message.warning("移除后不存在商品");
				}
				this.toBuy(filteredCartList);
			},
			/**
			 * 零库存下单时候进行提醒
			 * @param {Object} filteredCartList
			 */
			_ZeroStockCheckRemind(filteredCartList) {
				try {
					// 判断是否大于库存
					let hasStockButNoEnoughCount = 0;
					let cannotOrderGoodsList = filteredCartList.filter(t => t.stock < parseFloat(t.goodsCount)).map(
						t => {
							if (t.stock > 0) {
								hasStockButNoEnoughCount++;
							}
							return {
								imgUrl: t.coverPic,
								goodsName: t.goodsName,
								specName: t.specName,
								unitName: t.unitName,
								goodsCount: t.goodsCount - (t.stock > 0 ? t.stock : 0),
								stock: t.stock
							}
						});
					// 渲染前端页面
					if (cannotOrderGoodsList.length > 0) {
						this.isShowStockCheckModal = true;
						this.cannotOrderGoodsList = cannotOrderGoodsList;

						return false;
					}
					return true;
				} catch (e) {
					return true;
				}
			},

			goTradeConfirm(filteredCartList) {
				// 跳转到下单页
				let cartIds = filteredCartList.map(item => item.cartId).join(',');

				// 跳转到下单页
				this.$router.push({
					name: 'tradeconfirm',
					params: {
						trade_from: 'PC购物车',
						cartIds
					}
				});
			},

			/**
			 * 过滤货品数据，将库存为0的商品去除，以及修改选购量
			 */
			_filterGoodsList(checkedCartList) {
				// 去除库存为0的商品
				checkedCartList = checkedCartList.filter(t => t.stock > 0 && !t.isInvalid);
				// 修改库存不足的商品选购量
				checkedCartList.forEach(t => {
					if (t.stock < t.goodsCount) {
						t.goodsCount = t.stock;
					}
				});

				return checkedCartList;
			},

			/**
			 * 跳转商品详情
			 * @param {Object} good
			 */
			gotoGoodsDetail(cart) {
				let goodsId = cart.packageGoodsId || cart.goodsId;

				// 限时抢购商品
				if (cart.actType == 1) {
					return this.$router.push({
						name: 'timedsalegoodsdetail',
						query: {
							saleId: cart.actId,
							goodsId: cart.goodsId
						}
					})
				}

				let encode = window.btoa(window.encodeURIComponent(goodsId))
				this.$router.push({
					name: 'goodsdetail',
					query: {
						id: encode
					}
				});
			},

			/**
			 * 计算营销活动价格
			 */
			calcMarketingPrice() {
				// 遍历需要补充的sku列表，进行价格补充
				this.shopCartList.filter(item => item.actId == "0").forEach(cart => {
					// 获取匹配的营销活动信息
					let marketingInfo = cart.marketingInfoList.find(t => cart.goodsCount >= t.limitCount && cart.goodsCount <= t.upperLimitCount);

					// 满足数量要求，则执行营销活动价
					if (marketingInfo) {
						cart.executionPrice = marketingInfo.favorablePrice;
						cart.marketingInfo = marketingInfo;
					} else {
						cart.executionPrice = cart.memberPrice;
						cart.marketingInfo = {};
					}
				});
			},

			/**
			 * 匹配CRM促销策略
			 */
			__matchCrmPromotionPloy: commonUtils.debounce(async function() {
				// 去除无效商品
				let effectiveShoppingCartList = this.shopCartList.filter((item, index) => this.checkedArr[index]);
				if (!(effectiveShoppingCartList.length > 0)) {
					// 重置crm促销策略匹配结果
					this.__resetCrmPloyMatchResult();
					return;
				}

				// 匹配CRM促销策略
				let crmPloyMatchResult = await matchCrmPromotionPloy(effectiveShoppingCartList);
				if (!(crmPloyMatchResult.promotionRemindDtoList.length > 0)) {
					// 重置crm促销策略匹配结果
					this.__resetCrmPloyMatchResult();
					return;
				}

				// 渲染数据
				this.isMatchedCrmPloy = false; // 是否匹配到促销策略
				this.isMatchedCrmPloy = true; // 是否匹配到促销策略
				this.crmPloyMatchResult = crmPloyMatchResult; // 促销策略匹配结果
				this.crmPloyDeductionAmount = crmPloyMatchResult.discount; // crm促销策略抵扣金额
				this.totalMoney = commonUtils.floatKeepDigit(parseFloat(this.totalMoney) - crmPloyMatchResult.discount); // 渲染订单合计
			}, 300, false),

			/**
			 * 重置crm促销策略匹配结果
			 */
			__resetCrmPloyMatchResult() {
				this.isMatchedCrmPloy = false; // 是否匹配到促销策略
				this.crmPloyMatchResult = []; // 促销策略匹配结果
				this.crmPloyDeductionAmount = 0; // crm促销策略抵扣金额
			},
		}
	}
</script>

<style scoped="scoped">
	.empty-cart-wrap {
		margin: 20px auto;
		max-width: calc(100vw - 60px);
		background-color: #fff;
		position: relative;
		height: calc(100vh - 110px);
	}

	.empty-cart-img {
		width: 151px;
		height: 128px;
	}

	.empty-cart-tips {
		color: #ccc;
		font-size: 18px;
		line-height: 120px;
	}

	.empty-cart-btn {
		width: 200px;
		height: 70px;
		font-size: 20px;
	}

	.cart-wrap {
		margin: 20px auto;
		max-width: calc(100vw - 60px);
		background-color: #fff;
		padding: 30px 30px 70px;
		position: relative;
	}

	.cart-title-wrap {
		padding: 0 20px;
		height: 50px;
		line-height: 40px;
		border-bottom: solid 1px #eee;
		display: flex;
		justify-content: space-between;
	}

	.cart-title {
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 2px;
	}

	.shop-btn {
		color: #f56c6b;
		font-size: 18px;
		cursor: pointer;
	}

	.cart-list {
		/* padding: 0 20px; */
		height: calc(100vh - 250px);
		overflow-y: auto;
		box-sizing: border-box;
	}

	.cart-item {
		padding: 20px;
		border-bottom: solid 1px #eee;
		align-items: center;
		position: relative;
	}

	.cart-list .cart-item:last-child {
		border-bottom: none;
	}

	.cart-checkbox {
		width: 50px;
	}

	.goods-box {
		display: contents;
	}

	.cart-item .goods-img {
		width: 100px;
		height: 100px;
		margin: 3px 30px 0 10px;
		max-height: 108px;
	}

	.cart-goods-info {
		width: calc(100% - 690px);
		height: 100%;
		line-height: 20px;
	}

	.cart-goods-name {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		word-break: break-all;
		/* 		padding: 5px 0; */
		font-size: 15px;
		/* height: 50px; */
		line-height: 20px;
	}

	.cart-spec-name {
		font-size: 14px;
		color: #aaa;
		width: 300px;
	}

	.cart-spec-unit {
		margin-right: 20px;
	}

	.cart-price {
		width: 200px;
		text-align: center;
		margin: 0 10px;
	}

	.cart-count-input {
		width: 200px;
		text-align: center;
	}

	.cart-del-btn {
		width: 100px;
		font-size: 28px;
		text-align: center;
	}

	.cart-del-btn>i {
		cursor: pointer;
	}

	.cart-checkbox /deep/.el-checkbox__inner {
		width: 25px;
		height: 25px;
		border-radius: 20px;
	}

	.cart-checkbox /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
		border-color: #DCDFE6;
	}

	.cart-checkbox /deep/.el-checkbox__inner::after {
		border-width: 2px;
		height: 12px;
		left: 7px;
		top: 2px;
		width: 7px;
	}

	.cart-bottom-wrap {
		height: 70px;
		line-height: 70px;
		width: calc(100% - 50px);
		padding-left: 50px;
		font-size: 17px;
		align-items: center;
		position: absolute;
		bottom: 0;
		left: 0;
		border-top: solid 1px #eee;
	}

	.cart-all-checkbox {
		width: 70px;
	}

	.cart-all-checkbox /deep/.el-checkbox__label {
		font-size: 17px;
		color: #333 !important;
	}

	.del-all-btn {
		width: 80px;
		text-align: center;
		cursor: pointer;
		margin: 0 30px;
		position: relative;
	}

	.del-all-btn .el-icon-delete {
		margin-right: 5px;
		font-size: 25px;
		position: relative;
		top: 4px;
	}

	.sum-data {
		display: flex;
		align-items: center;
	}

	.sum-data-wrap {
		width: calc(100% - 450px);
		text-align: right;
		justify-content: flex-end;
	}

	.sum-data-money {
		margin-left: 50px;
	}

	.sum-data-money-txt {
		font-size: 20px;
		font-weight: bold;
		margin-right: 5px;
	}

	.pay-btn {
		position: absolute;
		right: 0;
		top: 0px;
		height: 70px;
		width: 200px;
		font-size: 18px !important;
	}

	.act-label {
		border-radius: 5px;
		color: #f63;
		font-size: 14px;
		border: 1px solid #f63;
		height: 20px !important;
		/* 		margin-left: 10px; */
		word-break: break-all;
		overflow: hidden;
		text-align: center;
		background: #fff;
		padding: 1px 6px;
		max-width: 240px;
		margin-right: 4px;
		margin-bottom: 6px;
	}

	.invalid-bg {
		background: #33333308 !important;
	}

	.price-detail {
		color: #aaa;
		text-decoration: line-through;
		font-size: 13px;
	}

	.warn-stock {
		color: white;
		background: black;
		opacity: 0.85;
		position: absolute;
		bottom: 4px;
		width: 100px;
		display: flex;
		justify-content: center;
		margin: 3px 30px 0 10px;
	}

	.relative-pos {
		position: relative;
	}

	.zero-stock {
		color: #ff0000;
	}

	.sold-out-img {
		width: 80px;
		height: 80px;
		position: absolute;
		top: 14px;
		right: 40px;
		z-index: 9;
	}

	.warn-blockup {
		color: red;
		font-size: 12px;
		width: 200px;
	}

	.label-content {
		display: flex;
		flex-wrap: wrap;
		margin-top: 5px;
	}

	@media screen and (max-width: 1000px) {
		.cart-wrap {
			width: calc(100vw - 80px);
			max-width: calc(100vw - 80px);
			min-width: 700px;
		}

		.cart-checkbox {
			width: 30px;
		}

		.cart-goods-info {
			width: calc(100% - 540px);
		}

		.cart-price {
			width: 150px;
		}

		.cart-count-input {
			width: 160px;
		}

		.cart-wrap .el-input-number {
			width: 140px;
		}

		.cart-del-btn {
			width: 60px;
		}

		.cart-all-checkbox {
			width: 70px;
		}
	}

	.crm-ploy-info {
		position: absolute;
		left: 0;
		bottom: 70px;
		z-index: 9;
	}
</style>